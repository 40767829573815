import React from "react";
import classNames from "classnames";
import styles from "../Styles/modal.module.css";
import { UserAvatar } from "../../Avatar/UserAvatar";

interface Props {
  icon?: string;
  photoUrl?: string;
  fullName?: string;
  backgroundColor: string;
}

export class ModalHeader extends React.PureComponent<Props> {
  static defaultProps = {
    backgroundColor: "var(--primary-font-color)"
  }

  render() {
    const {photoUrl, fullName} = this.props;
    const style = {backgroundColor: this.props.backgroundColor}
    return (
      <div className={styles.iconWrapper}>
        <div className={styles.iconHolder} style={style}>
          {!!this.props.icon && <i className={classNames(this.props.icon, styles.icon)}/>}
          {!!fullName && <UserAvatar size="100%" round name={fullName} profilePhoto={!!photoUrl ? photoUrl : ""}/>}
        </div>
      </div>
    );
  }
}