import React from "react";
import * as Icons from "react-icons/go";
import { NavLink } from "react-router-dom";
import styles from './Styles/menu.module.css';
import { IMenuItem } from "../../PSolutions.Types/Menu";

interface Props {
  menu: IMenuItem;
  isSideBarOpen: boolean;
}

export class MenuItem extends React.PureComponent<Props> {
  render() {
    const icons: any = Icons;
    const {menu} = this.props;
    const ItemIcon = icons[menu.icon];
    return (
      <NavLink className={styles.itemHolder} to={menu.to} activeClassName={styles.active} exact={menu.exact}>
        <ItemIcon size={27}/>
      </NavLink>
    )
  }
}