import React from "react";
import styles from './Styles/dashboard.module.css';
import { SideBar } from "../PSolutions.Components/Layout/Side/Sidebar";
import { Content } from "../PSolutions.Components/Layout/Main/Content";

interface Props {
  show: boolean;
}

interface State {
  isSidebarOpen: boolean;
}

export class Dashboard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {isSidebarOpen: false};
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.setState({isSidebarOpen: !this.state.isSidebarOpen});
  }

  render() {
    if (!this.props.show) return null;
    const {isSidebarOpen} = this.state;
    return (
      <div className={styles.applicationWrapper}>
        <SideBar isOpen={isSidebarOpen} toggleSidebar={this.toggleSidebar}/>
        <Content sidebarOpen={isSidebarOpen} toggleSidebar={this.toggleSidebar}/>
      </div>
    );
  }
}