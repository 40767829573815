import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { BsArrowRight } from "react-icons/all";
import styles from "../../Styles/appointment-upsert.module.css";
import { IRootState } from "../../../../PSolutions.State/RootReducer";
import { DateItem } from "../../../../PSolutions.Components/Date/DateItem";

interface Props {
  readonly: boolean;

  onServiceSelect(): void;
}

export const AppointmentServiceFirstItem = React.memo(({onServiceSelect, readonly}: Props) => {
  const context = useIntl();
  const {calendarData} = useSelector((state: IRootState) => state.appointmentUpsert);
  const {selectedAppointment} = useSelector((state: IRootState) => state.appointmentUpsert);

  const services = selectedAppointment?.services || [];
  const firstService = services[0] || {};

  const selectText = context.formatMessage({id: "generic.messages.select.service"})
  const formatTime = !!firstService.start ? `${firstService?.start}-${firstService?.end}` : `${calendarData.startTime} -`;

  const appliedStyle = !readonly ? styles.serviceContainerButtonFirstItemWrapper : styles.serviceContainerFirstItemWrapper;
  const employeeName = !!firstService.employeeId ? `${firstService.employee.firstName} ${firstService.employee.lastName}` : calendarData.fullName;

  return (
    <React.Fragment>
      <DateItem date={selectedAppointment.date} status={selectedAppointment.status} startTime={selectedAppointment.startTime}/>
      <div className={appliedStyle} onClick={onServiceSelect}>
        <div className={styles.serviceInfoWrapper}>
          <div className={styles.servicesTitle}>{firstService.service?.serviceName || selectText}</div>
          <div className={styles.employeeName}>{employeeName}</div>
          <div className={styles.serviceTime}>{formatTime}</div>
        </div>
        <div className={styles.firstServicePrice}>{firstService.service?.price} {firstService.service?.currency}</div>
        {!readonly && <BsArrowRight size="1.5em" color="var(--ternary-border-color)" className={styles.selectServiceArrowRight}/>}
      </div>
    </React.Fragment>
  )
});