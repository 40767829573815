import React from "react";
import { ResourceLabelContentArg } from "@fullcalendar/resource-common";
import { IWorkingHours } from "../../Types";
import { HoursDisplay } from "./HoursDisplay";
import resourceStyles from '../../Styles/resource.module.css';
import { UserAvatar } from "../../../../PSolutions.Components/Avatar/UserAvatar";

interface Props {
  resourceData: ResourceLabelContentArg
}

export class Resource extends React.PureComponent<Props> {
  render() {
    const {resource} = this.props.resourceData;
    const workingHours: Array<IWorkingHours> = resource?.extendedProps?.displayBusinessHours;
    return (
      <div className={resourceStyles.resourceHolder}>
        <UserAvatar round={true} size="2.5rem" name={resource.title} profilePhoto={resource.extendedProps?.profilePhoto}/>
        <div className={resourceStyles.infoHolder}>
          <div className={resourceStyles.resourceTitle}>{resource.title}</div>
          <HoursDisplay workingHours={workingHours}/>
        </div>
      </div>
    );
  }
}