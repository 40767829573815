import { Reducer } from "redux";
import { EventApi } from "@fullcalendar/react";
import { DateSelectArg } from "@fullcalendar/common";
import { IAppointmentListState } from "../Types";
import { AppointmentListActions } from "./Actions";
import { AppointmentListActionTypesEnum } from "./ActionTypes";


const initialState: IAppointmentListState = {
  isBusy: false,
  resources: [],
  errorMessage: "",
  showError: false,
  appointments: [],
  isDeleted: false,
  isSuccess: false,
  showDeleted: false,
  showSuccess: false,
  apiError: undefined,
  overallEndHour: "21:00",
  overallStartHour: "06:00",
  selectedEvent: {} as EventApi,
  inlineCalendarMinuteInterval: 15,
  selectedSlotArg: {} as DateSelectArg,
}

const appointmentListReducer: Reducer<IAppointmentListState, AppointmentListActions> = (state: IAppointmentListState = initialState, action: AppointmentListActions) => {
  switch (action.type) {
    // Get resources
    case AppointmentListActionTypesEnum.GET_RESOURCES_SUCCESS:
      return {
        ...state,
        isBusy: false,
        resources: action.payload.resources,
        appointments: action.payload.appointments,
        overallEndHour: action.payload.overallEndHour,
        overallStartHour: action.payload.overallStartHour,
        inlineCalendarMinuteInterval: action.payload.inlineCalendarMinuteInterval,
      };
    case AppointmentListActionTypesEnum.GET_RESOURCES_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""}
    case AppointmentListActionTypesEnum.GET_RESOURCES_FAILURE:
      return {...state, isBusy: false, showError: true, apiError: action.payload};

    case AppointmentListActionTypesEnum.SET_SELECTED_EVENT:
      return {...state, selectedEvent: action.payload}

    case AppointmentListActionTypesEnum.SET_SELECTED_SLOT_ARG:
      return {...state, selectedSlotArg: action.payload}

    default:
      return state;
  }
}

export default appointmentListReducer;