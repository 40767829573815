import React from "react";
import Modal from 'react-modal';
import { connect } from "react-redux";
import 'react-calendar/dist/Calendar.css';
import { IntlProvider } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import { GuestPage } from "../../PSolutions.Pages/GuestPage";
import { Dashboard } from "../../PSolutions.Pages/Dashboard";
import { persistor } from "../../PSolutions.State/TekicaStore";
import { IRootState } from "../../PSolutions.State/RootReducer";
import enTranslation from '../../PSolutions.Providers/Resources/en.json';
import { registerRequestInterceptor } from "../../PSolutions.Http/RequestInterceptors";
import validationMessages from '../../PSolutions.Providers/Resources/validation-en.json';
import { TranslationProvider } from "../../PSolutions.Providers/Translation/TranslationProvider";


interface Props {
  currentLanguage: string;
  isAuthenticated: boolean;
  accessToken: string | null;
}

const translations: any = {
  en: {
    ...enTranslation,
    ...validationMessages
  }
}

Modal.setAppElement('#root');

/**
 * Entrader app.
 * Contains starting point and whole configuration for app.
 */
class TekicaApp extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.handleBeforeLift = this.handleBeforeLift.bind(this);
  }

  handleBeforeLift() {
    registerRequestInterceptor();
  }

  render() {
    const {isAuthenticated, currentLanguage} = this.props;
    const applicationMessages = translations[currentLanguage];
    return (
      <PersistGate persistor={persistor} onBeforeLift={this.handleBeforeLift}>
        <TranslationProvider currentLanguage="hr">
          <IntlProvider locale={currentLanguage} defaultLocale="en" messages={applicationMessages}>
            <Dashboard show={isAuthenticated}/>
            <GuestPage show={!isAuthenticated}/>
          </IntlProvider>
        </TranslationProvider>
      </PersistGate>
    );
  }
}

/**
 * Connects to store
 * @param state
 */
const mapStateToProps = (state: IRootState) => {
  return {
    accessToken: state.app.accessToken,
    isAuthenticated: state.app.isAuthenticated,
    currentLanguage: state.app.currentLanguage,
  };
}

/**
 * Connects to dispatch
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TekicaApp);