import axios from "axios";
import { Dispatch } from "redux";
import { IWorkRequestModel, IWorkScheduleModel } from "../Types";
import { WorkScheduleActionTypesEnum } from "./Types";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";

// Get by id
export interface GetSuccessAction {
  payload: IWorkScheduleModel;
  type: WorkScheduleActionTypesEnum.GET_SUCCESS;
}

export interface GetFailureAction {
  payload: string;
  type: WorkScheduleActionTypesEnum.GET_FAILURE;
}

export interface GetRequestedAction {
  type: WorkScheduleActionTypesEnum.GET_REQUESTED;
}

// Get list
export interface GetListRequestedAction {
  type: WorkScheduleActionTypesEnum.GET_LIST_REQUESTED;
}

export interface GetListSuccessAction {
  payload: Array<IWorkScheduleModel>;
  type: WorkScheduleActionTypesEnum.GET_LIST_SUCCESS;
}

export interface GetListFailureAction {
  payload: string;
  type: WorkScheduleActionTypesEnum.GET_LIST_FAILURE;
}

// UPDATE
export interface UpdateRequestedAction {
  type: WorkScheduleActionTypesEnum.UPDATE_REQUESTED;
}

export interface UpdateSuccessAction {
  payload: Array<IWorkScheduleModel>;
  type: WorkScheduleActionTypesEnum.UPDATE_SUCCESS;
}

export interface UpdateFailureAction {
  payload: string;
  type: WorkScheduleActionTypesEnum.UPDATE_FAILURE;
}

// Utility
export interface IWorkScheduleClearErrorAction {
  type: WorkScheduleActionTypesEnum.CLEAR_ERROR;
}

export interface IWorkScheduleClearStateAction {
  type: WorkScheduleActionTypesEnum.CLEAR_STATE;
}

export const getScheduleAsync = (officeId: number, startDate: string, endDate: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const url = `${AppSettings.apiUrl}/WorkSchedule/GetSchedule`;
      dispatch({type: WorkScheduleActionTypesEnum.GET_LIST_REQUESTED});
      const params = {start: startDate, end: endDate, officeId: officeId};
      const res = await axios.get<Array<IWorkScheduleModel>>(url, {params});
      dispatch({type: WorkScheduleActionTypesEnum.GET_LIST_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: WorkScheduleActionTypesEnum.GET_LIST_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const updateScheduleAsync = (model: IWorkRequestModel) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: WorkScheduleActionTypesEnum.UPDATE_REQUESTED});
      const res = await axios.post(`${AppSettings.apiUrl}/WorkSchedule/UpdateSchedule`, model);
      dispatch({type: WorkScheduleActionTypesEnum.UPDATE_SUCCESS, payload: res.data});
      return true;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: WorkScheduleActionTypesEnum.UPDATE_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const clearError = () => (dispatch: Dispatch) => dispatch({type: WorkScheduleActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: WorkScheduleActionTypesEnum.CLEAR_STATE});

export type WorkScheduleActions =
  GetRequestedAction |
  GetSuccessAction |
  GetFailureAction |

  GetListSuccessAction |
  GetListFailureAction |
  GetListRequestedAction |

  UpdateFailureAction |
  UpdateSuccessAction |
  UpdateRequestedAction |

  IWorkScheduleClearErrorAction |
  IWorkScheduleClearStateAction;