import React from "react";
import cn from "classnames";
import { Locale } from "date-fns";
import * as dateFns from "date-fns";
import { IntlContext } from "react-intl";
import { IAppointmentUpsertModel } from "../Types";
import { AppointmentAvatar } from "./AppointmentAvatar";
import styles from '../Styles/appointment-upsert.module.css';
import { AppointmentStatusEnum } from "../../../PSolutions.Enums/AppointmentStatusEnum";
import { TEKICA_DATE_FORMAT, TEKICA_FULL_DATE_FORMAT } from "../../../PSolutions.Config/TekicaTimeFormat";

interface Props {
  upsertMode: boolean;
  currentLocale: Locale,
  selectedAppointment: IAppointmentUpsertModel
}

export class AppointmentHeader extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.getClientName = this.getClientName.bind(this);
    this.getStatusText = this.getStatusText.bind(this);
    this.getBottomText = this.getBottomText.bind(this);
  }

  getColor() {
    const {selectedAppointment} = this.props;
    if (selectedAppointment.status === AppointmentStatusEnum.Done) return styles.appointmentHeaderDone;
    if (selectedAppointment.status === AppointmentStatusEnum.NoShow) return styles.appointmentHeaderNoShow;
    if (selectedAppointment.status === AppointmentStatusEnum.FullyPaid) return styles.appointmentHeaderDone;
    if (selectedAppointment.status === AppointmentStatusEnum.Canceled) return styles.appointmentHeaderCanceled;
    if (selectedAppointment.status === AppointmentStatusEnum.Declined) return styles.appointmentHeaderDeclined;
    if (selectedAppointment.status === AppointmentStatusEnum.Confirmed) return styles.appointmentHeaderConfirmed;
    if (selectedAppointment.status === AppointmentStatusEnum.NeedApproval) return styles.appointmentHeaderNeedApproval;
    if (selectedAppointment.status === AppointmentStatusEnum.ClientCanceled) return styles.appointmentHeaderClientCanceled;
  }

  getStatusText() {
    const ctx = this.context;
    const {selectedAppointment} = this.props;
    if (selectedAppointment.status === AppointmentStatusEnum.Confirmed) return ctx.formatMessage({id: "appointment.status.confirmed"});
    else if (selectedAppointment.status === AppointmentStatusEnum.NoShow) return ctx.formatMessage({id: "appointment.status.no.show"});
    else if (selectedAppointment.status === AppointmentStatusEnum.FullyPaid) return ctx.formatMessage({id: "appointment.status.fully.paid"});
    else if (selectedAppointment.status === AppointmentStatusEnum.NeedApproval) return ctx.formatMessage({id: "appointment.status.need.approval"});
  }

  getClientName() {
    const {selectedAppointment} = this.props;
    return !!selectedAppointment?.clientId ? selectedAppointment?.client?.name : selectedAppointment.walkInClientName;
  }

  getBottomText() {
    const {selectedAppointment, currentLocale, upsertMode} = this.props;
    const parsed = dateFns.parse(selectedAppointment.date, TEKICA_DATE_FORMAT, new Date());

    // Parse
    const dateText = dateFns.format(parsed, TEKICA_FULL_DATE_FORMAT, {locale: currentLocale});
    const bookingText = this.context.formatMessage({id: "generic.messages.appointment.id"});

    return upsertMode ? dateText : `${bookingText}: ${selectedAppointment.id}`;
  }

  render() {
    const bottomText = this.getBottomText();
    const {selectedAppointment} = this.props;

    return (
      <div className={cn(styles.appointmentHeader, this.getColor())}>
        <AppointmentAvatar selectedAppointment={selectedAppointment}/>
        <div className={styles.appointmentHeaderStatus}>{this.getStatusText()}</div>
        <div className={styles.appointmentHeaderClientName}>{this.getClientName()}</div>
        <div className={styles.appointmentHeaderBookingStatusId}>{bottomText}</div>
      </div>
    );
  }
}