export enum AppointmentListActionTypesEnum {
  CLEAR_ERROR = "APPOINTMENT_LIST/CLEAR_ERROR",
  CLEAR_STATE = "APPOINTMENT_LIST/CLEAR_STATE",

  GET_RESOURCES_SUCCESS = "APPOINTMENT_LIST/GET_RESOURCES_SUCCESS",
  GET_RESOURCES_FAILURE = "APPOINTMENT_LIST/GET_RESOURCES_FAILURE",
  GET_RESOURCES_REQUESTED = "APPOINTMENT_LIST/GET_RESOURCES_REQUESTED",

  SET_SELECTED_EVENT = "APPOINTMENT_LIST/SET_SELECTED_APPOINTMENT",
  SET_SELECTED_SLOT_ARG = "APPOINTMENT_LIST/SET_SELECTED_SLOT_ARG",
}