import { Reducer } from "redux";
import { IEmployeeListState } from "../Types";
import { EmployeeListActions } from "./Actions";
import { EmployeeListActionTypesEnum } from "./Types";

const initialState: IEmployeeListState = {
  isBusy: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  showDeleted: false,
  showSuccess: false,
  employees: [],
}


const EmployeeListReducer: Reducer<IEmployeeListState, EmployeeListActions> = (state: IEmployeeListState = initialState, action: EmployeeListActions) => {
  switch (action.type) {
    case EmployeeListActionTypesEnum.GET_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case EmployeeListActionTypesEnum.GET_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case EmployeeListActionTypesEnum.GET_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: ""};

    case EmployeeListActionTypesEnum.GET_LIST_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case EmployeeListActionTypesEnum.GET_LIST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case EmployeeListActionTypesEnum.GET_LIST_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: "", employees: action.payload};

    case EmployeeListActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case EmployeeListActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default EmployeeListReducer;