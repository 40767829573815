import React from "react";
import { MdOutlineMessage } from "react-icons/all";
import styles from '../../Styles/event.module.css';

interface Props {
  show: boolean;
}

export class NoteIndicator extends React.PureComponent<Props> {
  render() {
    if (!this.props.show) return null;
    return (
      <div className={styles.noteIndicatorHolder}>
        <MdOutlineMessage color="gray" size="1.3em"/>
      </div>
    );
  }
}