import React from 'react'
import * as dateFns from 'date-fns';
import { connect } from "react-redux";
import { IntlContext } from 'react-intl';
import { EventApi } from "@fullcalendar/react";
import { DateSelectArg } from "@fullcalendar/common";
import { bindActionCreators, Dispatch } from "redux";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { IPauseModel } from "../Types";
import { PauseView } from "../Views/PauseView";
import { CalendarManager } from "../../../PSolutions.Manager/CalendarManager";
import { PauseValidationSchema } from "./PauseValidationSchema";
import { ISelectOption } from "../../../PSolutions.Types/Common";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { addAsync, clearError, clearState, removeAsync } from '../Redux/Actions';
import { TekicaPopup } from "../../../PSolutions.Prebuilt/Popups/Base/TekicaPopup";
import { TEKICA_FULL_DATE_FORMAT } from "../../../PSolutions.Config/TekicaTimeFormat";

interface Props {
  isEdit: boolean;
  visible: boolean;
  showError: boolean;
  currentLocale: Locale,
  selectedEvent: EventApi;
  selectedTime: DateSelectArg;
  selectedSlotArg: DateSelectArg,

  closeModal(): void;

  clearError(): void;

  clearState(): void;

  onSuccess(): void;

  addAsync(model: IPauseModel): any;

  removeAsync(model: IPauseModel): any;
}

interface State {
  id: number;
  date: string;
  title: string;
  allDay: boolean;
  dateObject: Date;
  employeeId?: string;
  profilePhoto: string;
  endTime: ISelectOption;
  startTime: ISelectOption;
  selectedEmployee: string;
}

class PauseContainer extends React.PureComponent<Props, State> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: 0,
      date: "",
      title: "",
      allDay: false,
      employeeId: "",
      profilePhoto: "",
      selectedEmployee: "",
      dateObject: new Date(),
      endTime: {} as ISelectOption,
      startTime: {} as ISelectOption,
    };
    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.parseSelection = this.parseSelection.bind(this);
    this.handleSubmitAsync = this.handleSubmitAsync.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.visible) return;
    if (prevProps.visible === this.props.visible) return;
    this.props.isEdit ? this.parseFromEvent() : this.parseSelection();
  }

  parseSelection() {
    const parsedSelection = CalendarManager.parseFromSelection(this.props.selectedTime)
    const {date, employeeId, fullName, id, profilePhoto, dateObject, startOption, endOption} = parsedSelection;
    this.setState({endTime: endOption, startTime: startOption, date, selectedEmployee: fullName, employeeId, id, profilePhoto, dateObject});
  }

  parseFromEvent() {
    const parsedSelection = CalendarManager.parseFromEvent(this.props.selectedEvent);
    const {date, employeeId, fullName, id, profilePhoto, dateObject, startOption, endOption} = parsedSelection;
    this.setState({endTime: endOption, startTime: startOption, date, selectedEmployee: fullName, employeeId, id, profilePhoto, dateObject});
  }

  handleSuccess() {
    this.props.closeModal();
    this.props.clearState();
    this.props.onSuccess();
  }

  handleError() {
  }

  async handleSubmitAsync(values: State, actions: FormikHelpers<State>) {
    let response = undefined;
    const model = this.getModelFromValues(values);

    // Preform action
    if (!this.props.isEdit) response = await this.props.addAsync(model);
    if (this.props.isEdit) response = await this.props.removeAsync(model);

    actions.setSubmitting(false);
    return response === true ? this.handleSuccess() : this.handleError();
  }

  render() {
    if (this.props.showError) return null;

    const {visible, closeModal, currentLocale} = this.props;
    const {selectedEmployee, profilePhoto, dateObject} = this.state;

    const title = this.context.formatMessage({id: "appointment.new.pause"});
    const editPause = this.context.formatMessage({id: "appointment.edit.pause"});
    const modalTitle = this.props.isEdit ? editPause : title;

    const bottomText = dateFns.format(dateObject, TEKICA_FULL_DATE_FORMAT, {locale: currentLocale});

    return (
      <TekicaPopup
        isBusy={false}
        show={visible}
        subtitle={modalTitle}
        closeModal={closeModal}
        name={selectedEmployee}
        title={selectedEmployee}
        profilePhoto={profilePhoto}
        textBottomValue={bottomText}>
        <Formik
          initialValues={this.state}
          onSubmit={this.handleSubmitAsync}
          validationSchema={PauseValidationSchema}>
          {(props: FormikProps<State>) => <PauseView {...props} isEdit={this.props.isEdit} visible={visible}/>}
        </Formik>
      </TekicaPopup>
    )
  }

  getModelFromValues(values: State): IPauseModel {
    const {date, employeeId, startTime, endTime, title, allDay} = values;
    return {id: values.id, allDay: allDay, endDate: date, startDate: date, endTime: endTime.value, startTime: startTime.value, employeeId, title};
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    showError: state.pause.showError,
    currentLanguage: state.app.currentLanguage,
    selectedEvent: state.appointmentList.selectedEvent,
    selectedSlotArg: state.appointmentList.selectedSlotArg,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({clearError, clearState, addAsync, removeAsync}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PauseContainer)