import React from "react";
import styles from "../Styles/modal.module.css";
import classNames from "classnames";

interface Props {
  children: any;
  title?: string;
  maxHeight?: string;
  showBorder?: boolean;
  description?: string;
}

export class ModalContent extends React.PureComponent<Props> {
  render() {
    const {title, description, children, maxHeight, showBorder} = this.props;
    const style = !!maxHeight ? {maxHeight} : {};
    return (
      <>
        {!!title && <div className={classNames(styles.title, {[styles.titleBottom]: !!showBorder})}>{this.props.title}</div>}
        <div className={styles.content} style={style}>
          {!!description && <div className={styles.description}>{this.props.description}</div>}
          {children}
        </div>
      </>
    );
  }
}