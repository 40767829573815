import { IEmployeeModel } from "../PSolutions.Containers/EmployeeList/Types";
import { AppointmentServiceUtility } from "../PSolutions.Utility/AppointmentServiceUtility";
import { IAppointmentUpsertServiceModel, IServiceModel } from "../PSolutions.Containers/AppointmentUpsert/Types";

export interface IAppointmentServiceFactoryRequest {
  startTime: string;
  employeeId: string;
  service: IServiceModel;
  employees: Array<IEmployeeModel>;
}

export class AppointmentFactory {
  public static CreateAppointmentService(request: IAppointmentServiceFactoryRequest): IAppointmentUpsertServiceModel | null {
    const employeeId = Number.parseInt(request.employeeId);
    const endTime = AppointmentServiceUtility.CalculateEndTime(request.startTime, request.service.estimatedDuration);

    const selectedEmployee = request.employees.find((e: IEmployeeModel) => employeeId === e.id);
    if (!selectedEmployee) return null;

    // Find custom option
    return {
      id: 0,
      end: endTime,
      waitingTime: "",
      hasWaiting: false,
      hasOverlaps: false,
      overlappingTime: "",
      employeeId: employeeId,
      hasProcessingTime: false,
      service: request.service,
      start: request.startTime,
      employee: selectedEmployee,
      serviceId: request.service.id,
      processingTimeStartInMinutes: 0,
      processingTimeDurationInMinutes: 0,
      formattedServiceStartTime: request.startTime,
    };
  }
}