import React from "react";
import classNames from "classnames";
import { IntlContext } from "react-intl";
import { IWorkScheduleModel } from "../../Types";
import styles from "../../Styles/work.schedule.module.css";

interface Props {
  shift: IWorkScheduleModel;
}

export class ShiftDisplay extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  getColor(shift: IWorkScheduleModel) {
    return !shift.backgroundColor ? {backgroundColor: "var(--utility-blue-100)"} : {backgroundColor: shift.backgroundColor};
  }

  render() {
    const {shift} = this.props;
    const appliedStyle = this.getColor(shift);
    return (
      <div className={classNames(styles.shiftItem, styles.defaultItem)} style={appliedStyle}>
        <div className={styles.shiftText}>{shift.shiftName || this.context.formatMessage({id: "generic.messages.custom.time"})}</div>
        {shift.customStartTime} - {shift.customEndTime}
      </div>
    );
  }
}
