import axios from "axios";
import { Dispatch } from "redux";
import { ISignInModel } from "../Types";
import { SignInActionTypesEnum } from "./Types";
import { IUserCredentials } from "../../../PSolutions.App/Types";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";

export interface ISignInSuccessAction {
  payload: IUserCredentials;
  type: SignInActionTypesEnum.SIGN_IN_SUCCESS;
}

export interface ISignInFailureAction {
  payload: string;
  type: SignInActionTypesEnum.SIGN_IN_FAILURE;
}

export interface ISignInRequestedAction {
  type: SignInActionTypesEnum.SIGN_IN_REQUESTED;
}

export interface ISignInClearErrorAction {
  type: SignInActionTypesEnum.CLEAR_ERROR;
}


export const signInAsync = (model: ISignInModel) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: SignInActionTypesEnum.SIGN_IN_REQUESTED});
      const res = await axios.post<IUserCredentials>(`${AppSettings.apiUrl}/Auth/login`, model);
      dispatch({type: SignInActionTypesEnum.SIGN_IN_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: SignInActionTypesEnum.SIGN_IN_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const clearError = () => (dispatch: Dispatch) => dispatch({type: SignInActionTypesEnum.CLEAR_ERROR});

export type SignInActions = ISignInRequestedAction | ISignInSuccessAction | ISignInFailureAction | ISignInClearErrorAction;