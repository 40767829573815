import React from "react";
import { AppointmentLoader } from "./AppointmentLoader";
import styles from '../Styles/appointment-upsert.module.css';

interface Props {
  isBusy: boolean;
}

export class AppointmentHolder extends React.PureComponent<Props> {
  render() {
    return (
      <div className={styles.appointmentContainer}>
        {!this.props.isBusy && this.props.children}
        <AppointmentLoader show={this.props.isBusy}/>
      </div>
    );
  }
}