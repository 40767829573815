import React from "react";
import { IntlContext } from "react-intl";
import { BaseModal } from "../../../../PSolutions.Components/Modals/Base/BaseModal";
import { PrimaryButton } from "../../../../PSolutions.Components/Buttons/PrimaryButton";
import { OutlineButton } from "../../../../PSolutions.Components/Buttons/OutlineButton";
import { ModalHeader } from "../../../../PSolutions.Components/Modals/Parts/ModalHeader";
import { ModalContent } from "../../../../PSolutions.Components/Modals/Parts/ModalContent";
import { ModalContainer } from "../../../../PSolutions.Components/Modals/Parts/ModalContainer";

interface Props {
  show: boolean;

  closePicker(): void;

  onPauseClick(): void;

  onAppointmentClick(addMode: boolean): void;
}

export class AppointmentPicker extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.handlePauseClicked = this.handlePauseClicked.bind(this);
    this.handleAppointmentClicked = this.handleAppointmentClicked.bind(this);
  }

  handlePauseClicked() {
    this.props.closePicker();
    this.props.onPauseClick();
  }

  handleAppointmentClicked() {
    this.props.closePicker();
    this.props.onAppointmentClick(true);
  }

  render() {
    const style = {marginTop: '10px'};
    const pause = this.context.formatMessage({id: "appointment.pause.title"});
    const title = this.context.formatMessage({id: "appointment.pick.pause.or.service"});
    const appointment = this.context.formatMessage({id: "generic.messages.appointment"});
    return (
      <BaseModal closeModal={this.props.closePicker} topPosition={45} topPadding={50} visible={this.props.show}>
        <ModalContainer>
          <ModalHeader icon="fa fa-info fa-2x" backgroundColor="var(--primary-font-color)"/>
          <ModalContent title={title}>
            <PrimaryButton isBusy={false} text={appointment} onClick={this.handleAppointmentClicked}/>
            <OutlineButton isBusy={false} text={pause} style={style} onClick={this.handlePauseClicked}/>
          </ModalContent>
        </ModalContainer>
      </BaseModal>
    );
  }
}