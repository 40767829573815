import React from "react";
import { connect } from "react-redux";
import { injectIntl, IntlShape } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import styles from '../Styles/sidebar.module.css';
import { UserAvatar } from "../../Avatar/UserAvatar";
import { ContentModal } from "../../Modals/ContentModal";
import { OutlineButton } from "../../Buttons/OutlineButton";
import { appLogOut } from "../../../PSolutions.App/Redux/Actions";
import { IRootState } from "../../../PSolutions.State/RootReducer";

interface Props {
  intl: IntlShape;
  isSidebarOpen: boolean;
  lastName: string | null;
  firstName: string | null;
  profilePhoto: string | null;

  appLogOut(): any;
}

interface State {
  showLocationPicker: boolean;
}

class SidebarFooter extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {showLocationPicker: false};
    this.toggleModal = this.toggleModal.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  toggleModal() {
    this.setState({showLocationPicker: !this.state.showLocationPicker});
  }

  renderContent() {
    return null;
  }

  renderFooter() {
    const {intl, appLogOut, firstName, lastName, profilePhoto} = this.props;
    const message = intl.formatMessage({id: "generic.messages.log.out"});
    const signIn = intl.formatMessage({id: "generic.message.currently.sign.as"});
    const fullName = `${firstName} ${lastName}`;

    return (
      <div className={styles.modalFooter}>
        <div className={styles.left}>
          <UserAvatar name={fullName} size="50px" round={true} profilePhoto={profilePhoto}/>
          <div className={styles.currentUserInfo}>
            <div className={styles.currentLabel}>{signIn}</div>
            <div className={styles.currentUserName}>{fullName}</div>
          </div>
        </div>
        <div><OutlineButton isBusy={false} text={message} onClick={appLogOut}/></div>
      </div>
    )
  }

  render() {
    const footer = this.renderFooter();
    const content = this.renderContent();
    const {firstName, lastName, profilePhoto} = this.props;
    const fullName = `${firstName} ${lastName}`;

    return (
      <div className={styles.footer}>
        <UserAvatar
          round={true}
          size="2.5rem"
          name={fullName}
          onClick={this.toggleModal}
          profilePhoto={profilePhoto}
        />
        <ContentModal
          footer={footer}
          content={content}
          closeModal={this.toggleModal}
          visible={this.state.showLocationPicker}
          title="generic.messages.current.user.info"
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    lastName: state.app.lastName,
    firstName: state.app.firstName,
    profilePhoto: state.app.profilePhoto,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({appLogOut}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SidebarFooter));