import React from "react";
import styles from './Styles/popup.module.css';
import { TekicaPopupHeader } from "./Components/TekicaPopupHeader";
import { BaseModal } from "../../../PSolutions.Components/Modals/Base/BaseModal";

interface Props {
  show: boolean;
  name: string;
  title: string;
  isBusy: boolean;
  subtitle: string;
  profilePhoto: string;
  textBottom?: string;
  textBottomValue?: string;
  backgroundColor?: string;
  preventRequestClose: boolean

  closeModal(): void;
}

export class TekicaPopup extends React.PureComponent<Props> {
  public static defaultProps = {subtitle: "", name: "", isBusy: false, preventRequestClose: false}
  render() {
    const {backgroundColor, title, subtitle, preventRequestClose} = this.props;
    const {closeModal, show, profilePhoto, name, textBottomValue, textBottom} = this.props;
    return (
      <BaseModal closeModal={closeModal} topPadding={65} visible={show} preventRequestClose={preventRequestClose}>
        <div className={styles.baseModalHolder}>
          <TekicaPopupHeader
            name={name}
            title={title}
            subtitle={subtitle}
            textBottom={textBottom}
            profilePhoto={profilePhoto}
            textBottomValue={textBottomValue}
            backgroundColor={backgroundColor}
          />
          <div className={styles.contentHolder}>{this.props.children}</div>
        </div>
      </BaseModal>
    );
  }
}