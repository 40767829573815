import React from "react";
import styles from "../Styles/modal.module.css";

interface Props {
  children: any;
}

export class ModalFooter extends React.PureComponent<Props> {
  render() {
    return (
      <div className={styles.footer}>{this.props.children}</div>
    );
  }
}