import React from "react";
import classNames from "classnames";
import { IntlContext } from "react-intl";
import { ITimeRange } from "../../../EmployeeList/Types";
import styles from "../../Styles/work.schedule.module.css";

interface Props {
  standardTime: ITimeRange | undefined;
}

export class StandardDisplay extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    const {standardTime} = this.props;
    if (!standardTime?.endTime || !standardTime.startTime) return null;
    const appliesStyles = classNames(styles.shiftItem, styles.standardTime);
    const time = this.context.formatMessage({id: "generic.messages.standard.time"});
    return (
      <div className={appliesStyles}>
        {standardTime.startTime}-{standardTime.endTime}
        <div className={styles.standardTimeText}>{time}</div>
      </div>
    );
  }
}
