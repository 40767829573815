import React from "react";
import classNames from "classnames";
import styles from "../../Styles/work.schedule.module.css";

interface Props {
  dayName: string;
  selected: boolean;

  onClick(): void;
}

export class RepeatDay extends React.PureComponent<Props> {
  render() {
    const {selected} = this.props;
    return (
      <div className={styles.repeatDayItem} onClick={this.props.onClick}>
        <div className={classNames(styles.dayItem, {[styles.selectedDay]: selected})}>{this.props.dayName}</div>
      </div>
    );
  }
}