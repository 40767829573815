export enum ShiftsActionTypesEnum {
  CLEAR_ERROR = "Shifts/CLEAR_ERROR",
  CLEAR_STATE = "Shifts/CLEAR_STATE",

  GET_FAILURE = "Shifts/GET_FAILURE",
  GET_SUCCESS = "Shifts/GET_SUCCESS",
  GET_REQUESTED = "Shifts/GET_REQUESTED",

  GET_LIST_FAILURE = "Shifts/GET_LIST_FAILURE",
  GET_LIST_SUCCESS = "Shifts/GET_LIST_SUCCESS",
  GET_LIST_REQUESTED = "Shifts/GET_LIST_REQUESTED",
}