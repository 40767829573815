import { IApplicationSettings } from "../PSolutions.Types/Settings";

const APPLICATION_DEFAULT_LOCALE = "hr";
const AppSettings: IApplicationSettings = {apiUrl: "", baseUrl: ""}


switch (process.env.NODE_ENV) {
  case "development":
    AppSettings.baseUrl = "https://psolutions-tekica-production.azurewebsites.net/";
    AppSettings.apiUrl = "https://psolutions-tekica-production.azurewebsites.net/api/biz";
    break;
  case "test":
    AppSettings.baseUrl = "https://psolutions-tekica.azurewebsites.net/";
    AppSettings.apiUrl = "https://psolutions-tekica.azurewebsites.net/api/biz";
    break;
  case "production":
    AppSettings.baseUrl = "https://psolutions-tekica-production.azurewebsites.net/";
    AppSettings.apiUrl = "https://psolutions-tekica-production.azurewebsites.net/api/biz";
    break;
}

export { AppSettings, APPLICATION_DEFAULT_LOCALE };