import axios from 'axios';
import { Dispatch } from "redux";
import storage from "redux-persist/es/storage";
import { IUserCredentials } from "../Types";

// Types of action
export enum AppActionTypesEnum {
  APP_LOG_IN_SUCCESS = "APP/APP_LOG_IN_SUCCESS",
  APP_LOG_OUT_SUCCESS = "APP/APP_LOG_OUT_SUCCESS",
}

// Login action
export interface IAppLoginAction {
  payload: IUserCredentials,
  type: AppActionTypesEnum.APP_LOG_IN_SUCCESS,
}

// Logout action
export interface IAppLogoutAction {
  type: AppActionTypesEnum.APP_LOG_OUT_SUCCESS,
}

// App login in
export const appLoginIn = (userCredentials: IUserCredentials) => {
  return (dispatch: Dispatch) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${userCredentials.accessToken}`;
    dispatch({type: AppActionTypesEnum.APP_LOG_IN_SUCCESS, payload: userCredentials});
  }
}

// App log out
export const appLogOut = () => {
  return async (dispatch: Dispatch) => {
    axios.defaults.headers.common['Authorization'] = null;
    await storage.removeItem("userCredentials");
    dispatch({type: AppActionTypesEnum.APP_LOG_OUT_SUCCESS});
  }
}

// Union
export type AppActions = IAppLoginAction | IAppLogoutAction;