import React from "react";
import { useHistory } from "react-router-dom";
import styles from '../Styles/sidebar.module.css';
import logo from '../../../PSolutions.Assets/Images/logo.png';

export const SidebarHeader = React.memo(() => {
  const history = useHistory();

  function handleClick() {
    return history.push("/");
  }

  return (
    <div className={styles.header}>
      <img alt="logo" src={logo} className={styles.logo} onClick={handleClick}/>
      <div className={styles.separator}>&nbsp;</div>
    </div>
  )
});