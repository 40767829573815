export enum WorkScheduleActionTypesEnum {
  CLEAR_ERROR = "WorkSchedule/CLEAR_ERROR",
  CLEAR_STATE = "WorkSchedule/CLEAR_STATE",

  GET_FAILURE = "WorkSchedule/GET_FAILURE",
  GET_SUCCESS = "WorkSchedule/GET_SUCCESS",
  GET_REQUESTED = "WorkSchedule/GET_REQUESTED",

  GET_LIST_FAILURE = "WorkSchedule/GET_LIST_FAILURE",
  GET_LIST_SUCCESS = "WorkSchedule/GET_LIST_SUCCESS",
  GET_LIST_REQUESTED = "WorkSchedule/GET_LIST_REQUESTED",

  UPDATE_FAILURE = "WorkSchedule/UPDATE_FAILURE",
  UPDATE_SUCCESS = "WorkSchedule/UPDATE_SUCCESS",
  UPDATE_REQUESTED = "WorkSchedule/UPDATE_REQUESTED",
}