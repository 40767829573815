import React, { FormEvent } from "react";
import classNames from "classnames";
import { IRegularFormInput } from "../Types";
import styles from "../Styles/input.module.css";

interface Props extends IRegularFormInput {
  show: boolean;
  noMargin: boolean;

  handleTextChange(event: FormEvent<HTMLInputElement>): void;
}

interface State {
  focused: boolean;
}

export class RegularInput extends React.PureComponent<Props, State> {
  static defaultProps = {label: "", show: true, noMargin: false}
  constructor(props: Props) {
    super(props);
    this.state = {focused: false};
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleFocus() {
    this.setState({focused: true});
  }

  handleBlur() {
    this.setState({focused: false});
  }

  render() {
    const {focused} = this.state;
    if (!this.props.show) return null;
    const {id, placeholder, label, type, disabled, value, noMargin} = this.props;
    return (
      <div className={classNames(styles.inputHolder, {[styles.noMargin]: noMargin})}>
        <div className={classNames(styles.label, {[styles.focused]: focused})}>{label}</div>
        <input
          id={id}
          type={type}
          disabled={disabled}
          defaultValue={value}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          onFocus={this.handleFocus}
          onChange={this.props.handleTextChange}
          className={classNames(styles.formInput)}
        />
      </div>
    );
  }
}