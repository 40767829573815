export enum PauseActionTypesEnum {
  CLEAR_ERROR = "Pause/CLEAR_ERROR",
  CLEAR_STATE = "Pause/CLEAR_STATE",
  
  ADD_FAILURE = "Pause/ADD_FAILURE",
  ADD_SUCCESS = "Pause/ADD_SUCCESS",
  ADD_REQUESTED = "Pause/ADD_REQUESTED",

  GET_FAILURE = "Pause/GET_FAILURE",
  GET_SUCCESS = "Pause/GET_SUCCESS",
  GET_REQUESTED = "Pause/GET_REQUESTED",

  REMOVE_FAILURE = "Pause/REMOVE_FAILURE",
  REMOVE_SUCCESS = "Pause/REMOVE_SUCCESS",
  REMOVE_REQUESTED = "Pause/REMOVE_REQUESTED",
}