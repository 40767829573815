import React from 'react'
import * as dateFns from 'date-fns';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IWorkScheduleModel } from "../Types";
import { WeekRow } from "../Components/Week/WeekRow";
import styles from '../Styles/work.schedule.module.css';
import { WeekDisplay } from "../Components/Week/WeekDisplay";
import { IEmployeeModel } from "../../EmployeeList/Types";
import UpsertScheduleContainer from "./UpsertScheduleContainer";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { getEmployeeListAsync } from "../../EmployeeList/Redux/Actions";
import { BusyLoader } from "../../../PSolutions.Components/UI/BusyLoader";
import { getListAsync as getShiftsAsync } from "../../Shifts/Redux/Actions";
import { clearError, clearState, getScheduleAsync } from '../Redux/Actions';
import { ErrorDisplay } from "../../../PSolutions.Components/UI/ErrorDisplay";
import { TEKICA_DATE_FORMAT } from "../../../PSolutions.Config/TekicaTimeFormat";
import { CalendarToolBar } from "../../AppointmentList/Components/Toolbar/CalendarToolBar";
import { TranslationContext } from "../../../PSolutions.Providers/Translation/TranslationContext";

interface Props {
  isBusy: boolean;
  officeId: number;
  showError: boolean;
  errorMessage: string;
  employees: Array<IEmployeeModel>;
  schedule: Array<IWorkScheduleModel>;

  clearError(): void;

  clearState(): void;

  getShiftsAsync(): any

  getEmployeeListAsync(): any;

  getScheduleAsync(officeId: number, start: string, end: string): any;
}

interface State {
  endDate: Date;
  startDate: Date;
  showUpsertModal: boolean;
  selectedDate: Date | undefined;
  selectedEmployee: IEmployeeModel | undefined;
}

class WorkScheduleContainer extends React.PureComponent<Props, State> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;

  constructor(props: Props) {
    super(props);
    this.handleDates = this.handleDates.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.closeUpsertModal = this.closeUpsertModal.bind(this);
    this.getScheduleAsync = this.getScheduleAsync.bind(this);
    this.handleDatePicked = this.handleDatePicked.bind(this);
    this.state = {
      endDate: new Date(),
      startDate: new Date(),
      showUpsertModal: false,
      selectedDate: undefined,
      selectedEmployee: undefined,
    };
  }

  async componentDidMount() {
    this.handleDates();
    await this.props.getEmployeeListAsync();
    await this.props.getShiftsAsync();
  }

  async getScheduleAsync() {
    const {officeId} = this.props;
    const {startDate, endDate} = this.state;
    const end = dateFns.format(endDate, TEKICA_DATE_FORMAT);
    const start = dateFns.format(startDate, TEKICA_DATE_FORMAT);
    return await this.props.getScheduleAsync(officeId, start, end);
  }

  handlePrevClick() {
    this.handleDates(this.state.startDate, -7);
  }

  handleNextClick() {
    this.handleDates(this.state.startDate, 7);
  }

  handleDatePicked(date: Date) {
    this.handleDates(date);
  }

  closeUpsertModal(reload?: boolean) {
    this.setState({showUpsertModal: false, selectedEmployee: undefined, selectedDate: undefined}, async () => !!reload && this.getScheduleAsync())
  }

  handleItemClick(date: Date, employee: IEmployeeModel) {
    this.setState({showUpsertModal: true, selectedDate: date, selectedEmployee: employee});
  }

  render() {
    const {startDate, endDate, showUpsertModal} = this.state;
    const selectedDate = this.state?.selectedDate || new Date();
    const {employees, errorMessage, showError, clearError} = this.props;
    const selectedEmployee = this.state?.selectedEmployee || {} as IEmployeeModel;

    return (
      <div className={styles.container}>
        <CalendarToolBar
          endDate={this.state.endDate}
          currentDate={this.state.startDate}
          onPrevClick={this.handlePrevClick}
          onNextClick={this.handleNextClick}
          onDateClick={this.handleDatePicked}
        />
        <WeekDisplay endDate={endDate} startDate={startDate}/>
        <div className={styles.workScheduleContainer}>
          <div className={styles.weekHolder}>
            {employees.map((e: IEmployeeModel, i: number) => <WeekRow key={i} employee={e} endDate={endDate} startDate={startDate} onItemClick={this.handleItemClick}/>)}
          </div>
        </div>
        <BusyLoader visible={this.props.isBusy}/>
        <ErrorDisplay error={errorMessage} visible={showError} closeModal={clearError}/>
        <UpsertScheduleContainer selectedDate={selectedDate} show={showUpsertModal && !showError} closeModal={this.closeUpsertModal} selectedEmployee={selectedEmployee}/>
      </div>
    );
  }

  handleDates(date: Date = new Date(), daysToAdd: number = 0) {
    const options = {locale: this.context.currentLocale};
    const endOfWeek = dateFns.addDays(dateFns.endOfWeek(date, options), daysToAdd);
    const startOfWeek = dateFns.addDays(dateFns.startOfWeek(date, options), daysToAdd);
    this.setState({startDate: startOfWeek, endDate: endOfWeek}, async () => await this.getScheduleAsync());
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isBusy: state.workSchedule.isBusy,
    schedule: state.workSchedule.schedule,
    employees: state.employeeList.employees,
    showError: state.workSchedule.showError,
    errorMessage: state.workSchedule.errorMessage,
    officeId: state.officeSelector.selectedOfficeId,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({clearError, clearState, getEmployeeListAsync, getShiftsAsync, getScheduleAsync}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkScheduleContainer)