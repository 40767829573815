import React from "react";
import { IntlContext } from "react-intl";
import styles from "../Styles/appointment-upsert.module.css";
import { IAppointmentUpsertModel, ICancelAppointmentRequest } from "../Types";
import { FlatButton } from "../../../PSolutions.Components/Buttons/FlatButton";
import { TekicaPopup } from "../../../PSolutions.Prebuilt/Popups/Base/TekicaPopup";
import { AppointmentUtility } from "../../../PSolutions.Utility/AppointmentUtility";
import { DangerButton } from "../../../PSolutions.Components/Buttons/DangerButton";
import { PopupRow } from "../../../PSolutions.Prebuilt/Popups/Base/Components/PopupRow";
import { AppointmentStatusEnum } from "../../../PSolutions.Enums/AppointmentStatusEnum";
import { PopupFooter } from "../../../PSolutions.Prebuilt/Popups/Base/Components/PopupFooter";
import { RegularCheck } from "../../../PSolutions.Components/Inputs/Components/RegularCheck";
import { PopupContainer } from "../../../PSolutions.Prebuilt/Popups/Base/Components/PopupContainer";

interface Props {
  visible: boolean;
  isBusy: boolean;
  selectedAppointment: IAppointmentUpsertModel;

  closeModal(): void;

  onBackClick(): void;

  onCancelClick(request: ICancelAppointmentRequest): void;
}

interface State {
  sendSms: boolean;
  cancelAllSeries: boolean;
  sendCancellationNotification: boolean;
}

export class CancellationPopup extends React.PureComponent<Props, State> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.toggleSms = this.toggleSms.bind(this);
    this.toggleSeries = this.toggleSeries.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.state = {sendCancellationNotification: false, sendSms: false, cancelAllSeries: false};
  }

  toggleNotification() {
    this.setState({sendCancellationNotification: !this.state.sendCancellationNotification})
  }

  toggleSms() {
    this.setState({sendSms: false})
  }

  toggleSeries() {
    this.setState({cancelAllSeries: false})
  }

  handleCancel() {
    const req: ICancelAppointmentRequest = {
      cancelAllSeries: false,
      status: AppointmentStatusEnum.Canceled,
      sendCancellationSms: this.state.sendSms,
      appointmentId: this.props.selectedAppointment.id,
      sendCancellationNotification: this.state.sendCancellationNotification,
    };

    this.props.onCancelClick(req);
    this.setState({sendSms: false, cancelAllSeries: false, sendCancellationNotification: false});
  }

  render() {
    const {cancelAllSeries, sendSms, sendCancellationNotification} = this.state;
    const {visible, closeModal, selectedAppointment, onBackClick} = this.props;
    const avatar = AppointmentUtility.GetAppointmentAvatar(selectedAppointment);
    const name = AppointmentUtility.GetAppointmentDisplayName(selectedAppointment);

    const back = this.context.formatMessage({id: "generic.messages.back"});
    const cancelBtnText = this.context.formatMessage({id: "generic.messages.cancel"});
    const sendSmsTitle = this.context.formatMessage({id: "generic.messages.send.sms"});
    const cancelDescription = this.context.formatMessage({id: "generic.messages.cancel.for"})
    const cancelSeriesTitle = this.context.formatMessage({id: "generic.messages.cancel.all.series"});
    const sendNotificationTitle = this.context.formatMessage({id: "generic.messages.send.notification"});

    return (
      <TekicaPopup closeModal={closeModal} preventRequestClose show={visible} title={name} profilePhoto={avatar} name={name} subtitle={cancelDescription}>
        <PopupContainer>
          <RegularCheck label={sendSmsTitle} value={sendSms} id="send-sms-notification" handleChange={this.toggleSms} containerStyle={styles.cancelCheckBox}/>
          <RegularCheck label={cancelSeriesTitle} value={cancelAllSeries} id="cancel-series-notification" handleChange={this.toggleSeries} containerStyle={styles.cancelCheckBox}/>
          <RegularCheck label={sendNotificationTitle} value={sendCancellationNotification} id="send-series-notification" handleChange={this.toggleNotification} containerStyle={styles.cancelCheckBox}/>
        </PopupContainer>
        <PopupFooter styles={styles.footer}>
          <PopupRow>
            <FlatButton text={back} isBusy={false} hide={!visible} className={styles.cancelBtnMargin} onClick={onBackClick}/>
            <DangerButton text={cancelBtnText} isBusy={this.props.isBusy} hide={!visible} onClick={this.handleCancel} className={styles.submitCancelBtn}/>
          </PopupRow>
        </PopupFooter>
      </TekicaPopup>
    );
  }
}