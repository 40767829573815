import axios from "axios";
import { Dispatch } from "redux";
import { IEmployeeModel } from "../Types";
import { EmployeeListActionTypesEnum } from "./Types";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";
import { IRootState } from "../../../PSolutions.State/RootReducer";

export interface GetSuccessAction {
  payload: IEmployeeModel;
  type: EmployeeListActionTypesEnum.GET_SUCCESS;
}

export interface GetFailureAction {
  payload: string;
  type: EmployeeListActionTypesEnum.GET_FAILURE;
}

export interface GetRequestedAction {
  type: EmployeeListActionTypesEnum.GET_REQUESTED;
}

export interface GetListSuccessAction {
  payload: Array<IEmployeeModel>;
  type: EmployeeListActionTypesEnum.GET_LIST_SUCCESS;
}


export interface GetListFailureAction {
  payload: string;
  type: EmployeeListActionTypesEnum.GET_LIST_FAILURE;
}

export interface GetListRequestedAction {
  type: EmployeeListActionTypesEnum.GET_LIST_REQUESTED;
}

export interface IEmployeeListClearErrorAction {
  type: EmployeeListActionTypesEnum.CLEAR_ERROR;
}

export interface IEmployeeListClearStateAction {
  type: EmployeeListActionTypesEnum.CLEAR_STATE;
}


export const getAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: EmployeeListActionTypesEnum.GET_REQUESTED});
      const res = await axios.post<IEmployeeModel>(`${AppSettings.apiUrl}/EmployeeList}`);
      dispatch({type: EmployeeListActionTypesEnum.GET_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: EmployeeListActionTypesEnum.GET_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const getEmployeeListAsync = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const officeId = getState().officeSelector.selectedOfficeId;
      dispatch({type: EmployeeListActionTypesEnum.GET_LIST_REQUESTED});
      const res = await axios.get<Array<IEmployeeModel>>(`${AppSettings.apiUrl}/Employee/GetEmployees/${officeId}`);
      dispatch({type: EmployeeListActionTypesEnum.GET_LIST_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: EmployeeListActionTypesEnum.GET_LIST_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}


export const clearError = () => (dispatch: Dispatch) => dispatch({type: EmployeeListActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: EmployeeListActionTypesEnum.CLEAR_STATE});

export type EmployeeListActions =
  GetRequestedAction |
  GetSuccessAction |
  GetFailureAction |
  GetListRequestedAction |
  GetListSuccessAction |
  GetListFailureAction |
  IEmployeeListClearErrorAction |
  IEmployeeListClearStateAction;