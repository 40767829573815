import { Reducer } from "redux";
import { OfficeSelectorActions } from "./Actions";
import { OfficeSelectorActionTypesEnum } from "./Types";
import { IOfficeSelectorModel, IOfficeSelectorState } from "../Types";

const initialState: IOfficeSelectorState = {
  isBusy: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  showDeleted: false,
  showSuccess: false,
  branchOffices: [],
  selectedOfficeId: 0,
  selectedOffice: {} as IOfficeSelectorModel,
}


const OfficeSelectorReducer: Reducer<IOfficeSelectorState, OfficeSelectorActions> = (state: IOfficeSelectorState = initialState, action: OfficeSelectorActions) => {
  switch (action.type) {
    case OfficeSelectorActionTypesEnum.GET_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case OfficeSelectorActionTypesEnum.GET_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case OfficeSelectorActionTypesEnum.GET_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: "", branchOffices: action.payload};

      // Utility
    case OfficeSelectorActionTypesEnum.SET_OFFICE_ID:
      return {...state, selectedOfficeId: action.payload.providerId, selectedOffice: action.payload};

    case OfficeSelectorActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case OfficeSelectorActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default OfficeSelectorReducer;