import React from "react";
import styles from "../../Styles/appointment-upsert.module.css";

interface Props {
  icon: any;
  show: boolean;
  value?: string;
}

export class ClientInfoItem extends React.PureComponent<Props> {
  render() {
    if (!this.props.show) return null;
    return (
      <div className={styles.clientInfoItem}>
        {this.props.icon}
        <span className={styles.clientInfoItemName}>{this.props.value}</span>
      </div>
    );
  }
}