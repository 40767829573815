import { Reducer } from "redux";
import { ShiftsActions } from "./Actions";
import { ShiftsActionTypesEnum } from "./Types";
import { IShiftsState } from "../Types";

const initialState: IShiftsState = {
  isBusy: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  officeShifts: [],
  errorMessage: "",
  showDeleted: false,
  showSuccess: false,
}


const ShiftsReducer: Reducer<IShiftsState, ShiftsActions> = (state: IShiftsState = initialState, action: ShiftsActions) => {
  switch (action.type) {
    case ShiftsActionTypesEnum.GET_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case ShiftsActionTypesEnum.GET_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case ShiftsActionTypesEnum.GET_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: ""};

    case ShiftsActionTypesEnum.GET_LIST_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case ShiftsActionTypesEnum.GET_LIST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case ShiftsActionTypesEnum.GET_LIST_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: "", officeShifts: action.payload};

    case ShiftsActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case ShiftsActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default ShiftsReducer;