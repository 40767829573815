import thunk from "redux-thunk"
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from "redux-persist";
import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from './RootReducer';

/**
 * Initial state of store.
 */
const initialState = {};

/**
 * List of enhancers.
 */
const enhancers: Array<any> = [];

/**
 * Persist configuration.
 * Config is used when app is loaded.
 * For now, only app reducer will be persisted to localstorage.
 */
const persistConfig = {key: 'root', storage: storage, whitelist: ['app', 'officeSelector']};

/**
 *  Apply middlewares to handle redux actions.
 *  Here you can add as many middlewares you want.
 */
const middleware = [thunk];

/**
 * Compose middlewares and enhancers
 */
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

/**
 * Create persist reducer.
 */
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Create store
 */
const store = createStore(persistedReducer, initialState, composedEnhancers);

/**
 * Persist store
 */
let persistor = persistStore(store);

/**
 * Exports
 */
export { store, persistor, };