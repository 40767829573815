import { Reducer } from "redux";
import { ISignInState } from "../Types";
import { SignInActions } from "./Actions";
import { SignInActionTypesEnum } from "./Types";

const initialState: ISignInState = {
  isBusy: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  showDeleted: false,
  showSuccess: false,
}


const signInReducer: Reducer<ISignInState, SignInActions> = (state: ISignInState = initialState, action: SignInActions) => {
  switch (action.type) {
    case SignInActionTypesEnum.SIGN_IN_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case SignInActionTypesEnum.SIGN_IN_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case SignInActionTypesEnum.SIGN_IN_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: ""};

    case SignInActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default signInReducer;