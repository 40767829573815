import { Reducer } from "redux";
import { ISelectClientState } from "../Types";
import { SelectClientActions } from "./Actions";
import { SelectClientActionTypesEnum } from "./Types";

const initialState: ISelectClientState = {
  page: 1,
  pageSize: 25,
  clients: [],
  nextPage: 1,
  totalCount: 0,
  isBusy: false,
  hasMore: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  isLoadMore: false,
  showDeleted: false,
  showSuccess: false,
}

const SelectClientReducer: Reducer<ISelectClientState, SelectClientActions> = (state: ISelectClientState = initialState, action: SelectClientActions) => {
  switch (action.type) {
    case SelectClientActionTypesEnum.GET_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case SelectClientActionTypesEnum.GET_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case SelectClientActionTypesEnum.GET_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: "",};

    case SelectClientActionTypesEnum.GET_LIST_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case SelectClientActionTypesEnum.GET_LIST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case SelectClientActionTypesEnum.GET_LIST_SUCCESS:
      return {
        ...state,
        isBusy: false,
        showError: false,
        errorMessage: "",
        page: action.payload.page,
        hasMore: action.payload.hasMore,
        nextPage: action.payload.nextPage,
        totalCount: action.payload.totalCount,
        clients: [...state.clients, ...action.payload.data]
      };

    case SelectClientActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case SelectClientActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default SelectClientReducer;