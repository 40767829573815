import React from "react";
import * as dateFns from "date-fns";
import styles from "../../Styles/work.schedule.module.css";
import { TEKICA_SHIFT_FORMAT } from "../../../../PSolutions.Config/TekicaTimeFormat";
import { TranslationContext } from "../../../../PSolutions.Providers/Translation/TranslationContext";
import { FormattedMessage } from "react-intl";

interface Props {
  endDate: Date;
  startDate: Date;
}

export class WeekDisplay extends React.PureComponent<Props> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;

  render() {
    const {endDate, startDate} = this.props;
    const options = {locale: this.context.currentLocale};
    const daysBetween = dateFns.eachDayOfInterval({start: startDate, end: endDate});

    return (
      <div className={styles.weekDisplay}>
        <div className={styles.dayDisplay}><FormattedMessage id="generic.messages.employees" /></div>
        {daysBetween.map((d: Date, i: number) => <div key={i} className={styles.dayDisplay}>{dateFns.format(d, TEKICA_SHIFT_FORMAT, options)}</div>)}
      </div>
    );
  }
}