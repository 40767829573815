import axios from "axios";
import { Dispatch } from "redux";
import { IOfficeSelectorModel } from "../Types";
import { OfficeSelectorActionTypesEnum } from "./Types";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";

export interface GetSuccessAction {
  payload: Array<IOfficeSelectorModel>;
  type: OfficeSelectorActionTypesEnum.GET_SUCCESS;
}

export interface GetFailureAction {
  payload: string;
  type: OfficeSelectorActionTypesEnum.GET_FAILURE;
}

export interface GetRequestedAction {
  type: OfficeSelectorActionTypesEnum.GET_REQUESTED;
}

export interface IOfficeSelectorClearErrorAction {
  type: OfficeSelectorActionTypesEnum.CLEAR_ERROR;
}

export interface IOfficeSelectorClearStateAction {
  type: OfficeSelectorActionTypesEnum.CLEAR_STATE;
}

export interface SetSelectedOfficeIdAction {
  payload: IOfficeSelectorModel;
  type: OfficeSelectorActionTypesEnum.SET_OFFICE_ID
}

const path = "BusinessAccount/GetAccountProviders";

export const getAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: OfficeSelectorActionTypesEnum.GET_REQUESTED});
      const res = await axios.get<Array<IOfficeSelectorModel>>(`${AppSettings.apiUrl}/${path}`);
      dispatch({type: OfficeSelectorActionTypesEnum.GET_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: OfficeSelectorActionTypesEnum.GET_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const clearError = () => (dispatch: Dispatch) => dispatch({type: OfficeSelectorActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: OfficeSelectorActionTypesEnum.CLEAR_STATE});
export const setOfficeId = (office: IOfficeSelectorModel) => (dispatch: Dispatch) => dispatch({type: OfficeSelectorActionTypesEnum.SET_OFFICE_ID, payload: office});

export type OfficeSelectorActions = GetRequestedAction | GetSuccessAction | GetFailureAction | IOfficeSelectorClearErrorAction | IOfficeSelectorClearStateAction | SetSelectedOfficeIdAction;