import { combineReducers } from "redux";
import AppReducer from '../PSolutions.App/Redux/Reducer';
import PauseReducer from "../PSolutions.Containers/Pause/Redux/Reducer";
import SignInReducer from '../PSolutions.Containers/SignIn/Redux/Reducer';
import ShiftsReducer from "../PSolutions.Containers/Shifts/Redux/Reducer";
import WorkScheduleReducer from "../PSolutions.Containers/WorkSchedule/Redux/Reducer";
import EmployeeListReducer from "../PSolutions.Containers/EmployeeList/Redux/Reducer";
import SelectClientReducer from '../PSolutions.Containers/SelectClient/Redux/Reducer';
import OfficeSelectorReducer from "../PSolutions.Containers/OfficeSelector/Redux/Reducer";
import AppointmentListReducer from '../PSolutions.Containers/AppointmentList/Redux/Reducer';
import ServiceSelectorReducer from "../PSolutions.Containers/ServiceSelector/Redux/Reducer";
import AppointmentUpsertReducer from "../PSolutions.Containers/AppointmentUpsert/Redux/Reducer";

/**
 * In this object, whole application state is contained.
 */
const reducers = combineReducers({
  app: AppReducer,
  pause: PauseReducer,
  shift: ShiftsReducer,
  signIn: SignInReducer,
  selectClient: SelectClientReducer,
  employeeList: EmployeeListReducer,
  workSchedule: WorkScheduleReducer,
  officeSelector: OfficeSelectorReducer,
  serviceSelector: ServiceSelectorReducer,
  appointmentList: AppointmentListReducer,
  appointmentUpsert: AppointmentUpsertReducer,
});

/**
 * Export type for root reducer.
 * So devs can get easy intellisense.
 */
export type IRootState = ReturnType<typeof reducers>;

/**
 * Export
 */
export default reducers;
