import _ from 'lodash';
import React from "react";
import * as dateFns from 'date-fns';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IWorkScheduleModel } from "../../Types";
import { AddIndicator } from "../Upsert/AddIndicator";
import { ShiftDisplay } from "../Time/ShiftDisplay";
import styles from '../../Styles/work.schedule.module.css';
import { StandardDisplay } from "../Time/StandardDisplay";
import { IEmployeeModel } from "../../../EmployeeList/Types";
import { IRootState } from "../../../../PSolutions.State/RootReducer";
import { TEKICA_DATE_FORMAT } from "../../../../PSolutions.Config/TekicaTimeFormat";
import { EmployeeUtilities } from "../../../../PSolutions.Utility/EmployeeUtilities";

interface Props {
  date: Date;
  employee: IEmployeeModel;
  schedule: Array<IWorkScheduleModel>;

  onItemClick(date: Date, employee: IEmployeeModel): void;
}

class WeekItem extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.getScheduleForWeek = this.getScheduleForWeek.bind(this);
  }

  onClick() {
    this.props.onItemClick(this.props.date, this.props.employee);
  }

  getScheduleForWeek() {
    const {schedule, date, employee} = this.props;
    const formattedDate = dateFns.format(date, TEKICA_DATE_FORMAT);
    const filtered = schedule.filter((s: IWorkScheduleModel) => s.employeeId === employee.id && s.date === formattedDate);
    return _.sortBy(filtered, ['customStartTime']);
  }

  render() {
    const {date, employee} = this.props;
    const schedule = this.getScheduleForWeek();
    const standardTime = EmployeeUtilities.mapHoursToDay(date, employee);

    return (
      <div className={styles.weekItem} onClick={this.onClick}>
        <div className={styles.itemWrapper}>
          <StandardDisplay standardTime={standardTime}/>
          <AddIndicator show={!schedule.length && (!standardTime?.startTime || !standardTime?.endTime)}/>
          {schedule.map((s: IWorkScheduleModel, i: number) => <ShiftDisplay key={i} shift={s}/>)}
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state: IRootState) => {
  return {
    schedule: state.workSchedule.schedule
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WeekItem);