import React from "react";
import { useIntl } from "react-intl";
import { FormikProps } from "formik";
import styles from '../Styles/sign-in.module.css';
import { InputField } from "../../../PSolutions.Components/Fields/InputField";
import { PrimaryButton } from "../../../PSolutions.Components/Buttons/PrimaryButton";

interface Props extends FormikProps<any> {
}

export const SignInView = React.memo(({submitForm, isSubmitting}: Props) => {
    const intl = useIntl();

    const title = intl.formatMessage({id: "sign.in.title"});
    const signInText = intl.formatMessage({id: "sign.in.button.continue"});
    const passwordLabel = intl.formatMessage({id: "generic.messages.password.label"});
    const usernameLabel = intl.formatMessage({id: "generic.messages.email.label"});
    const usernamePlaceholder = intl.formatMessage({id: "generic.messages.email.placeholder"});
    const passwordPlaceholder = intl.formatMessage({id: "generic.messages.password.placeholder"});

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.formContent}>
          <InputField
            required
            id="email"
            type="text"
            name="email"
            label={usernameLabel}
            placeholder={usernamePlaceholder}
          />
          <InputField
            required
            id="password-id"
            name="password"
            type="password"
            label={passwordLabel}
            placeholder={passwordPlaceholder}
          />
        </div>
        <PrimaryButton isBusy={isSubmitting} text={signInText} onClick={submitForm}/>
      </div>
    );
  }
);