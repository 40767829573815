import axios from "axios";
import { Dispatch } from "redux";
import { IPauseModel } from "../Types";
import { PauseActionTypesEnum } from "./Types";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";

export interface GetSuccessAction {
  payload: IPauseModel;
  type: PauseActionTypesEnum.GET_SUCCESS;
}

export interface GetFailureAction {
  payload: string;
  type: PauseActionTypesEnum.GET_FAILURE;
}

export interface AddFailureAction {
  payload: string;
  type: PauseActionTypesEnum.ADD_FAILURE;
}

export interface GetRequestedAction {
  type: PauseActionTypesEnum.GET_REQUESTED;
}

export interface IPauseClearErrorAction {
  type: PauseActionTypesEnum.CLEAR_ERROR;
}

export interface IPauseClearStateAction {
  type: PauseActionTypesEnum.CLEAR_STATE;
}


export const addAsync = (pauseModel: IPauseModel) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: PauseActionTypesEnum.ADD_REQUESTED});
      const res = await axios.post<IPauseModel>(`${AppSettings.apiUrl}/Appointments/MakePause`, pauseModel);
      dispatch({type: PauseActionTypesEnum.ADD_SUCCESS, payload: res.data});
      return true;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: PauseActionTypesEnum.ADD_FAILURE, payload: manager.getApiError()})
      return false;
    }
  }
}

export const removeAsync = (pauseModel: IPauseModel) => {
  return async (dispatch: Dispatch) => {
    try {
      const id = pauseModel.id;
      dispatch({type: PauseActionTypesEnum.REMOVE_REQUESTED});
      const res = await axios.delete(`${AppSettings.apiUrl}/Appointments/DeletePause/${id}`);
      dispatch({type: PauseActionTypesEnum.REMOVE_SUCCESS, payload: res.data});
      return true;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: PauseActionTypesEnum.REMOVE_FAILURE, payload: manager.getApiError()})
      return false;
    }
  }
}

export const clearError = () => (dispatch: Dispatch) => dispatch({type: PauseActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: PauseActionTypesEnum.CLEAR_STATE});

export type PauseActions = GetRequestedAction | GetSuccessAction | GetFailureAction | IPauseClearErrorAction | IPauseClearStateAction | AddFailureAction;