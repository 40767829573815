export enum AppointmentUpsertActionTypesEnum {
  CLEAR_ERROR = "AppointmentUpsert/CLEAR_ERROR",
  CLEAR_STATE = "AppointmentUpsert/CLEAR_STATE",

  ADD_FAILURE = "AppointmentUpsert/ADD_FAILURE",
  ADD_SUCCESS = "AppointmentUpsert/ADD_SUCCESS",
  ADD_REQUESTED = "AppointmentUpsert/ADD_REQUESTED",

  UPDATE_FAILURE = "AppointmentUpsert/UPDATE_FAILURE",
  UPDATE_SUCCESS = "AppointmentUpsert/UPDATE_SUCCESS",
  UPDATE_REQUESTED = "AppointmentUpsert/UPDATE_REQUESTED",

  GET_FAILURE = "AppointmentUpsert/GET_FAILURE",
  GET_SUCCESS = "AppointmentUpsert/GET_SUCCESS",
  GET_REQUESTED = "AppointmentUpsert/GET_REQUESTED",

  GET_LIST_FAILURE = "AppointmentUpsert/GET_LIST_FAILURE",
  GET_LIST_SUCCESS = "AppointmentUpsert/GET_LIST_SUCCESS",
  GET_LIST_REQUESTED = "AppointmentUpsert/GET_LIST_REQUESTED",

  CLEAR_SELECTED_APPOINTMENT = "AppointmentUpsert/CLEAR_SELECTED_APPOINTMENT",
  SET_APPOINTMENT_INITIAL_DATA = "AppointmentUpsert/SET_APPOINTMENT_INITIAL_DATA",
  SET_SELECTED_APPOINTMENT_DATA = "AppointmentUpsert/SET_SELECTED_APPOINTMENT_DATA",
  SET_CALENDAR_MANAGER_SELECTION = "AppointmentUpsert/SET_CALENDAR_MANAGER_SELECTION",
}