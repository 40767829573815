import React from "react";
import { IAppointmentUpsertModel } from "../Types";
import styles from '../Styles/appointment-upsert.module.css';
import { UserAvatar } from "../../../PSolutions.Components/Avatar/UserAvatar";

interface Props {
  selectedAppointment: IAppointmentUpsertModel;
}

export class AppointmentAvatar extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.getClientName = this.getClientName.bind(this);
  }

  getClientName() {
    const {selectedAppointment} = this.props;
    return !!selectedAppointment?.clientId ? selectedAppointment?.client?.name : selectedAppointment.walkInClientName;
  }

  render() {
    const {selectedAppointment} = this.props;
    return (
      <div className={styles.appointmentClientAvatarHolder}>
        <UserAvatar size="65px" round={true} name={this.getClientName() || ""} profilePhoto={selectedAppointment?.client?.profilePhoto || ""}/>
      </div>
    );
  }
}