import { Reducer } from "redux";
import { IWorkScheduleState } from "../Types";
import { WorkScheduleActions } from "./Actions";
import { WorkScheduleActionTypesEnum } from "./Types";

const initialState: IWorkScheduleState = {
  isBusy: false,
  schedule: [],
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  showDeleted: false,
  showSuccess: false,
}


const WorkScheduleReducer: Reducer<IWorkScheduleState, WorkScheduleActions> = (state: IWorkScheduleState = initialState, action: WorkScheduleActions) => {
  switch (action.type) {
    case WorkScheduleActionTypesEnum.GET_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case WorkScheduleActionTypesEnum.GET_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case WorkScheduleActionTypesEnum.GET_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: ""};

    case WorkScheduleActionTypesEnum.GET_LIST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case WorkScheduleActionTypesEnum.GET_LIST_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case WorkScheduleActionTypesEnum.GET_LIST_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: "", schedule: action.payload};

    case WorkScheduleActionTypesEnum.UPDATE_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case WorkScheduleActionTypesEnum.UPDATE_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case WorkScheduleActionTypesEnum.UPDATE_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: ""};

    case WorkScheduleActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case WorkScheduleActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default WorkScheduleReducer;