import React from "react";
import * as dateFns from 'date-fns'
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import { IWorkRequestModel, IWorkScheduleModel } from "../Types";
import { IShiftModel } from "../../Shifts/Types";
import { updateScheduleAsync } from "../Redux/Actions";
import styles from '../Styles/work.schedule.module.css';
import { RepeatDays } from "../Components/Repeat/RepeatDays";
import { ISelectOption } from "../../../PSolutions.Types/Common";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { RepeatingRules } from "../Components/Repeat/RepeatingRules";
import { IEmployeeModel, ITimeRange } from "../../EmployeeList/Types";
import { WorkingIndicator } from "../Components/Upsert/WorkingIndicator";
import { UpsertScheduleRow } from "../Components/Upsert/UpsertScheduleRow";
import { BaseModal } from "../../../PSolutions.Components/Modals/Base/BaseModal";
import { EmployeeUtilities } from "../../../PSolutions.Utility/EmployeeUtilities";
import { PrimaryButton } from "../../../PSolutions.Components/Buttons/PrimaryButton";
import { OutlineButton } from "../../../PSolutions.Components/Buttons/OutlineButton";
import { ModalFooter } from "../../../PSolutions.Components/Modals/Parts/ModalFooter";
import { ModalHeader } from "../../../PSolutions.Components/Modals/Parts/ModalHeader";
import { ModalContent } from "../../../PSolutions.Components/Modals/Parts/ModalContent";
import { ModalContainer } from "../../../PSolutions.Components/Modals/Parts/ModalContainer";
import { TranslationContext } from "../../../PSolutions.Providers/Translation/TranslationContext";
import { TEKICA_CALENDAR_FORMAT, TEKICA_DATE_FORMAT } from "../../../PSolutions.Config/TekicaTimeFormat";

interface Props {
  intl: any
  show: boolean;
  isBusy: boolean;
  selectedDate: Date;
  selectedEmployee: IEmployeeModel;
  officeShifts: Array<IShiftModel>;
  schedule: Array<IWorkScheduleModel>;

  closeModal(reload?: boolean): void;

  updateScheduleAsync(model: IWorkRequestModel): any
}

interface State {
  repeatTo: Date;
  notWorking: boolean;
  repeatRule: ISelectOption,
  selectedDays: Array<number>;
  shifts: Array<IWorkScheduleModel>;
}

class UpsertScheduleContainer extends React.PureComponent<Props, State> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      shifts: [],
      selectedDays: [],
      notWorking: false,
      repeatTo: props.selectedDate,
      repeatRule: this.getRepeatRuleOptions()[0],
    };
    this.addNewShift = this.addNewShift.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectDay = this.handleSelectDay.bind(this);
    this.handleEndRepeat = this.handleEndRepeat.bind(this);
    this.toggleIsWorking = this.toggleIsWorking.bind(this);
    this.getDefaultShift = this.getDefaultShift.bind(this);
    this.getRequestModel = this.getRequestModel.bind(this);
    this.getSelectedShifts = this.getSelectedShifts.bind(this);
    this.handleRepeatChange = this.handleRepeatChange.bind(this);
    this.handleSubmitAsync = this.handleSubmitAsync.bind(this);
    this.getRepeatRuleOptions = this.getRepeatRuleOptions.bind(this);
    this.getEmployeeFixedTime = this.getEmployeeFixedTime.bind(this);
  }

  async handleSubmitAsync() {
    const model = this.getRequestModel(this.state);
    const res = await this.props.updateScheduleAsync(model);
    if (!!res) this.onCloseModal(true);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!this.props.show) return;
    if (prevProps.show === this.props.show) return;

    const shifts = this.getSelectedShifts();
    if (!!shifts && !!shifts.length) return this.setState({shifts, repeatTo: this.props.selectedDate});
    else return this.setState({shifts: [this.getDefaultShift()], repeatTo: this.props.selectedDate});
  }

  getRepeatRuleOptions(): Array<ISelectOption> {
    const noRepeat = this.props.intl.formatMessage({id: "generic.messages.repeat.rule.no.repeat"});
    const weeklyRepeat = this.props.intl.formatMessage({id: "generic.messages.repeat.rule.weekly"});
    const biWeeklyRepeat = this.props.intl.formatMessage({id: "generic.messages.repeat.rule.bi.weekly"});
    return [{value: -1, label: noRepeat}, {value: 0, label: weeklyRepeat}, {value: 2, label: biWeeklyRepeat}];
  }

  toggleIsWorking() {
    this.setState({notWorking: !this.state.notWorking})
  }

  handleSelectDay(day: number) {
    const {selectedDays} = this.state;
    const filtered = selectedDays.includes(day) ? selectedDays.filter((d: number) => d !== day) : [...selectedDays, day];
    this.setState({selectedDays: filtered});
  }

  handleEndRepeat(date: Date) {
    this.setState({repeatTo: date});
  }

  handleRepeatChange(option: ISelectOption) {
    this.setState({repeatRule: option});
  }

  handleChange(schedule: IWorkScheduleModel, index: number) {
    const {shifts} = this.state;
    const mapped = [...shifts.map((s: IWorkScheduleModel, i: number) => i === index ? schedule : s)];
    this.setState({shifts: mapped})
  }

  addNewShift() {
    this.setState({shifts: [...this.state.shifts, this.getDefaultShift()]});
  }

  onCloseModal(reload?: boolean) {
    const state = {shifts: [], notWorking: false, selectedDays: [], repeatTo: this.props.selectedDate, repeatRule: this.getRepeatRuleOptions()[0]}
    this.setState({...state}, () => this.props.closeModal(reload));
  }

  getSelectedShifts() {
    const {selectedEmployee, selectedDate} = this.props;
    const formatted = dateFns.format(selectedDate, TEKICA_DATE_FORMAT);
    return this.props.schedule.filter((s: IWorkScheduleModel) => s.employeeId === selectedEmployee.id && formatted === s.date)
  }

  getEmployeeFixedTime(): IWorkScheduleModel {
    const {selectedEmployee, selectedDate} = this.props;
    const workingHours: ITimeRange | undefined = EmployeeUtilities.mapHoursToDay(selectedDate, selectedEmployee);

    if (!workingHours) return {} as IWorkScheduleModel;
    return {customEndTime: workingHours?.endTime, customStartTime: workingHours.startTime} as IWorkScheduleModel;
  }

  getDefaultShift() {
    const {selectedEmployee, selectedDate} = this.props;
    const formatted = dateFns.format(selectedDate, TEKICA_DATE_FORMAT);
    return {employeeId: selectedEmployee.id, customStartTime: "08:00", customEndTime: "16:00", date: formatted} as IWorkScheduleModel;
  }

  getRequestModel(state: State): IWorkRequestModel {
    return {
      shifts: state.shifts,
      notWorking: state.notWorking,
      selectedDays: state.selectedDays,
      employeeId: this.props.selectedEmployee.id,
      frequency: Math.max(state.repeatRule.value, 0),
      repeatToDate: dateFns.format(state.repeatTo, TEKICA_DATE_FORMAT),
      selectedDate: dateFns.format(this.props.selectedDate, TEKICA_DATE_FORMAT),
    }
  }

  render() {
    const fixedTime = this.getEmployeeFixedTime();
    const repeatRules = this.getRepeatRuleOptions();
    const options = {locale: this.context.currentLocale};
    const {shifts, repeatTo, repeatRule, notWorking} = this.state;
    const {show, selectedDate, selectedEmployee, officeShifts} = this.props;
    const fullName = `${selectedEmployee.firstName} ${selectedEmployee.lastName}`;
    const saveText = this.props.intl.formatMessage({id: "generic.messages.save"})
    const cancelText = this.props.intl.formatMessage({id: "generic.messages.cancel"})
    const formattedDate = dateFns.format(selectedDate, TEKICA_CALENDAR_FORMAT, options);
    const addText = this.props.intl.formatMessage({id: "office.shift.shifts.add.new.shift"})
    const fixedTimeText = this.props.intl.formatMessage({id: "generic.messages.standard.time"});
    const customTimeText = this.props.intl.formatMessage({id: "generic.messages.custom.time"});
    return (
      <BaseModal closeModal={this.onCloseModal} topPosition={40} topPadding={50} visible={show} preventRequestClose>
        <ModalContainer>
          <ModalHeader fullName={fullName} photoUrl={selectedEmployee.profilePhoto}/>
          <ModalContent title={formattedDate} maxHeight="350px" showBorder>
            <WorkingIndicator
              checked={notWorking}
              toggleIsWorking={this.toggleIsWorking}
              show={shifts.some((s: IWorkScheduleModel) => s.id > 0)}
            />
            <div className={styles.upsertContainer}>
              <UpsertScheduleRow
                index={0}
                disabled={true}
                data={fixedTime}
                isFirst={!!fixedTime}
                shifts={officeShifts}
                fixedTimeText={fixedTimeText}
                hide={!fixedTime.customEndTime || !fixedTime.customStartTime}
              />
              {
                shifts.map((s: IWorkScheduleModel, i: number) =>
                  <UpsertScheduleRow
                    key={i}
                    data={s}
                    index={i}
                    shifts={officeShifts}
                    isFirst={i === 0 && !fixedTime}
                    customTimeText={customTimeText}
                    handleScheduleChange={this.handleChange}
                  />
                )
              }
              <button className={styles.addNewSchedule} onClick={this.addNewShift}>{addText}<i className="fa fa-plus-circle"/></button>
              <RepeatingRules
                repeatTo={repeatTo}
                repeatRule={repeatRule}
                repeatRules={repeatRules}
                selectedDate={selectedDate}
                show={repeatRule.value >= 0}
                locale={this.context.currentLocale}
                handleEndRepeatChange={this.handleEndRepeat}
                handleRepeatChange={this.handleRepeatChange}
              />
              <RepeatDays
                show={repeatRule.value >= 0}
                selectedDays={this.state.selectedDays}
                handleSelectDay={this.handleSelectDay}
              />
            </div>
          </ModalContent>
          <ModalFooter>
            <div className={styles.upsertContainerFooter}>
              <OutlineButton text={cancelText} isBusy={false} className={styles.btnCancel} onClick={() => this.onCloseModal()}/>
              <PrimaryButton text={saveText} isBusy={this.props.isBusy} className={styles.btnSave} onClick={this.handleSubmitAsync}/>
            </div>
          </ModalFooter>
        </ModalContainer>
      </BaseModal>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isBusy: state.workSchedule.isBusy,
    schedule: state.workSchedule.schedule,
    officeShifts: state.shift.officeShifts,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({updateScheduleAsync}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UpsertScheduleContainer))