import React from "react";
import { IntlContext } from "react-intl";
import { BsArrowRight } from "react-icons/all";
import styles from '../Styles/service-selector.module.css';
import { IServiceModel } from "../../AppointmentUpsert/Types";
import { UserAvatar } from "../../../PSolutions.Components/Avatar/UserAvatar";

interface Props {
  service: IServiceModel;

  onServiceClick(service: IServiceModel): void;
}

export class ServiceSelectorItem extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.onClientClick = this.onClientClick.bind(this);
  }

  onClientClick() {
    this.props.onServiceClick(this.props.service)
  }

  render() {
    const {service} = this.props;
    const serviceDuration = this.context.formatMessage({id: "generic.messages.service.duration"})
    return (
      <div className={styles.selectServiceContainer} onClick={this.onClientClick}>
        <div className={styles.leftSide}>
          <UserAvatar size="50px" round name={service.serviceName} profilePhoto={service.servicePhoto}/>
          <div className={styles.serviceInfoHolder}>
            <div className={styles.serviceName}>{service.serviceName}</div>
            <div className={styles.serviceDuration}>{serviceDuration}: <em><strong>{service.estimatedDuration} min</strong> </em></div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <BsArrowRight size="1.5em" color="var(--ternary-border-color)" className={styles.arrowRight}/>
        </div>
      </div>
    );
  }
}