import React from "react";
import classNames from "classnames";
import styles from "../Styles/modal.module.css";

interface Props {
  children: any;
  removeTop?: boolean;
}

export class ModalContainer extends React.PureComponent<Props> {
  render() {
    const {removeTop} = this.props;
    return <div className={classNames(styles.modalWithIcon, {[styles.removeTop]: !!removeTop})}>{this.props.children}</div>;
  }
}