import React from "react";
import styles from '../Styles/popup.module.css'

export class PopupRow extends React.PureComponent {
  render() {
    return (
      <div className={styles.popupFooterDefaultRow}>
        <div className={styles.popupFooterDefaultRowWrapper}>{this.props.children}</div>
      </div>
    );
  }
}