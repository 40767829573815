import React from "react";
import { FormattedMessage } from "react-intl";
import { EventContentArg } from "@fullcalendar/react";
import { AllDay } from "./AllDay";
import classNames from "classnames";
import { NoteIndicator } from "./NoteIndicator";
import { EventIndicator } from "./EventIndicator";
import styles from '../../Styles/event.module.css';
import { AppointmentStatusEnum } from "../../../../PSolutions.Enums/AppointmentStatusEnum";

interface Props {
  eventInfo: EventContentArg;
}

export class Event extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.getStyles = this.getStyles.bind(this);
    this.getColorForNoShow = this.getColorForNoShow.bind(this);
    this.getColorForFullyPaid = this.getColorForFullyPaid.bind(this);
  }

  getColorForNoShow() {
    return {backgroundColor: "var(--appointment-fully-paid-color)", borderLeftColor: "var(--status-cancel-color)"};
  }

  getColorForFullyPaid() {
    return {backgroundColor: "var(--appointment-fully-paid-color)", borderLeftColor: "var(--appointment-fully-paid-border-color)"};
  }

  getStyles() {
    let {borderColor, backgroundColor, event} = this.props.eventInfo;
    let defaultStyle: any = {backgroundColor: backgroundColor, borderLeftColor: borderColor};

    // Handle styles
    if (event?.extendedProps?.status === AppointmentStatusEnum.FullyPaid) return this.getColorForFullyPaid();
    if (event?.extendedProps?.status === AppointmentStatusEnum.NoShow) return this.getColorForNoShow();
    if (!!this.props.eventInfo?.event?.extendedProps?.status || null) return defaultStyle;

    defaultStyle.backgroundColor = "var(--fourth-container-background-color)";
    defaultStyle.border = "1px dashed var(--secondary-border-color)";
    return defaultStyle;
  }

  render() {
    const {event} = this.props.eventInfo;
    const allDay = event.allDay;
    const status = event?.extendedProps?.status || undefined;
    const clientName = event?.extendedProps?.clientName || "";
    const endTimeDisplay = event?.extendedProps?.endTimeDisplay || "";
    const startTimeDisplay = event?.extendedProps?.startTimeDisplay || "";

    if (allDay) return <AllDay eventInfo={this.props.eventInfo}/>
    const displayServiceAndClient = !!clientName ? `${clientName} - ` : "";
    const appointmentTitleDisplay = this.props?.eventInfo?.event?.title || "";

    const appliedStyles = !status ? classNames(styles.eventContainer, styles.shadow) : styles.eventContainer;

    return (
      <div className={appliedStyles} style={this.getStyles()}>
        <div className={styles.infoWrapper}>
          {!status && <div className={styles.timeText}>{this.props.eventInfo.timeText}</div>}
          {!!status && <div className={styles.timeText}>{startTimeDisplay} - {endTimeDisplay}</div>}
          {status === AppointmentStatusEnum.TimeReservation && <div className={styles.titleText}><FormattedMessage id="appointment.pause.title"/></div>}
          <div className={styles.titleText}>{!!displayServiceAndClient && !!appointmentTitleDisplay && `${displayServiceAndClient}${appointmentTitleDisplay}`}</div>
        </div>
        <EventIndicator status={status}/>
        <NoteIndicator show={!!event.extendedProps.hasNote}/>
      </div>
    );
  }
}