import { AppointmentStatusEnum } from "../PSolutions.Enums/AppointmentStatusEnum";
import { IAppointmentUpsertModel } from "../PSolutions.Containers/AppointmentUpsert/Types";

export class AppointmentUtility {
  /**
   * Gets corresponding color for status
   * @param status
   * @constructor
   */
  public static GetColorForStatus(status: number) {
    if (status === AppointmentStatusEnum.NoShow) return "var(--status-cancel-color)"
    if (status === AppointmentStatusEnum.Canceled) return "var(--status-cancel-color)"
    if (status === AppointmentStatusEnum.Declined) return "var(--status-cancel-color)"
    if (status === AppointmentStatusEnum.ClientCanceled) return "var(--status-cancel-color)"
    if (status === AppointmentStatusEnum.Confirmed) return "var(--status-confirmed-color)"
    if (status === AppointmentStatusEnum.NeedApproval) return "var(--status-need-approval-color)"
    if (status === AppointmentStatusEnum.Done) return "var(--appointment-fully-paid-border-color)"
    if (status === AppointmentStatusEnum.FullyPaid) return "var(--appointment-fully-paid-border-color)"
  }

  public static GetAppointmentDisplayName(appointment: IAppointmentUpsertModel): string {
    const filtered = !!appointment?.clientId ? appointment?.client?.name : appointment?.walkInClientName;
    return !!filtered ? filtered : "";
  }

  public static GetAppointmentAvatar(appointment: IAppointmentUpsertModel): string {
    return appointment.client?.profilePhoto || "";
  }

  public static IsRecurring(appointment: IAppointmentUpsertModel): boolean {
    return !!appointment.frequency && !!appointment.repeatToDate;
  }
}