import React from "react";
import styles from '../Styles/input.module.css';

interface Props {
  show: boolean;
}

export class ModalInputHolder extends React.PureComponent<Props> {
  static defaultProps = {show: true}

  render() {
    if (!this.props.show) return null;
    return (
      <div className={styles.modalPreventBlurHolder}>{this.props.children}</div>
    );
  }
}