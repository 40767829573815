export enum SelectClientActionTypesEnum {
  CLEAR_ERROR = "SelectClient/CLEAR_ERROR",
  CLEAR_STATE = "SelectClient/CLEAR_STATE",

  GET_FAILURE = "SelectClient/GET_FAILURE",
  GET_SUCCESS = "SelectClient/GET_SUCCESS",
  GET_REQUESTED = "SelectClient/GET_REQUESTED",

  GET_LIST_FAILURE = "SelectClient/GET_LIST_FAILURE",
  GET_LIST_SUCCESS = "SelectClient/GET_LIST_SUCCESS",
  GET_LIST_REQUESTED = "SelectClient/GET_LIST_REQUESTED",
}