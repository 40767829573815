import axios from "axios";
import { Dispatch } from "redux";
import { IAppointmentUpsertModel, ICancelAppointmentRequest } from "../Types";
import { AppointmentUpsertActionTypesEnum } from "./Types";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { ICalendarManagerData } from "../../../PSolutions.Types/Common";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";

export interface GetSuccessAction {
  payload: IAppointmentUpsertModel;
  type: AppointmentUpsertActionTypesEnum.GET_SUCCESS;
}

export interface GetFailureAction {
  payload: string;
  type: AppointmentUpsertActionTypesEnum.GET_FAILURE;
}

export interface GetRequestedAction {
  type: AppointmentUpsertActionTypesEnum.GET_REQUESTED;
}

export interface GetListSuccessAction {
  payload: Array<IAppointmentUpsertModel>;
  type: AppointmentUpsertActionTypesEnum.GET_LIST_SUCCESS;
}

export interface AddSuccessAction {
  payload: IAppointmentUpsertModel;
  type: AppointmentUpsertActionTypesEnum.ADD_SUCCESS;
}

export interface AddFailureAction {
  payload: string;
  type: AppointmentUpsertActionTypesEnum.ADD_FAILURE;
}

export interface AddRequestedAction {
  type: AppointmentUpsertActionTypesEnum.ADD_REQUESTED;
}

export interface UpdateSuccessAction {
  payload: IAppointmentUpsertModel;
  type: AppointmentUpsertActionTypesEnum.UPDATE_SUCCESS;
}

export interface UpdateFailureAction {
  payload: string;
  type: AppointmentUpsertActionTypesEnum.UPDATE_FAILURE;
}

export interface UpdateRequestedAction {
  type: AppointmentUpsertActionTypesEnum.UPDATE_REQUESTED;
}

export interface GetListFailureAction {
  payload: string;
  type: AppointmentUpsertActionTypesEnum.GET_LIST_FAILURE;
}

export interface GetListRequestedAction {
  type: AppointmentUpsertActionTypesEnum.GET_LIST_REQUESTED;
}

export interface IAppointmentUpsertClearErrorAction {
  type: AppointmentUpsertActionTypesEnum.CLEAR_ERROR;
}

export interface IAppointmentUpsertClearStateAction {
  type: AppointmentUpsertActionTypesEnum.CLEAR_STATE;
}

export interface IAppointmentUpsertClearSelectedAppointmentAction {
  type: AppointmentUpsertActionTypesEnum.CLEAR_SELECTED_APPOINTMENT;
}

export interface IAppointmentUpsertInitialDataAction {
  payload: ICalendarManagerData,
  type: AppointmentUpsertActionTypesEnum.SET_APPOINTMENT_INITIAL_DATA,
}

export interface ISetSelectedAppointmentDataAction {
  payload: IAppointmentUpsertModel,
  type: AppointmentUpsertActionTypesEnum.SET_SELECTED_APPOINTMENT_DATA,
}

export interface ISetCalendarManagerSelectionDataAction {
  payload: ICalendarManagerData,
  type: AppointmentUpsertActionTypesEnum.SET_CALENDAR_MANAGER_SELECTION,
}

export const scheduleAppointmentAsync = (appointment: IAppointmentUpsertModel) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      appointment.providerId = getState().officeSelector.selectedOfficeId;
      dispatch({type: AppointmentUpsertActionTypesEnum.ADD_REQUESTED});
      const res = await axios.post(`${AppSettings.apiUrl}/Appointments/ScheduleAppointment`, appointment);
      dispatch({type: AppointmentUpsertActionTypesEnum.ADD_SUCCESS, payload: res.data});
      return true;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: AppointmentUpsertActionTypesEnum.ADD_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const getAppointmentAsync = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: AppointmentUpsertActionTypesEnum.GET_REQUESTED});
      const res = await axios.get<IAppointmentUpsertModel>(`${AppSettings.apiUrl}/Appointments/${id}`);
      dispatch({type: AppointmentUpsertActionTypesEnum.GET_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: AppointmentUpsertActionTypesEnum.GET_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const getListAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: AppointmentUpsertActionTypesEnum.GET_LIST_REQUESTED});
      const res = await axios.get<Array<IAppointmentUpsertModel>>(`${AppSettings.apiUrl}/AppointmentUpsert`);
      dispatch({type: AppointmentUpsertActionTypesEnum.GET_LIST_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: AppointmentUpsertActionTypesEnum.GET_LIST_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const updateAppointmentStatusAsync = (data: ICancelAppointmentRequest) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: AppointmentUpsertActionTypesEnum.UPDATE_REQUESTED});
      await axios.put(`${AppSettings.apiUrl}/Appointments/UpdateStatus`, data);
      dispatch({type: AppointmentUpsertActionTypesEnum.UPDATE_SUCCESS});
      return true;
    } catch (e: any) {
      const manager = new ErrorManager(e);
      dispatch({type: AppointmentUpsertActionTypesEnum.UPDATE_FAILURE, payload: manager.getApiError()});
      return false;
    }
  };
};

export const clearError = () => (dispatch: Dispatch) => dispatch({type: AppointmentUpsertActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: AppointmentUpsertActionTypesEnum.CLEAR_STATE});
export const clearSelectedAppointment = () => (dispatch: Dispatch) => dispatch({type: AppointmentUpsertActionTypesEnum.CLEAR_SELECTED_APPOINTMENT});
export const setCalendarManagerSelectionData = (data: ICalendarManagerData) => (dispatch: Dispatch) => dispatch({type: AppointmentUpsertActionTypesEnum.SET_CALENDAR_MANAGER_SELECTION, payload: data});
export const setInitialAppointmentSelectionData = (data: ICalendarManagerData) => (dispatch: Dispatch) => dispatch({type: AppointmentUpsertActionTypesEnum.SET_APPOINTMENT_INITIAL_DATA, payload: data});
export const setSelectAppointmentInformation = (appointment: IAppointmentUpsertModel) => (dispatch: Dispatch) => dispatch({type: AppointmentUpsertActionTypesEnum.SET_SELECTED_APPOINTMENT_DATA, payload: appointment})


export type AppointmentUpsertActions =
  AddSuccessAction |
  AddFailureAction |
  AddRequestedAction |
  UpdateSuccessAction |
  UpdateFailureAction |
  UpdateRequestedAction |
  GetRequestedAction |
  GetSuccessAction |
  GetFailureAction |
  GetListRequestedAction |
  GetListSuccessAction |
  GetListFailureAction |
  ISetSelectedAppointmentDataAction |
  IAppointmentUpsertClearErrorAction |
  IAppointmentUpsertClearStateAction |
  IAppointmentUpsertInitialDataAction |
  ISetCalendarManagerSelectionDataAction |
  IAppointmentUpsertClearSelectedAppointmentAction;
