import React from "react";
import { GridLoader } from "react-spinners";
import styles from "../Styles/appointment-upsert.module.css";

interface Props {
  show: boolean;
}

export class AppointmentLoader extends React.PureComponent<Props> {
  render() {
    if (!this.props.show) return null;
    return (
      <div className={styles.appointmentContainerLoader}><GridLoader color="var(--primary-font-color)" size="30px"/></div>
    );
  }
}