export enum EmployeeListActionTypesEnum {
  CLEAR_ERROR = "EmployeeList/CLEAR_ERROR",
  CLEAR_STATE = "EmployeeList/CLEAR_STATE",

  GET_FAILURE = "EmployeeList/GET_FAILURE",
  GET_SUCCESS = "EmployeeList/GET_SUCCESS",
  GET_REQUESTED = "EmployeeList/GET_REQUESTED",

  GET_LIST_FAILURE = "EmployeeList/GET_LIST_FAILURE",
  GET_LIST_SUCCESS = "EmployeeList/GET_LIST_SUCCESS",
  GET_LIST_REQUESTED = "EmployeeList/GET_LIST_REQUESTED",
}