import React from "react";
import * as dateFns from 'date-fns';
import WeekItem from "./WeekItem";
import { ResourceItem } from "../Upsert/ResourceItem";
import styles from '../../Styles/work.schedule.module.css';
import { IEmployeeModel } from "../../../EmployeeList/Types";

interface Props {
  endDate: Date;
  startDate: Date;
  employee: IEmployeeModel;

  onItemClick(date: Date, employee: IEmployeeModel): void;
}

export class WeekRow extends React.PureComponent<Props> {
  render() {
    const {endDate, startDate, employee, onItemClick} = this.props;
    const daysBetween = dateFns.eachDayOfInterval({start: startDate, end: endDate});
    return (
      <div className={styles.weekRow}>
        <ResourceItem employee={employee}/>
        {daysBetween.map((d: Date, i: number) => <WeekItem key={i} date={d} employee={employee} onItemClick={onItemClick}/>)}
      </div>
    );
  }

}