import React from "react";
import { FormattedMessage } from "react-intl";
import { EventContentArg } from "@fullcalendar/react";
import styles from '../../Styles/event.module.css';

interface Props {
  eventInfo: EventContentArg;
}

export class AllDay extends React.PureComponent<Props> {
  render() {
    const {borderColor, backgroundColor} = this.props.eventInfo;
    const style = {backgroundColor: backgroundColor, borderLeftColor: borderColor};

    return (
      <div className={styles.eventContainer} style={style}>
        <div className={styles.infoWrapper}>
          <div className={styles.timeText}>00:00 - 23:59</div>
          <div className={styles.allDayTimeText}><FormattedMessage id="appointment.all.day.title"/></div>
          <div className={styles.titleText}><FormattedMessage id="appointment.pause.title"/></div>
        </div>
      </div>
    );
  }
}