import React from "react";
import Icon from '@mdi/react'
import { mdiTextBoxRemove } from '@mdi/js'
import { MdOutlineGrading } from "react-icons/all";
import styles from '../../Styles/event.module.css';
import { AppointmentStatusEnum } from "../../../../PSolutions.Enums/AppointmentStatusEnum";

interface Props {
  status: number;
}

export class EventIndicator extends React.PureComponent<Props> {
  render() {
    return (
      <div className={styles.indicatorHolder}>
        {{
          [AppointmentStatusEnum.Done]: <MdOutlineGrading color="gray" size="1.3em"/>,
          [AppointmentStatusEnum.FullyPaid]: <MdOutlineGrading color="gray" size="1.3em"/>,
          [AppointmentStatusEnum.NoShow]: <Icon color="var(--danger-primary-color)" size="1.3em" path={mdiTextBoxRemove}/>,
        }[this.props.status]}
      </div>
    );
  }
}