import axios from "axios";
import { Dispatch } from "redux";
import { EventApi, } from "@fullcalendar/react";
import { DateSelectArg } from "@fullcalendar/common";
import { IApiError } from "../../../PSolutions.Types/Error";
import { AppointmentListActionTypesEnum } from "./ActionTypes";
import { IAppointmentListState, IAppointmentQuery } from "../Types";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";
import { CalendarUtilities } from "../../../PSolutions.Utility/CalendarUtilities";

const path = `${AppSettings.apiUrl}/Appointments`;

export interface IGetResourcesSuccessAction {
  payload: IAppointmentListState,
  type: AppointmentListActionTypesEnum.GET_RESOURCES_SUCCESS,
}

export interface IGetResourcesFailureAction {
  payload: IApiError;
  type: AppointmentListActionTypesEnum.GET_RESOURCES_FAILURE,
}

export interface IGetResourcesRequestedAction {
  type: AppointmentListActionTypesEnum.GET_RESOURCES_REQUESTED
}

export interface ISetSelectedEventAction {
  payload: EventApi,
  type: AppointmentListActionTypesEnum.SET_SELECTED_EVENT,
}

export interface ISetSelectedSlotAction {
  payload: DateSelectArg,
  type: AppointmentListActionTypesEnum.SET_SELECTED_SLOT_ARG,
}

export const getResourcesAsync = (query: IAppointmentQuery) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: AppointmentListActionTypesEnum.GET_RESOURCES_REQUESTED});
      const res = await axios.post<IAppointmentListState>(`${path}/GetWebAppointments`, query);

      CalendarUtilities.updateHeightOfGridCell(res.data.inlineCalendarMinuteInterval);
      dispatch({type: AppointmentListActionTypesEnum.GET_RESOURCES_SUCCESS, payload: res.data})
      return true;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: AppointmentListActionTypesEnum.GET_RESOURCES_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const clearError = () => (dispatch: Dispatch) => dispatch({type: AppointmentListActionTypesEnum.CLEAR_ERROR});

export const setSelectedEvent = (event: EventApi) => (dispatch: Dispatch) => dispatch({type: AppointmentListActionTypesEnum.SET_SELECTED_EVENT, payload: event})
export const setSelectedSlot = (slotArg: DateSelectArg) => (dispatch: Dispatch) => dispatch({type: AppointmentListActionTypesEnum.SET_SELECTED_SLOT_ARG, payload: slotArg});

export type AppointmentListActions =
  ISetSelectedEventAction |
  ISetSelectedSlotAction |
  IGetResourcesSuccessAction |
  IGetResourcesFailureAction |
  IGetResourcesRequestedAction;
