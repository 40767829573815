import { Reducer } from "redux";
import { AppointmentUpsertActions } from "./Actions";
import { AppointmentUpsertActionTypesEnum } from "./Types";
import { TODAY } from "../../../PSolutions.Config/TekicaTimeConstants";
import { ICalendarManagerData } from "../../../PSolutions.Types/Common";
import { IAppointmentUpsertModel, IAppointmentUpsertState } from "../Types";
import { AppointmentStatusEnum } from "../../../PSolutions.Enums/AppointmentStatusEnum";

const initialState: IAppointmentUpsertState = {
  isBusy: false,
  isAdding: false,
  isUpdating: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  addErrorMessage: "",
  showDeleted: false,
  showAddError: false,
  showSuccess: false,
  showUpdateError: false,
  updateErrorMessage: "",
  calendarData: {} as ICalendarManagerData,
  selectedAppointment: {} as IAppointmentUpsertModel
}

const emptyAppointment = {} as IAppointmentUpsertModel;

const AppointmentUpsertReducer: Reducer<IAppointmentUpsertState, AppointmentUpsertActions> = (state: IAppointmentUpsertState = initialState, action: AppointmentUpsertActions) => {
  switch (action.type) {
    // Get by id
    case AppointmentUpsertActionTypesEnum.GET_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case AppointmentUpsertActionTypesEnum.GET_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case AppointmentUpsertActionTypesEnum.GET_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: "", selectedAppointment: action.payload};

    // Get list
    case AppointmentUpsertActionTypesEnum.GET_LIST_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case AppointmentUpsertActionTypesEnum.GET_LIST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case AppointmentUpsertActionTypesEnum.GET_LIST_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: ""};

    // Add
    case AppointmentUpsertActionTypesEnum.ADD_SUCCESS:
      return {...state, showAddError: false, isAdding: false}
    case AppointmentUpsertActionTypesEnum.ADD_REQUESTED:
      return {...state, showAddError: false, isAdding: true}
    case AppointmentUpsertActionTypesEnum.ADD_FAILURE:
      return {...state, showAddError: true, addErrorMessage: action.payload, isAdding: false};

    // Add
    case AppointmentUpsertActionTypesEnum.UPDATE_SUCCESS:
      return {...state, showUpdateError: false, isUpdating: false}
    case AppointmentUpsertActionTypesEnum.UPDATE_REQUESTED:
      return {...state, showUpdateError: false, isUpdating: true}
    case AppointmentUpsertActionTypesEnum.UPDATE_FAILURE:
      return {...state, showUpdateError: true, updateErrorMessage: action.payload, isUpdating: false};

    // Clear
    case AppointmentUpsertActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case AppointmentUpsertActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false, showAddError: false, addErrorMessage: "", updateErrorMessage: "", showUpdateError: false};

    case AppointmentUpsertActionTypesEnum.CLEAR_SELECTED_APPOINTMENT:
      return {...state, selectedAppointment: {} as IAppointmentUpsertModel};

    // Change handlers
    case AppointmentUpsertActionTypesEnum.SET_CALENDAR_MANAGER_SELECTION:
      return {...state, calendarData: action.payload}
    case AppointmentUpsertActionTypesEnum.SET_SELECTED_APPOINTMENT_DATA:
      return {...state, selectedAppointment: {...state.selectedAppointment, ...action.payload}};
    case AppointmentUpsertActionTypesEnum.SET_APPOINTMENT_INITIAL_DATA:
      return {...state, selectedAppointment: {...emptyAppointment, date: action.payload?.tekicaDate || TODAY(), startTime: action.payload.startTime, status: AppointmentStatusEnum.Confirmed}}

    default:
      return state;
  }
}

export default AppointmentUpsertReducer;