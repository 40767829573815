import _ from "lodash";
import React, { FormEvent } from 'react'
import { connect } from "react-redux";
import { IntlContext } from 'react-intl';
import { bindActionCreators, Dispatch } from "redux";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from '../Styles/service-selector.module.css';
import { IServiceModel } from "../../AppointmentUpsert/Types";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { ServiceSelectorItem } from "../Components/ServiceSelectorItem";
import { ICalendarManagerData } from "../../../PSolutions.Types/Common";
import { clearError, clearState, getServiceListAsync } from '../Redux/Actions';
import { TekicaPopup } from "../../../PSolutions.Prebuilt/Popups/Base/TekicaPopup";
import { OutlineButton } from "../../../PSolutions.Components/Buttons/OutlineButton";
import { ScrollHolder } from "../../../PSolutions.Prebuilt/Popups/Scroll/ScrollHolder";
import { RegularInput } from "../../../PSolutions.Components/Inputs/Components/RegularInput";
import { PopupFooter } from "../../../PSolutions.Prebuilt/Popups/Base/Components/PopupFooter";

interface Props {
  show: boolean;
  isBusy: boolean;
  services: Array<IServiceModel>
  calendarData: ICalendarManagerData;

  closeModal(): void;

  clearError(): void;

  clearState(): void;

  onServiceClick(service: IServiceModel): void;

  getServiceListAsync(serviceName: string, employeeId: string): any;
}

interface State {
  searchText: string;
}


class ServiceSelectorContainer extends React.PureComponent<Props, State> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.state = {searchText: ""}
    this.onCloseModal = this.onCloseModal.bind(this);
    this.getDataAsync = this.getDataAsync.bind(this);
    this.handleServiceClick = this.handleServiceClick.bind(this);
    this.handleTextChange = _.debounce(this.handleTextChange, 500) as any;
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.props.show) return;
    if (this.props.show === prevProps.show) return;
    await this.props.getServiceListAsync(this.state.searchText, this.props.calendarData.employeeId);
  }

  async handleTextChange(searchText: string) {
    this.props.clearState();
    await this.props.getServiceListAsync(searchText, this.props.calendarData.employeeId);
  }

  async getDataAsync() {
    const {searchText} = this.state;
    await this.props.getServiceListAsync(searchText, this.props.calendarData.employeeId);
  }

  onCloseModal() {
    this.props.clearState();
    this.props.closeModal();
  }

  handleServiceClick(service: IServiceModel) {
    this.props.clearState();
    this.props.onServiceClick(service);
  }

  getLoader() {
    return <div><h4>Loading</h4></div>
  }

  render() {
    const {searchText} = this.state;
    const {show, services, calendarData} = this.props;
    const search = this.context.formatMessage({id: "generic.messages.search"});
    const cancelText = this.context.formatMessage({id: "generic.messages.cancel"});
    const bottomText = this.context.formatMessage({id: "generic.messages.services.total"});
    const servicesTitle = this.context.formatMessage({id: "generic.messages.services.shown.for"});
    const placeholder = this.context.formatMessage({id: "generic.messages.search.services.placeholder"});
    return (
      <TekicaPopup
        show={show}
        preventRequestClose
        textBottom={bottomText}
        subtitle={servicesTitle}
        title={calendarData.fullName}
        closeModal={this.onCloseModal}
        profilePhoto={calendarData.profilePhoto}
        textBottomValue={services.length.toString()}>
        <ScrollHolder>
          <RegularInput
            type="text"
            label={search}
            name="search-text"
            id="search-client"
            value={searchText}
            placeholder={placeholder}
            handleTextChange={(event: FormEvent<HTMLInputElement>) => this.handleTextChange(event.currentTarget.value)}/>
        </ScrollHolder>
        <ScrollHolder>
          <InfiniteScroll height={350} next={this.getDataAsync} hasMore={false} loader={this.getLoader()} dataLength={services.length}>
            {this.props.services.map((s: IServiceModel) => <ServiceSelectorItem key={s.id} service={s} onServiceClick={this.handleServiceClick}/>)}
          </InfiniteScroll>
        </ScrollHolder>
        <PopupFooter styles={styles.footer}>
          <OutlineButton text={cancelText} isBusy={false} onClick={this.onCloseModal}/>
        </PopupFooter>
      </TekicaPopup>
    )
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isBusy: state.serviceSelector.isBusy,
    services: state.serviceSelector.services,
    showError: state.serviceSelector.showError,
    errorMessage: state.serviceSelector.errorMessage,
    calendarData: state.appointmentUpsert.calendarData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({clearError, clearState, getServiceListAsync}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelectorContainer)