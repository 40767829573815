import React from "react";
import Switch from "react-switch";
import { FormattedMessage } from "react-intl";
import styles from '../../Styles/work.schedule.module.css'

interface Props {
  show: boolean;
  checked: boolean;

  toggleIsWorking(): void;
}

export class WorkingIndicator extends React.PureComponent<Props> {
  render() {
    if(!this.props.show) return null;

    return (
      <div className={styles.workingIndicator}>
        <div className={styles.workingIndicatorLabel}><FormattedMessage id="generic.messages.set.as.non.working"/></div>
        <Switch checkedIcon={false} uncheckedIcon={false} checked={this.props.checked} onChange={this.props.toggleIsWorking}/>
      </div>
    );
  }
}