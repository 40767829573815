import React from "react";
import classNames from "classnames";
import SidebarFooter from "./SidebarFooter";
import { MenuItem } from "../../Nav/MenuItem";
import { SidebarHeader } from "./SidebarHeader";
import styles from '../Styles/sidebar.module.css';
import { IMenuItem } from "../../../PSolutions.Types/Menu";

interface Props {
  isOpen: boolean;

  toggleSidebar(): void;
}

const items: Array<IMenuItem> = [
  {
    exact: true,
    to: "/calendar",
    icon: "GoCalendar",
    title: "generic.messages.calendar",
  },
  // {
  //   exact: false,
  //   to: "/clients",
  //   icon: "GoOrganization",
  //   title: "generic.messages.clients",
  // },
  {
    exact: true,
    to: "/workSchedule",
    icon: "GoGear",
    title: "generic.messages.schedule",
  }
];

export class SideBar extends React.PureComponent<Props> {
  render() {
    const {isOpen} = this.props;
    return (
      <div className={classNames(styles.sidebar, isOpen && styles.isOpen)}>
        <div className={styles.sideMenu}>
          <SidebarHeader/>
          <nav className={styles.content}>
            {items.map((i: IMenuItem, index: number) => <MenuItem key={index} isSideBarOpen={isOpen} menu={i}/>)}
          </nav>
          <SidebarFooter isSidebarOpen={isOpen}/>
        </div>
      </div>
    );
  }
}

