import { Reducer } from "redux";
import { IApplicationState } from "../Types";
import { AppActions, AppActionTypesEnum } from "./Actions";

// Initial state
const initialState: IApplicationState = {
  email: null,
  fullName: null,
  lastName: null,
  firstName: null,
  profilePhoto: "",
  accessToken: null,
  currentLanguage: "en",
  isAuthenticated: false,
};

// App Reducer
const appReducer: Reducer<IApplicationState, AppActions> = (state: IApplicationState = initialState, action: AppActions) => {
  switch (action.type) {
    case AppActionTypesEnum.APP_LOG_OUT_SUCCESS:
      return {...state, ...initialState, currentLanguage: state.currentLanguage}
    case AppActionTypesEnum.APP_LOG_IN_SUCCESS:
      return {...state, ...action.payload, isAuthenticated: true};
    default:
      return state
  }
}

export default appReducer;