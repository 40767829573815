export const AppointmentStatusEnum = Object.freeze({
  Confirmed: 1,
  Declined: 2,
  Canceled: 3,
  NeedApproval: 4,
  NoShow: 5,
  Done: 6,
  ClientCanceled: 7,
  FullyPaid: 10,
  TimeReservation: 99,
});