import React from "react";
import { IntlContext } from "react-intl";
import { IWorkScheduleModel } from "../../Types";
import { IShiftModel } from "../../../Shifts/Types";
import styles from "../../Styles/work.schedule.module.css";
import { ISelectOption } from "../../../../PSolutions.Types/Common";
import { TekicaSelect } from "../../../../PSolutions.Components/Controls/TekicaSelect";
import { GetTekicaTimeRangeOptions, TekicaTimeRangeOptions } from "../../../../PSolutions.Common/Data/TimeOptions";

interface Props {
  hide?: boolean;
  index: number;
  isFirst?: boolean;
  disabled?: boolean;
  fixedTimeText?: string;
  customTimeText?: string;
  data: IWorkScheduleModel;
  shifts: Array<IShiftModel>;

  handleScheduleChange?(data: IWorkScheduleModel, index: number): void;
}

interface State {
  selectedShift: ISelectOption;
}

export class UpsertScheduleRow extends React.Component<Props, State> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private readonly timeOptions: Array<ISelectOption>;

  constructor(props: Props) {
    super(props);
    this.timeOptions = GetTekicaTimeRangeOptions();
    this.handleEndChange = this.handleEndChange.bind(this);
    this.getShiftsOptions = this.getShiftsOptions.bind(this);
    this.getSelectedShift = this.getSelectedShift.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleShiftChange = this.handleShiftChange.bind(this);
    this.getFixedShiftOptions = this.getFixedShiftOptions.bind(this);
    this.getCustomShiftOption = this.getCustomShiftOption.bind(this);
    this.state = {selectedShift: this.getSelectedShift()};
  }

  handleShiftChange(option: ISelectOption) {
    const {data, handleScheduleChange, shifts, index} = this.props;

    const selectedShift = shifts.find((s: IShiftModel) => s.id === option.value);
    if (!selectedShift || !option.value) return this.setState({selectedShift: option});

    data.shiftId = selectedShift.id;
    data.customEndTime = selectedShift.endTime;
    data.customStartTime = selectedShift.startTime;
    if (!!handleScheduleChange) handleScheduleChange(data, index);

    this.setState({selectedShift: option});
  }

  getShiftsOptions(): Array<ISelectOption> {
    if (!!this.props.disabled) return this.getFixedShiftOptions();
    const mapping = this.props.shifts.map((s: IShiftModel) => ({value: s.id, label: s.name}))

    return [...mapping, this.getCustomShiftOption()];
  }

  handleEndChange(option: ISelectOption) {
    const {data, handleScheduleChange, index} = this.props;
    if (option.value <= data.customStartTime) return;

    data.customEndTime = option.value;
    if (!!handleScheduleChange) handleScheduleChange(data, index);
  }

  handleStartChange(option: ISelectOption) {
    const {data, handleScheduleChange, index} = this.props;
    if (option.value >= data.customEndTime) return;

    data.customStartTime = option.value;
    if (!!handleScheduleChange) handleScheduleChange(data, index);
  }

  render() {
    if (!!this.props.hide) return null;
    const {selectedShift} = this.state;
    const shiftOptions = this.getShiftsOptions();
    const {isFirst, data, disabled} = this.props;
    const shiftEnds = this.context.formatMessage({id: "office.shift.shifts.ends"})
    const shiftStarts = this.context.formatMessage({id: "office.shift.shifts.starts"});
    const predefined = this.context.formatMessage({id: "office.shift.predefined.shift"});

    const selectedEnd = TekicaTimeRangeOptions.find((s: ISelectOption) => s.value === data.customEndTime);
    const selectedStart = TekicaTimeRangeOptions.find((s: ISelectOption) => s.value === data.customStartTime);

    return (
      <div className={styles.upsertContainerRow}>
        <TekicaSelect
          disabled={disabled}
          value={selectedShift}
          options={shiftOptions}
          handleChange={this.handleShiftChange}
          label={isFirst ? predefined : undefined}
          containerClass={styles.upsertContainerItem}
        />
        <TekicaSelect
          value={selectedStart}
          options={this.timeOptions}
          handleChange={this.handleStartChange}
          label={isFirst ? shiftStarts : undefined}
          defaultValue={TekicaTimeRangeOptions[32]}
          containerClass={styles.upsertContainerItem}
          disabled={disabled || !!selectedShift.value}
        />
        <TekicaSelect
          value={selectedEnd}
          options={this.timeOptions}
          handleChange={this.handleEndChange}
          label={isFirst ? shiftEnds : undefined}
          defaultValue={TekicaTimeRangeOptions[64]}
          containerClass={styles.upsertContainerItem}
          disabled={disabled || !!selectedShift.value}
        />
      </div>
    );
  }

  getSelectedShift(): ISelectOption {
    const {data, disabled} = this.props;
    if (disabled) return this.getFixedShiftOptions()[0];
    if (!data.shiftId) return this.getCustomShiftOption();

    const shift = this.props.shifts.find((s: IShiftModel) => s.id === data.shiftId);
    if (!shift) return this.getCustomShiftOption();

    return {value: shift.id, label: shift.name};
  }

  getCustomShiftOption(): ISelectOption {
    return {value: "", label: this.props?.customTimeText || ""}
  }

  getFixedShiftOptions(): Array<ISelectOption> {
    return [{value: "", label: this.props?.fixedTimeText || ""}];
  }
}
