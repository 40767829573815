import React from "react";
import * as dateFns from 'date-fns'
import { ToolbarButton } from "./ToolbarButton";
import styles from '../../Styles/header.toolbar.module.css';
import { TEKICA_CALENDAR_FORMAT } from "../../../../PSolutions.Config/TekicaTimeFormat";
import { TekicaCalendar } from "../../../../PSolutions.Components/Calendar/TekicaCalendar";
import { TranslationContext } from "../../../../PSolutions.Providers/Translation/TranslationContext";

interface Props {
  endDate?: Date;
  currentDate: Date;

  onPrevClick(): void;

  onNextClick(): void;

  onDateClick(date: Date): void;
}

interface State {
  showPicker: boolean;
}

export class CalendarToolBar extends React.PureComponent<Props, State> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;

  constructor(props: Props) {
    super(props);
    this.state = {showPicker: false}
    this.getEndDate = this.getEndDate.bind(this);
    this._onDatePicked = this._onDatePicked.bind(this);
    this.toggleDatePicker = this.toggleDatePicker.bind(this);
  }

  _onDatePicked(date: Date) {
    this.props.onDateClick(date);
    this.toggleDatePicker();
  }

  toggleDatePicker() {
    this.setState({showPicker: !this.state.showPicker});
  }

  getEndDate() {
    const {endDate} = this.props;
    if (!endDate) return undefined;
    const locale = {locale: this.context.currentLocale};
    return ` - ${dateFns.format(endDate, TEKICA_CALENDAR_FORMAT, locale)}`;
  }

  render() {
    const {currentDate} = this.props;
    const endDate = this.getEndDate();
    const locale = {locale: this.context.currentLocale};
    const formattedStart = dateFns.format(currentDate, TEKICA_CALENDAR_FORMAT, locale);
    return (
      <div className={styles.holder}>
        <ToolbarButton onClick={this.props.onPrevClick}><i className="fa fa-2x fa-angle-left"/></ToolbarButton>
        <ToolbarButton onClick={this.toggleDatePicker}><span className={styles.selectedDateText}>{formattedStart}{endDate}</span></ToolbarButton>
        <ToolbarButton onClick={this.props.onNextClick}><i className="fa fa-2x fa-angle-right"/></ToolbarButton>
        {this.state.showPicker && <div className={styles.calendarHolder}><TekicaCalendar date={currentDate} onDateSelected={this._onDatePicked}/></div>}
      </div>
    );
  }
}