export enum ServiceSelectorActionTypesEnum {
  CLEAR_ERROR = "ServiceSelector/CLEAR_ERROR",
  CLEAR_STATE = "ServiceSelector/CLEAR_STATE",

  GET_FAILURE = "ServiceSelector/GET_FAILURE",
  GET_SUCCESS = "ServiceSelector/GET_SUCCESS",
  GET_REQUESTED = "ServiceSelector/GET_REQUESTED",

  GET_LIST_FAILURE = "ServiceSelector/GET_LIST_FAILURE",
  GET_LIST_SUCCESS = "ServiceSelector/GET_LIST_SUCCESS",
  GET_LIST_REQUESTED = "ServiceSelector/GET_LIST_REQUESTED",
}