import { Reducer } from "redux";
import { IServiceSelectorState } from "../Types";
import { ServiceSelectorActions } from "./Actions";
import { ServiceSelectorActionTypesEnum } from "./Types";

const initialState: IServiceSelectorState = {
  isBusy: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  showDeleted: false,
  showSuccess: false,
  services: [],
}


const ServiceSelectorReducer: Reducer<IServiceSelectorState, ServiceSelectorActions> = (state: IServiceSelectorState = initialState, action: ServiceSelectorActions) => {
  switch (action.type) {
    case ServiceSelectorActionTypesEnum.GET_LIST_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case ServiceSelectorActionTypesEnum.GET_LIST_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case ServiceSelectorActionTypesEnum.GET_LIST_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: "", services: action.payload};

    case ServiceSelectorActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case ServiceSelectorActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default ServiceSelectorReducer;