import _ from 'lodash';
import React from 'react'
import { Locale } from "date-fns";
import { connect } from "react-redux";
import { IntlContext } from 'react-intl';
import { bindActionCreators, Dispatch } from "redux";
import { DateSelectArg } from "@fullcalendar/common";
import { ClientInfo } from "../Components/Client/ClientInfo";
import { ServicesInfo } from "../Components/ServicesInfo";
import { CancellationPopup } from "../Popups/CancellationPopup";
import { AppointmentNotes } from "../Components/AppointmentNotes";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { AppointmentHolder } from "../Components/AppointmentHolder";
import { AppointmentHeader } from "../Components/AppointmentHeader";
import { AppointmentFooter } from "../Components/AppointmentFooter";
import { AppointmentActions } from "../Components/AppointmentActions";
import { ICalendarManagerData } from "../../../PSolutions.Types/Common";
import { ReadonlyServicesInfo } from "../Components/ReadonlyServicesInfo";
import { ErrorDisplay } from "../../../PSolutions.Components/UI/ErrorDisplay";
import { IAppointmentUpsertModel, ICancelAppointmentRequest } from "../Types";
import { BaseModal } from "../../../PSolutions.Components/Modals/Base/BaseModal";
import { clearError, clearSelectedAppointment, clearState, getAppointmentAsync, scheduleAppointmentAsync, setSelectAppointmentInformation, updateAppointmentStatusAsync } from '../Redux/Actions';
import { ClientNowShopPopup } from "../Popups/ClientNoShowPopup";

interface Props {
  show: boolean;
  isBusy: boolean;
  addMode: boolean;
  isAdding: boolean;
  isUpdating: boolean;
  showUpdateError: boolean;
  updateErrorMessage: string;
  showAddError: boolean;
  appointmentId: string;
  currentLocale: Locale;
  addErrorMessage: string;
  selectedSlot: DateSelectArg,
  calendarData: ICalendarManagerData,
  selectedAppointment: IAppointmentUpsertModel;

  closeModal(): void;

  clearError(): void;

  clearState(): void;

  fetchAppointmentsAsync(): void;

  toggleSelectServicePopup(): void;

  toggleSelectClientPopup(): void;

  clearSelectedAppointment(): void;

  getAppointmentAsync(id: string): any;

  updateAppointmentStatusAsync(data: ICancelAppointmentRequest): any;

  scheduleAppointmentAsync(appointment: IAppointmentUpsertModel): any;

  setSelectAppointmentInformation(appointment: IAppointmentUpsertModel): void;
}

interface State {
  sendSms: boolean;
  showCancelPopup: boolean;
  showNoShowPopup: boolean;
  sendCancellationNotification: boolean;
}

class AppointmentUpsertContainer extends React.PureComponent<Props, State> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.onScheduleAsync = this.onScheduleAsync.bind(this);
    this.handleNoShowAsync = this.handleNoShowAsync.bind(this);
    this.toggleNoShowPopup = this.toggleNoShowPopup.bind(this);
    this.handleCancelAsync = this.handleCancelAsync.bind(this);
    this.toggleCancelPopup = this.toggleCancelPopup.bind(this);
    this.handleSuccessAsync = this.handleSuccessAsync.bind(this);
    this.handleWalkInToggle = this.handleWalkInToggle.bind(this);
    this.handleServiceSelect = this.handleServiceSelect.bind(this);
    this.onClientSelectClick = this.onClientSelectClick.bind(this);
    this.handleInternalChange = _.debounce(this.handleInternalChange.bind(this), 100);
    this.handleWalkInTextChange = _.debounce(this.handleWalkInTextChange.bind(this), 100);
    this.state = {showCancelPopup: false, sendSms: false, sendCancellationNotification: true, showNoShowPopup: false};
  }

  onClientSelectClick() {
    this.props.closeModal();
    this.props.toggleSelectClientPopup();
  }

  handleServiceSelect() {
    this.props.closeModal();
    this.props.toggleSelectServicePopup();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.props.show) return;
    if (this.props.isBusy) return;
    if (this.props.addMode) return;
    if (prevProps.show === this.props.show) return;
    await this.props.getAppointmentAsync(this.props.appointmentId);
  }

  async handleSuccessAsync() {
    this.props.closeModal();
    await this.props.fetchAppointmentsAsync();
  }

  async onScheduleAsync() {
    const res = await this.props.scheduleAppointmentAsync(this.props.selectedAppointment);
    if (res === true) await this.handleSuccessAsync();
  }

  async handleCancelAsync(request: ICancelAppointmentRequest) {
    const res = await this.props.updateAppointmentStatusAsync(request);
    if (!res) return;

    this.toggleCancelPopup();
    this.props.closeModal();
    await this.props.fetchAppointmentsAsync();
  }

  async handleNoShowAsync(request: ICancelAppointmentRequest) {
    const res = await this.props.updateAppointmentStatusAsync(request);
    if (!res) return;

    this.toggleNoShowPopup();
    this.props.closeModal();
    await this.props.fetchAppointmentsAsync();
  }

  handleInternalChange(internalNote: string) {
    const {selectedAppointment} = this.props;
    selectedAppointment.internalNote = internalNote;
    this.props.setSelectAppointmentInformation(selectedAppointment);
  }

  handleNoteChange(note: string) {
    const {selectedAppointment} = this.props;
    selectedAppointment.note = note;
    this.props.setSelectAppointmentInformation(selectedAppointment);
  }

  handleWalkInToggle(checked: boolean) {
    const {selectedAppointment} = this.props;
    selectedAppointment.walkInClient = checked;
    if (!checked) selectedAppointment.client = undefined;
    if (!checked) selectedAppointment.clientId = undefined;
    if (!checked) selectedAppointment.walkInClientName = "";
    this.props.setSelectAppointmentInformation(selectedAppointment);
  }

  handleWalkInTextChange(text: string) {
    const {selectedAppointment} = this.props;
    selectedAppointment.walkInClientName = text;
    this.props.setSelectAppointmentInformation(selectedAppointment);
  }

  render() {
    const {showCancelPopup, showNoShowPopup} = this.state;
    const {selectedAppointment, show, showAddError, addMode, currentLocale, showUpdateError} = this.props;
    const visible = show && !showAddError && !showCancelPopup && !showUpdateError && !showNoShowPopup;
    return (
      <React.Fragment>
        <BaseModal closeModal={this.props.closeModal} topPadding={65} visible={visible} preventRequestClose={addMode}>
          <AppointmentHolder isBusy={this.props.isBusy}>
            <AppointmentHeader
              upsertMode={addMode}
              currentLocale={currentLocale}
              selectedAppointment={selectedAppointment}
            />
            <AppointmentActions
              disabled={addMode}
              onNoShowClick={this.toggleNoShowPopup}
              onCancelClick={this.toggleCancelPopup}
            />
            <ClientInfo
              upsertMode={addMode}
              toggleWalkIn={this.handleWalkInToggle}
              selectedAppointment={selectedAppointment}
              handleClientSelect={this.onClientSelectClick}
              handleWalkInChange={this.handleWalkInTextChange}
            />
            <ReadonlyServicesInfo
              show={!addMode}
              selectedAppointment={selectedAppointment}
            />
            <ServicesInfo
              show={addMode}
              selectedSlot={this.props.calendarData}
              selectedAppointment={selectedAppointment}
              onServiceSelect={this.handleServiceSelect}
            />
            <AppointmentNotes
              upsertMode={addMode}
              handleNoteChange={this.handleNoteChange}
              selectedAppointment={selectedAppointment}
              handleInternalNoteChange={this.handleInternalChange}
            />
            <AppointmentFooter
              show={addMode}
              isBusy={this.props.isAdding}
              onCancelClick={this.props.closeModal}
              onReserveClick={this.onScheduleAsync}
            />
          </AppointmentHolder>
        </BaseModal>
        <ErrorDisplay error={this.props.addErrorMessage} visible={this.props.showAddError} closeModal={this.props.clearError}/>
        <ErrorDisplay error={this.props.updateErrorMessage} visible={this.props.showUpdateError} closeModal={this.props.clearError}/>
        <CancellationPopup
          visible={showCancelPopup}
          isBusy={this.props.isUpdating}
          closeModal={this.toggleCancelPopup}
          onBackClick={this.toggleCancelPopup}
          onCancelClick={this.handleCancelAsync}
          selectedAppointment={selectedAppointment}
        />
        <ClientNowShopPopup
          visible={showNoShowPopup}
          isBusy={this.props.isUpdating}
          closeModal={this.toggleNoShowPopup}
          onBackClick={this.toggleNoShowPopup}
          onNoShowClick={this.handleNoShowAsync}
          selectedAppointment={selectedAppointment}
        />
      </React.Fragment>
    )
  }

  toggleCancelPopup() {
    this.setState({showCancelPopup: !this.state.showCancelPopup});
  }

  toggleNoShowPopup() {
    this.setState({showNoShowPopup: !this.state.showNoShowPopup});
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isBusy: state.appointmentUpsert.isBusy,
    isAdding: state.appointmentUpsert.isAdding,
    isUpdating: state.appointmentUpsert.isUpdating,
    calendarData: state.appointmentUpsert.calendarData,
    showAddError: state.appointmentUpsert.showAddError,
    selectedSlot: state.appointmentList.selectedSlotArg,
    showUpdateError: state.appointmentUpsert.showUpdateError,
    addErrorMessage: state.appointmentUpsert.addErrorMessage,
    appointmentId: state.appointmentList.selectedEvent.groupId,
    updateErrorMessage: state.appointmentUpsert.updateErrorMessage,
    selectedAppointment: state.appointmentUpsert.selectedAppointment,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    clearError,
    clearState,
    getAppointmentAsync,
    clearSelectedAppointment,
    updateAppointmentStatusAsync,
    setSelectAppointmentInformation,
    scheduleAppointmentAsync
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentUpsertContainer)