import React from "react";
import styles from '../../Styles/header.toolbar.module.css';

interface Props {
  children: any;

  onClick?(): void;
}

export class ToolbarButton extends React.PureComponent<Props> {
  render() {
    return (
      <button onClick={this.props.onClick} type="button" className={styles.toolbarButton}>{this.props.children}</button>
    );
  }
}