import React from "react";
import * as dateFns from 'date-fns';
import styles from './date-item.module.css';
import { AppointmentUtility } from "../../PSolutions.Utility/AppointmentUtility";
import { TEKICA_DATE_FORMAT, TEKICA_SHORT_MONTH_NAME } from "../../PSolutions.Config/TekicaTimeFormat";

interface Props {
  date: string;
  status: number;
  startTime: string;
}

export class DateItem extends React.PureComponent<Props> {

  getBorderColor(status: number) {
    const color = AppointmentUtility.GetColorForStatus(status);
    return {borderLeftColor: color};
  }

  getMonth(date: string) {
    const parsed = dateFns.parse(date, TEKICA_DATE_FORMAT, new Date());
    return dateFns.format(parsed, TEKICA_SHORT_MONTH_NAME);
  }

  getDate(date: string) {
    const parsed = dateFns.parse(date, TEKICA_DATE_FORMAT, new Date());
    return dateFns.format(parsed, "d");
  }

  render() {
    const appliedStyle = this.getBorderColor(this.props.status);
    return (
      <div className={styles.container}>
        <div className={styles.containerWrapper} style={appliedStyle}>
          <span className={styles.title}>{this.getMonth(this.props.date)}</span>
          <span className={styles.day}>{this.getDate(this.props.date)}</span>
          <span className={styles.time}>{this.props.startTime}</span>
        </div>
      </div>
    );
  }
}