import React from "react";
import { ScaleLoader } from "react-spinners";
import styles from './loaders.module.css';
import { BaseModal } from "../Modals/Base/BaseModal";

interface Props {
  visible: boolean;
}


export class BusyLoader extends React.PureComponent<Props> {
  closeModal() {

  }

  render() {
    const {visible} = this.props;
    return (
      <BaseModal visible={visible} closeModal={this.closeModal} topPosition={45}>
        <div className={styles.container}><ScaleLoader css={styles.loader}/></div>
      </BaseModal>
    );
  }
}