import React from "react";
import classNames from "classnames";
import styles from '../Styles/appointment-upsert.module.css';

interface Props {
  icon: any;
  style?: any;
  title: string;

  onPress(): void;
}

export class ActionItem extends React.PureComponent<Props> {
  render() {
    return (
      <div className={styles.appointmentActionItemContainer} onClick={this.props.onPress}>
        <div className={classNames(styles.appointmentActionItem, this.props.style)}>{this.props.icon}
          <div className={styles.appointmentActionItemTitle}>{this.props.title}</div>
        </div>
      </div>
    );
  }
}