import React from "react";
import { BsArrowRight, BsShieldFillCheck } from "react-icons/all";
import { IClient } from "../../ClientUpsert/Types";
import styles from '../Styles/select-client.module.css';
import { UserAvatar } from "../../../PSolutions.Components/Avatar/UserAvatar";

interface Props {
  client: IClient;

  onClientClick(client: IClient): void;
}

export class ClientItem extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onClientClick = this.onClientClick.bind(this);
  }

  onClientClick() {
    this.props.onClientClick(this.props.client)
  }

  render() {
    const {client} = this.props;
    return (
      <div className={styles.selectClientContainer} onClick={this.onClientClick}>
        <div className={styles.leftSide}>
          <UserAvatar size="50px" round name={client.firstName} profilePhoto={client.profilePhoto}/>
          <div className={styles.clientInfoHolder}>
            <div className={styles.clientName}>{client.firstName}</div>
            <div className={styles.clientPhoneNumber}>{client.phoneNumber}</div>
          </div>
        </div>
        <div className={styles.rightSide}>
          {!!this.props.client.userId && <BsShieldFillCheck size="1.5em" color="var(--utility-green)"/>}
          <BsArrowRight size="1.5em" color="var(--ternary-border-color)" className={styles.arrowRight}/>
        </div>
      </div>
    );
  }
}