import React from "react";
import { FormattedMessage, IntlContext } from "react-intl";
import { FaCommentDots, FaUserTag } from "react-icons/fa";
import { IAppointmentUpsertModel } from "../Types";
import { ClientInfoItem } from "./Client/ClientInfoItem";
import styles from '../Styles/appointment-upsert.module.css';
import { RegularInput } from "../../../PSolutions.Components/Inputs/Components/RegularInput";
import { ModalInputHolder } from "../../../PSolutions.Components/Inputs/Components/ModalInputHolder";

interface Props {
  upsertMode: boolean;
  selectedAppointment: IAppointmentUpsertModel,

  handleNoteChange(note: string): void;

  handleInternalNoteChange(internalNote: string): void;
}

export class AppointmentNotes extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.getNote = this.getNote.bind(this);
    this.getInternalNote = this.getInternalNote.bind(this);
  }

  getNote() {
    const {selectedAppointment} = this.props;
    const option = {id: "generic.messages.no.appointments.notes"};
    return !!selectedAppointment.note ? selectedAppointment.note : this.context.formatMessage(option);
  }

  getInternalNote() {
    const {selectedAppointment} = this.props;
    const option = {id: "generic.messages.no.internal.notes"};
    return !!selectedAppointment.internalNote ? selectedAppointment.internalNote : this.context.formatMessage(option);
  }

  getIcon() {
    return <FaCommentDots size="1.5em"/>
  }

  getIcon2() {
    return <FaUserTag size="1.5em"/>
  }


  render() {
    const {upsertMode} = this.props;
    const note = this.props.selectedAppointment?.internalNote || "";
    const internalNoteTitle = this.context.formatMessage({id: "generic.messages.internal.note"})
    const internalPlaceholder = this.context.formatMessage({id: "generic.messages.internal.note.placeholder"})

    return (
      <div className={styles.appointmentNotesContainer}>
        <div className={styles.divider}><FormattedMessage id="generic.messages.appointment.notes"/></div>
        <div className={styles.appointmentNotesWrapper}>
          <ClientInfoItem icon={this.getIcon()} show={!upsertMode} value={this.getInternalNote()}/>
          <ClientInfoItem icon={this.getIcon2()} show={!upsertMode} value={this.getNote()}/>
          <ModalInputHolder>
            <RegularInput
              id="note"
              type="text"
              value={note}
              show={upsertMode}
              name="internal-note"
              label={internalNoteTitle}
              placeholder={internalPlaceholder}
              handleTextChange={(e: any) => this.props.handleInternalNoteChange(e.currentTarget.value)}
            />
          </ModalInputHolder>
        </div>
      </div>
    );
  }
}