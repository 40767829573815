  import { AxiosError } from "axios";
import { IApiError } from "../PSolutions.Types/Error";

export class ErrorManager {
  private readonly _error: AxiosError<IApiError>;

  constructor(error: any) {
    this._error = error;
  }

  getApiError(): string {
    if (!this._error) return ""
    if (!this._error?.response) return ""
    if (!this._error?.response?.data) return ""

    return this._error.response.data.displayMessage;
  }
}
