import React from "react";
import Avatar from "react-avatar";
import styles from './avatar.module.css';

interface Props {
  size: string;
  round: boolean;
  name: string | null;
  profilePhoto: string | null;

  onClick?(): void;
}

export class UserAvatar extends React.PureComponent<Props> {
  render() {
    return (
      <Avatar
        maxInitials={2}
        fgColor="#979797"
        color="transparent"
        size={this.props.size}
        round={this.props.round}
        className={styles.avatar}
        onClick={this.props.onClick}
        name={this.props?.name || ""}
        src={this.props?.profilePhoto || ""}
      />
    );
  }
}