import React from "react";
import styles from '../../Styles/work.schedule.module.css';
import { IEmployeeModel } from "../../../EmployeeList/Types";
import { UserAvatar } from "../../../../PSolutions.Components/Avatar/UserAvatar";

interface Props {
  employee: IEmployeeModel;
}


export class ResourceItem extends React.PureComponent<Props> {
  render() {
    const {employee} = this.props;
    const photo = employee.profilePhoto;
    const name = `${employee.firstName} ${employee.lastName}`
    return (
      <div className={styles.resourceItem}>
        <div className={styles.resourceItemWrapper}>
          <UserAvatar size="45" round name={name} profilePhoto={photo}/>
          <div className={styles.resourceTitle}>{employee.firstName}</div>
        </div>
      </div>
    );
  }
}