import _ from 'lodash';
import React, { FormEvent } from 'react'
import { connect } from "react-redux";
import { IntlContext } from 'react-intl';
import { bindActionCreators, Dispatch } from "redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { IClient } from "../../ClientUpsert/Types";
import { ClientItem } from "../Components/ClientItem";
import styles from '../Styles/select-client.module.css';
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { clearError, clearState, getListAsync } from '../Redux/Actions';
import { TekicaPopup } from "../../../PSolutions.Prebuilt/Popups/Base/TekicaPopup";
import { OutlineButton } from "../../../PSolutions.Components/Buttons/OutlineButton";
import { ScrollHolder } from "../../../PSolutions.Prebuilt/Popups/Scroll/ScrollHolder";
import { PopupFooter } from "../../../PSolutions.Prebuilt/Popups/Base/Components/PopupFooter";
import { RegularInput } from "../../../PSolutions.Components/Inputs/Components/RegularInput";

interface Props {
  show: boolean;
  page: number;
  nextPage: number
  pageSize: number;
  hasMore: boolean;
  totalCount: number;
  clients: Array<IClient>

  clearError(): void;

  clearState(): void;

  closeModal(): void;

  onClientSelect(client: IClient): void;

  getListAsync(page: number, pageSize: number, searchText: string): any;
}

interface State {
  searchText: string;
}

class SelectClientContainer extends React.PureComponent<Props, State> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.state = {searchText: ""}
    this.onCloseModal = this.onCloseModal.bind(this);
    this.getDataAsync = this.getDataAsync.bind(this);
    this.handleClientClick = this.handleClientClick.bind(this);
    this.handleTextChange = _.debounce(this.handleTextChange, 500) as any;
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.props.show) return;
    if (this.props.show === prevProps.show) return;
    await this.props.getListAsync(1, 25, this.state.searchText);
  }

  async handleTextChange(searchText: string) {
    this.props.clearState();
    await this.props.getListAsync(1, 25, searchText);
  }

  async getDataAsync() {
    const {searchText} = this.state;
    const {nextPage, pageSize} = this.props;
    await this.props.getListAsync(nextPage, pageSize, searchText);
  }

  onCloseModal() {
    this.props.clearState();
    this.props.closeModal();
  }

  handleClientClick(client: IClient) {
    this.props.clearState();
    this.props.onClientSelect(client);
  }

  getLoader() {
    return <div>Loading</div>
  }

  render() {
    const {searchText} = this.state;
    const {show, hasMore, clients, totalCount} = this.props;
    const search = this.context.formatMessage({id: "generic.messages.search"});
    const cancelText = this.context.formatMessage({id: "generic.messages.cancel"});
    const clientsText = this.context.formatMessage({id: "generic.messages.clients"});
    const bottomText = this.context.formatMessage({id: "generic.messages.clients.total"});
    const placeholder = this.context.formatMessage({id: "generic.messages.search.clients.placeholder"});
    return (
      <TekicaPopup
        show={show}
        profilePhoto=""
        title={clientsText}
        preventRequestClose
        textBottom={bottomText}
        closeModal={this.onCloseModal}
        textBottomValue={totalCount.toString()}>
        <ScrollHolder>
          <RegularInput
            type="text"
            label={search}
            name="search-text"
            id="search-client"
            value={searchText}
            placeholder={placeholder}
            handleTextChange={(event: FormEvent<HTMLInputElement>) => this.handleTextChange(event.currentTarget.value)}/>
        </ScrollHolder>
        <ScrollHolder>
          <InfiniteScroll height={350} next={this.getDataAsync} hasMore={hasMore} loader={this.getLoader()} dataLength={clients.length}>
            {this.props.clients.map((c: IClient) => <ClientItem key={c.id} client={c} onClientClick={this.handleClientClick}/>)}
          </InfiniteScroll>
        </ScrollHolder>
        <PopupFooter styles={styles.footer}>
          <OutlineButton text={cancelText} isBusy={false} onClick={this.onCloseModal}/>
        </PopupFooter>
      </TekicaPopup>
    )
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    page: state.selectClient.page,
    clients: state.selectClient.clients,
    hasMore: state.selectClient.hasMore,
    pageSize: state.selectClient.pageSize,
    nextPage: state.selectClient.nextPage,
    totalCount: state.selectClient.totalCount,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getListAsync, clearError, clearState}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectClientContainer)