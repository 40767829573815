import * as dateFns from "date-fns";
import { IEmployeeModel, ITimeRange } from "../PSolutions.Containers/EmployeeList/Types";

export class EmployeeUtilities {

  static mapHoursToDay(date: Date, employee: IEmployeeModel): ITimeRange | undefined {
    const day = dateFns.getDay(date);

    switch (day) {
      case 0:
        return {startTime: employee.sundayStartTime, endTime: employee.sundayEndTime};
      case 1:
        return {startTime: employee.mondayStartTime, endTime: employee.mondayEndTime};
      case 2:
        return {startTime: employee.tuesdayStartTime, endTime: employee.tuesdayEndTime};
      case 3:
        return {startTime: employee.wednesdayStartTime, endTime: employee.wednesdayEndTime};
      case 4:
        return {startTime: employee.thursdayStartTime, endTime: employee.thursdayEndTime};
      case 5:
        return {startTime: employee.fridayStartTime, endTime: employee.fridayEndTime};
      case 6:
        return {startTime: employee.saturdayStartTime, endTime: employee.saturdayEndTime};
      default:
        return undefined;
    }
  }
}