import React from "react";
import styles from "../Styles/popup.module.css";
import { UserAvatar } from "../../../../PSolutions.Components/Avatar/UserAvatar";

interface Props {
  name: string;
  profilePhoto: string;
}

export class PopupAvatar extends React.PureComponent<Props> {
  render() {
    const {name, profilePhoto} = this.props;
    return (
      <div className={styles.baseModalAvatarHolder}>
        <UserAvatar size="65px" round={true} name={name} profilePhoto={profilePhoto}/>
      </div>
    );
  }
}