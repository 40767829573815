import * as dateFns from "date-fns";
import { TEKICA_TIME_FORMAT } from "../PSolutions.Config/TekicaTimeFormat";

export class AppointmentServiceUtility {
  public static CalculateEndTime(startTime: string, duration: number) {
    try {
      const parsed = dateFns.parse(startTime, TEKICA_TIME_FORMAT, new Date());
      const newTime = dateFns.addMinutes(parsed, duration);
      return dateFns.format(newTime, TEKICA_TIME_FORMAT);
    } catch (e) {
      return startTime;
    }
  }
}