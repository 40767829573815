import { Reducer } from "redux";
import { IPauseState } from "../Types";
import { PauseActions } from "./Actions";
import { PauseActionTypesEnum } from "./Types";

const initialState: IPauseState = {
  isBusy: false,
  showError: false,
  isDeleted: false,
  isSuccess: false,
  errorMessage: "",
  showDeleted: false,
  showSuccess: false,
}


const PauseReducer: Reducer<IPauseState, PauseActions> = (state: IPauseState = initialState, action: PauseActions) => {
  switch (action.type) {
    case PauseActionTypesEnum.GET_FAILURE:
    case PauseActionTypesEnum.ADD_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload}
    case PauseActionTypesEnum.GET_REQUESTED:
      return {...state, isBusy: true, showError: false, errorMessage: ""};
    case PauseActionTypesEnum.GET_SUCCESS:
      return {...state, isBusy: false, showError: false, errorMessage: ""};

    case PauseActionTypesEnum.CLEAR_STATE:
      return {...state, ...initialState}
    case PauseActionTypesEnum.CLEAR_ERROR:
      return {...state, showError: false, errorMessage: "", isBusy: false}
    default:
      return state;
  }
}

export default PauseReducer;