import React from "react";
import classNames from "classnames";
import { GoPlusSmall } from "react-icons/go";
import styles from "../../Styles/work.schedule.module.css";

interface Props {
  show: boolean;
}

export class AddIndicator extends React.PureComponent<Props> {
  render() {
    if (!this.props.show) return null;
    const appliesStyles = classNames(styles.shiftItem, styles.nonWorking);

    return (
      <div className={appliesStyles}><GoPlusSmall size="35"/></div>
    )
  }
}