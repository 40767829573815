import React from "react";
import { Switch, Route } from "react-router-dom";
import contentStyles from './main.content.module.css';
import AppointmentListContainer from "../../../PSolutions.Containers/AppointmentList/Container/AppointmentListContainer";
import OfficeSelectorContainer from "../../../PSolutions.Containers/OfficeSelector/Container/OfficeSelectorContainer";
import WorkScheduleContainer from "../../../PSolutions.Containers/WorkSchedule/Container/WorkScheduleContainer";

interface Props {
  sidebarOpen: boolean;

  toggleSidebar(): void;
}

export class Content extends React.PureComponent<Props> {
  render() {
    return (
      <div className={contentStyles.main}>
        <Switch>
          <Route exact path="/" component={OfficeSelectorContainer}/>
          <Route exact path="/clients" component={() => <h1>Clients</h1>}/>
          <Route exact path="/calendar" component={AppointmentListContainer}/>
          <Route exact path="/workSchedule" component={WorkScheduleContainer}/>
        </Switch>
      </div>
    );
  }
}