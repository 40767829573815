import React from "react";
import { IntlContext } from "react-intl";
import styles from "../Styles/appointment-upsert.module.css";
import { PrimaryButton } from "../../../PSolutions.Components/Buttons/PrimaryButton";
import { FlatButton } from "../../../PSolutions.Components/Buttons/FlatButton";

interface Props {
  show: boolean;
  isBusy: boolean;

  onCancelClick(): void;

  onReserveClick(): void;
}

export class AppointmentFooter extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    const {onCancelClick, onReserveClick} = this.props;
    const cancelText = this.context.formatMessage({id: "generic.messages.cancel"});
    const reserveText = this.context.formatMessage({id: "generic.messages.reserve"});
    return (
      <div className={styles.appointmentUpsertFooter}>
        <div className={styles.upsertButtonHolder}>
          <FlatButton text={cancelText} isBusy={false} hide={!this.props.show} className={styles.cancelBtnMargin} onClick={onCancelClick}/>
          <PrimaryButton text={reserveText} isBusy={this.props.isBusy} hide={!this.props.show} onClick={onReserveClick}/>
        </div>
      </div>
    );
  }
}