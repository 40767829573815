import React from "react";
import classNames from "classnames";
import { FormattedMessage, IntlContext } from "react-intl";
import styles from './loaders.module.css';
import { DangerButton } from "../Buttons/DangerButton";
import { BaseModal } from "../Modals/Base/BaseModal";

interface Props {
  error: string;
  visible: boolean;

  closeModal(): void;
}

export class ErrorDisplay extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    const {visible} = this.props;
    const icon = "fa fa-times fa-2x";
    const breakSection = {br: <br/>};
    const btnText = this.context.formatMessage({id: "generic.messages.ok"});
    return (
      <BaseModal visible={visible} closeModal={this.props.closeModal} topPosition={40} topPadding={50}>
        <div className={styles.container}>
          <div className={styles.iconWrapper}>
            <div className={styles.iconHolder}><i className={classNames(icon, styles.icon)}/></div>
          </div>
          <div className={styles.content}>
            <div className={styles.title}><FormattedMessage id="generic.messages.error"/></div>
            <div className={styles.apiError}>{this.props.error}</div>
            <div className={styles.description}><FormattedMessage id="generic.messages.error.desc" values={breakSection}/></div>
          </div>
          <div className={styles.footer}>
            <DangerButton isBusy={false} text={btnText} onClick={this.props.closeModal}/>
          </div>
        </div>
      </BaseModal>
    );
  }
}