import React from "react";
import Switch from "react-switch";
import classNames from "classnames";
import { IFormInput } from "./Types";
import { FormError } from "./FormError";
import styles from "./Styles/input.module.css";

interface Props extends IFormInput {
}


export class FormCheck extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {focused: false};
    this.handleChange = this.handleChange.bind(this);
    this.renderRequiredMark = this.renderRequiredMark.bind(this);
  }

  handleChange(checked: boolean) {
    this.props.form.setFieldValue(this.props.field.name, checked);
    this.props.form.setFieldTouched(this.props.field.name, true);
  }

  renderRequiredMark() {
    if (!this.props.required) return null;
    return <sup>&nbsp;*</sup>;
  }

  render() {
    const {id, label, field, form} = this.props;
    return (
      <div className={styles.checkHolder}>
        <label className={classNames(styles.checkLabel)} htmlFor={id}>{label}</label>
        <Switch id={id} onChange={this.handleChange} checked={field.value} checkedIcon={false} uncheckedIcon={false} size={25}/>
        <FormError errors={form.errors} fieldName={field.name} touched={form.touched}/>
      </div>
    );
  }
}