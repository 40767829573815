import * as dateFns from "date-fns";
import { EventApi } from "@fullcalendar/react";
import { DateSelectArg } from "@fullcalendar/common";
import { ICalendarManagerData, ISelectOption } from "../PSolutions.Types/Common";
import { TekicaTimeRangeOptions } from "../PSolutions.Common/Data/TimeOptions";
import { TEKICA_CALENDAR_FORMAT, TEKICA_DATE_FORMAT, TEKICA_TIME_FORMAT } from "../PSolutions.Config/TekicaTimeFormat";

export class CalendarManager {
  /**
   * Parse Data from full calendar selection
   * @param slot
   */
  static parseFromSelection(slot: DateSelectArg): ICalendarManagerData {
    const hasResource = !!slot.resource;
    if (!hasResource) return {} as ICalendarManagerData;

    // Parse extended props
    const groupId = 0;
    const id = slot.resource?.id || "";
    const dateObject = slot?.end || new Date();
    const fullName = slot.resource?.title || "";

    const profilePhoto = slot.resource?.extendedProps.profilePhoto || "";
    const end = dateFns.format(slot?.end || new Date(), TEKICA_TIME_FORMAT);
    const start = dateFns.format(slot?.start || new Date(), TEKICA_TIME_FORMAT);
    const date = dateFns.format(slot?.start || new Date(), TEKICA_CALENDAR_FORMAT);
    const tekicaDate = dateFns.format(slot?.start || new Date(), TEKICA_DATE_FORMAT);

    const endOption = TekicaTimeRangeOptions.find((o: ISelectOption) => o.value === end) || {} as ISelectOption;
    const startOption = TekicaTimeRangeOptions.find((o: ISelectOption) => o.value === start) || {} as ISelectOption;

    // Pack and ship
    return {
      date: date,
      id: groupId,
      endTime: end,
      employeeId: id,
      startTime: start,
      fullName: fullName,
      endOption: endOption,
      dateObject: dateObject,
      tekicaDate: tekicaDate,
      startOption: startOption,
      profilePhoto: profilePhoto,
    };
  }

  /**
   * Parse Data from full calendar event
   * @param selectedEvent
   */
  static parseFromEvent(selectedEvent: EventApi): ICalendarManagerData {

    const isAllDay = selectedEvent.allDay;
    const dateObject = selectedEvent?.end || new Date();
    const employee = selectedEvent?.getResources()[0] || "";
    const endTime = !isAllDay ? selectedEvent.extendedProps?.endTimeDisplay : "23:45";
    const startTime = !isAllDay ? selectedEvent.extendedProps?.startTimeDisplay : "00:00";
    const date = dateFns.format(selectedEvent?.start || new Date(), TEKICA_CALENDAR_FORMAT);
    const tekicaDate = dateFns.format(selectedEvent?.start || new Date(), TEKICA_DATE_FORMAT);

    const endOption = TekicaTimeRangeOptions.find((o: ISelectOption) => o.value === endTime) || {} as ISelectOption;
    const startOption = TekicaTimeRangeOptions.find((o: ISelectOption) => o.value === startTime) || {} as ISelectOption;

    return {
      date: date,
      endTime: endTime,
      startTime: startTime,
      endOption: endOption,
      dateObject: dateObject,
      tekicaDate: tekicaDate,
      employeeId: employee.id,
      fullName: employee.title,
      startOption: startOption,
      id: Number.parseInt(selectedEvent.groupId),
      profilePhoto: employee.extendedProps.profilePhoto,
    }
  }
}