import React from "react";
import { IntlContext } from "react-intl";
import { IWorkingHours } from "../../Types";
import resourceStyles from "../../Styles/resource.module.css";

interface Props {
  workingHours: Array<IWorkingHours>
}

export class HoursDisplay extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    const workingHours: Array<IWorkingHours> = this.props?.workingHours || [];
    const noTimeText = this.context.formatMessage({id: "appointment.resource.not.working"})
    const shouldRenderEmptyLabel = !workingHours.length || workingHours.every((w: IWorkingHours) => w.endTime === "00:00") || workingHours.every((w: IWorkingHours) => w.startTime === "00:00");

    return (
      <div className={resourceStyles.resourceWorkingHoursHolder}>
        {shouldRenderEmptyLabel && <span className={resourceStyles.noTimeLabel}>{noTimeText}</span>}
        {!shouldRenderEmptyLabel && workingHours.map((w: IWorkingHours, i: number) => <div key={i} className={resourceStyles.workingHours}>{i > 0 && ", "}{w.startTime}-{w.endTime}</div>)}
      </div>
    )
  }
}