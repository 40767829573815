import React from "react";
import { FormattedMessage } from "react-intl";
import styles from '../Styles/appointment-upsert.module.css';
import { IAppointmentUpsertModel, IAppointmentUpsertServiceModel } from "../Types";
import { AppointmentServiceFirstItem } from "./AppointmentService/AppointmentServiceFirstItem";

interface Props {
  show: boolean;
  selectedAppointment: IAppointmentUpsertModel;
}

export class ReadonlyServicesInfo extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.getFirstService = this.getFirstService.bind(this);
    this.getAppointmentTotal = this.getAppointmentTotal.bind(this);
    this.getFirstServiceEmployeeName = this.getFirstServiceEmployeeName.bind(this);
  }

  handleServiceSelect() {

  }

  getFirstService() {
    const {selectedAppointment} = this.props;
    const services = selectedAppointment?.services || [];
    return services[0] || {};
  }

  getFirstServiceEmployeeName() {
    const firstService = this.getFirstService();
    return `${firstService?.employee?.firstName} ${firstService?.employee?.lastName}`;
  }

  getAppointmentTotal() {
    let sum = 0;
    const services = this.props?.selectedAppointment?.services || [];
    services.map((a: IAppointmentUpsertServiceModel) => sum += parseFloat(a.service.price));
    return sum.toFixed(2);
  }

  render() {
    if (!this.props.show) return null;
    const firstService = this.getFirstService();

    return (
      <div className={styles.servicesContainer}>
        <div className={styles.divider}><FormattedMessage id="generic.messages.services"/></div>
        <div className={styles.servicesContainerWrapper}>
          <AppointmentServiceFirstItem onServiceSelect={this.handleServiceSelect} readonly={true}/>
        </div>
        <div className={styles.serviceContainerTotalHolder}>
          <span className={styles.serviceContainerTotalPlaceholder}><FormattedMessage id="generic.messages.total"/>:&nbsp;</span>
          <span className={styles.serviceContainerTotalPrice}>{this.getAppointmentTotal()} {firstService?.service?.currency}</span>
        </div>
      </div>
    );
  }
}