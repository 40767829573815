import _ from 'lodash';
import * as dateFns from "date-fns";
import { store } from "../../PSolutions.State/TekicaStore";
import { ISelectOption } from "../../PSolutions.Types/Common";
import { TEKICA_TIME_FORMAT } from "../../PSolutions.Config/TekicaTimeFormat";

const date = new Date()
const range = _.range(0, 1440 / 5);
date.setHours(0, 0, 0, 0);

const mapped = range.map((time) => dateFns.addMinutes(date, 5 * time))
const formattedTime = mapped.map((time: Date) => dateFns.format(time, TEKICA_TIME_FORMAT));
export const TekicaTimeRangeOptions: Array<ISelectOption> = formattedTime.map((time: string) => ({label: time, value: time}));


export function GetTekicaTimeRangeOptions(): Array<ISelectOption> {
  const localDate = new Date();
  localDate.setHours(0, 0, 0, 0);
  const step = store.getState().appointmentList?.inlineCalendarMinuteInterval || 15;

  const localRange = _.range(0, 1440 / step);
  const localMapped = localRange.map((time) => dateFns.addMinutes(localDate, step * time))
  const formatted = localMapped.map((time: Date) => dateFns.format(time, TEKICA_TIME_FORMAT));


  return formatted.map((time: string) => ({label: time, value: time}));
}
