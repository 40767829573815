import React from "react";
import { FastField } from "formik";
import { FormInput } from "../Inputs/FormInput";

interface Props {
  id: string;
  name: string;
  type: string;
  label: string;
  required: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  placeholder: string;
}

export class InputField extends React.PureComponent<Props> {
  render() {
    return (
      <FastField
        id={this.props.id}
        component={FormInput}
        type={this.props.type}
        name={this.props.name}
        label={this.props.label}
        disabled={this.props.disabled}
        required={this.props.required}
        hideLabel={this.props.hideLabel}
        placeholder={this.props.placeholder}
      />
    );
  }
}