import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { ISignInModel } from "../Types";
import { SignInSchema } from "./SignInSchema";
import { SignInView } from "../Views/SignInView";
import { clearError, signInAsync } from "../Redux/Actions";
import { appLoginIn } from "../../../PSolutions.App/Redux/Actions";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { ErrorDisplay } from "../../../PSolutions.Components/UI/ErrorDisplay";

export const SignInContainer = React.memo(() => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [signInData] = useState({email: "", password: ""} as ISignInModel);
  const {showError, errorMessage, isBusy} = useSelector((state: IRootState) => state.signIn);

  function _clearError() {
    dispatch(clearError());
  }

  async function handleSubmitAsync(values: ISignInModel, actions: FormikHelpers<ISignInModel>) {
    const data: any = await dispatch(signInAsync(values));
    actions.setSubmitting(false);
    if (!data) return;

    history.push("/");
    dispatch(appLoginIn(data));
  }

  return (
    <>
      <Formik
        component={SignInView}
        initialValues={signInData}
        onSubmit={handleSubmitAsync}
        validationSchema={SignInSchema}
      />
      <ErrorDisplay error={errorMessage} visible={showError && !isBusy} closeModal={_clearError}/>
    </>
  )
});
