import React from "react";
import { FocusEvent } from "react";
import * as dateFns from 'date-fns';
import { IntlContext } from 'react-intl';
import DatePicker from "react-datepicker";
import styles from "../../Styles/work.schedule.module.css";
import { ISelectOption } from "../../../../PSolutions.Types/Common";
import { TekicaSelect } from "../../../../PSolutions.Components/Controls/TekicaSelect";
import { TEKICA_CALENDAR_FORMAT } from "../../../../PSolutions.Config/TekicaTimeFormat";

interface Props {
  repeatTo: Date;
  locale: Locale;
  show: boolean;
  selectedDate: Date;
  repeatRule: ISelectOption;
  repeatRules: Array<ISelectOption>;

  handleEndRepeatChange(date: Date): void;

  handleRepeatChange(option: ISelectOption): void;
}

export class RepeatingRules extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.renderRepeatTo = this.renderRepeatTo.bind(this);
  }


  handleUserInput(e: FocusEvent<HTMLInputElement>) {
    e.preventDefault();
  }

  renderRepeatTo() {
    if (!this.props.show) return null;
    const {repeatTo, handleEndRepeatChange, locale, selectedDate} = this.props;
    const repeatRuleTextEnds = this.context.formatMessage({id: "generic.messages.repeating.rule.ends"});

    return (
      <React.Fragment>
        <label className={styles.label}>{repeatRuleTextEnds}</label>
        <DatePicker
          locale={locale}
          selected={repeatTo}
          minDate={selectedDate}
          onChange={handleEndRepeatChange}
          onChangeRaw={this.handleUserInput}
          dateFormat={TEKICA_CALENDAR_FORMAT}
          maxDate={dateFns.addMonths(new Date(), 3)}
        />
      </React.Fragment>
    )
  }

  render() {
    const {repeatRules, repeatRule, handleRepeatChange} = this.props;
    const repeatRuleText = this.context.formatMessage({id: "generic.messages.repeating.rule"});
    return (
      <div className={styles.repeatRuleContainer}>
        <div className={styles.repeatRuleItem}>
          <TekicaSelect options={repeatRules} label={repeatRuleText} value={repeatRule} handleChange={handleRepeatChange}/>
        </div>
        <div className={styles.repeatRuleItem}>{this.renderRepeatTo()}</div>
      </div>
    );
  }
}