import React from "react";
import Calendar, { CalendarTileProperties } from 'react-calendar';
import styles from './calendar.module.css';
import { TranslationContext } from "../../PSolutions.Providers/Translation/TranslationContext";

interface Props {
  date: Date

  onDateSelected(date: Date): void;
}

export class TekicaCalendar extends React.PureComponent<Props> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;

  handleTile(props: CalendarTileProperties) {
    return props.date.getDay() === 0 ? "non-working" : "working-day";
  }

  getPrevLabel() {
    return <div className={styles.navButtons}><i className="fa fa-2x fa-angle-left"/></div>
  }

  getNextLabel() {
    return <div className={styles.navButtons}><i className="fa fa-2x fa-angle-right"/></div>
  }

  render() {
    return (
      <div className={styles.container}>
        <Calendar
          view="month"
          minDetail="month"
          maxDetail="month"
          next2Label={null}
          prev2Label={null}
          value={this.props.date}
          className={styles.calendar}
          nextLabel={this.getNextLabel()}
          prevLabel={this.getPrevLabel()}
          tileClassName={this.handleTile}
          onChange={this.props.onDateSelected}
          defaultActiveStartDate={this.props.date}
          locale={this.context.currentLocale.code}
        />
      </div>
    );
  }
}