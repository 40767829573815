import React from "react";
import cn from "classnames";
import styles from '../Styles/popup.module.css';
import { PopupAvatar } from "./PopupAvatar";

interface Props {
  name: string;
  title: string;
  subtitle: string;
  textBottom?: string;
  profilePhoto: string;
  backgroundColor: string;
  textBottomValue?: string;
}

export class TekicaPopupHeader extends React.PureComponent<Props> {
  static defaultProps = {backgroundColor: "var(--secondary-border-color)"};

  constructor(props: Props) {
    super(props);
    this.getBackgroundColor = this.getBackgroundColor.bind(this);
  }

  render() {
    const bottomLabel = !!this.props?.textBottom ? `${this.props.textBottom}:` : "";
    return (
      <div className={cn(styles.baseModalHeader)} style={this.getBackgroundColor()}>
        <PopupAvatar name={this.props.name} profilePhoto={this.props.profilePhoto}/>
        <div className={styles.baseModalHeaderSubTitle}>{this.props.subtitle}</div>
        <div className={styles.baseModalHeaderTitle}>{this.props.title}</div>
        <div className={styles.textBottom}>{bottomLabel} {this.props.textBottomValue}</div>
      </div>
    );
  }

  getBackgroundColor() {
    return {backgroundColor: this.props.backgroundColor};
  }
}