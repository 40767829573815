import React from "react";
import { addDays, format, startOfWeek } from 'date-fns'
import { RepeatDay } from "./RepeatDay";
import styles from "../../Styles/work.schedule.module.css";
import { TranslationContext } from "../../../../PSolutions.Providers/Translation/TranslationContext";

interface Props {
  show: boolean;
  selectedDays: Array<number>;

  handleSelectDay(day: number): void;
}

export class RepeatDays extends React.PureComponent<Props> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;

  constructor(props: Props) {
    super(props);
    this.isSelected = this.isSelected.bind(this);
  }


  isSelected(day: number) {
    return this.props.selectedDays.includes(day);
  }

  render() {
    if (!this.props.show) return null;
    const firstLocalizedDay = startOfWeek(new Date());
    const options = {locale: this.context.currentLocale};
    const shortWeekDaysArray = Array.from(Array(7)).map((e, i: number) => format(addDays(firstLocalizedDay, i), 'EEEEEE', options))

    return (
      <div className={styles.repeatRuleContainer}>
        {shortWeekDaysArray.map((d: string, i: number) => <RepeatDay key={i} dayName={d} onClick={() => this.props.handleSelectDay(i)} selected={this.isSelected(i)}/>)}
      </div>
    );
  }
}