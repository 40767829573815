import React from "react";
import { FormikProps } from "formik";
import { IntlContext } from "react-intl";
import styles from '../Styles/pause.container.module.css';
import { ISelectOption } from "../../../PSolutions.Types/Common";
import { InputField } from "../../../PSolutions.Components/Fields/InputField";
import { CheckField } from "../../../PSolutions.Components/Fields/CheckField";
import { SelectField } from "../../../PSolutions.Components/Fields/SelectField";
import { DangerButton } from "../../../PSolutions.Components/Buttons/DangerButton";
import { PrimaryButton } from "../../../PSolutions.Components/Buttons/PrimaryButton";
import { GetTekicaTimeRangeOptions } from "../../../PSolutions.Common/Data/TimeOptions";
import { PopupFooter } from "../../../PSolutions.Prebuilt/Popups/Base/Components/PopupFooter";
import { PopupContent } from "../../../PSolutions.Prebuilt/Popups/Base/Components/PopupContent";

interface Props extends FormikProps<any> {
  isEdit: boolean;
  visible: boolean;
}

export class PauseView extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private readonly timeOptions: Array<ISelectOption>;

  constructor(props: Props) {
    super(props);
    this.timeOptions = GetTekicaTimeRangeOptions();
  }


  render() {
    const allDay = this.context.formatMessage({id: "appointment.all.day.title"});
    const pauseName = this.context.formatMessage({id: "appointment.new.pause.title"});
    const addNewPause = this.context.formatMessage({id: "appointment.add.new.pause.btn.text"});
    const deletePause = this.context.formatMessage({id: "appointment.delete.pause.btn.text"});
    const pausePlaceholder = this.context.formatMessage({id: "appointment.new.pause.placeholder"});

    return (
      <>
        <PopupContent showDivider={false}>
          <CheckField id="allDay" name="allDay" label={allDay} required={false}/>
          <InputField type="text" required={true} id="pause-title" name="description" label={pauseName} placeholder={pausePlaceholder}/>
          <div className={styles.selectedTimeRow}>
            <SelectField required name="startTime" id="pause-startTime" options={this.timeOptions} containerClassName={styles.startTimeSelect}/>
            <SelectField required name="endTime" id="pause-endTime" options={this.timeOptions} containerClassName={styles.endTimeSelect}/>
          </div>
        </PopupContent>
        <PopupFooter>
          <PrimaryButton text={addNewPause} isBusy={this.props.isSubmitting} onClick={this.props.submitForm} hide={this.props.isEdit}/>
          <DangerButton text={deletePause} isBusy={this.props.isSubmitting} onClick={this.props.submitForm} hide={!this.props.isEdit || !this.props.visible}/>
        </PopupFooter>
      </>
    );
  }
}