import React from "react";
import { IAppTranslation } from "../Types";
import { getTranslationContextLocale, TranslationContext } from "./TranslationContext";
import { APPLICATION_DEFAULT_LOCALE } from "../../PSolutions.Config/ApplicationSettings";

interface Props {
  currentLanguage: string | null;
}

export class TranslationProvider extends React.PureComponent<Props> {
  render() {
    const lng = this.props?.currentLanguage || APPLICATION_DEFAULT_LOCALE;
    const values: IAppTranslation = {currentLanguage: lng, currentLocale: getTranslationContextLocale(lng)};
    return <TranslationContext.Provider value={values}> {this.props.children}</TranslationContext.Provider>;
  }
}