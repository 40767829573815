import axios from "axios";
import { Dispatch } from "redux";
import { ServiceSelectorActionTypesEnum } from "./Types";
import { IServiceModel } from "../../AppointmentUpsert/Types";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";

export interface GetListSuccessAction {
  payload: Array<IServiceModel>;
  type: ServiceSelectorActionTypesEnum.GET_LIST_SUCCESS;
}


export interface GetListFailureAction {
  payload: string;
  type: ServiceSelectorActionTypesEnum.GET_LIST_FAILURE;
}

export interface GetListRequestedAction {
  type: ServiceSelectorActionTypesEnum.GET_LIST_REQUESTED;
}

export interface IServiceSelectorClearErrorAction {
  type: ServiceSelectorActionTypesEnum.CLEAR_ERROR;
}

export interface IServiceSelectorClearStateAction {
  type: ServiceSelectorActionTypesEnum.CLEAR_STATE;
}


export const getServiceListAsync = (serviceName: string, employeeId: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const officeId = getState().officeSelector.selectedOfficeId;
      dispatch({type: ServiceSelectorActionTypesEnum.GET_LIST_REQUESTED});
      const res = await axios.get<Array<IServiceModel>>(`${AppSettings.apiUrl}/OfficeServices/SearchOfficeServices/${officeId}`, {params: {serviceName, employeeId}});
      dispatch({type: ServiceSelectorActionTypesEnum.GET_LIST_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: ServiceSelectorActionTypesEnum.GET_LIST_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}


export const clearError = () => (dispatch: Dispatch) => dispatch({type: ServiceSelectorActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: ServiceSelectorActionTypesEnum.CLEAR_STATE});

export type ServiceSelectorActions =
  GetListRequestedAction |
  GetListSuccessAction |
  GetListFailureAction |
  IServiceSelectorClearErrorAction |
  IServiceSelectorClearStateAction;