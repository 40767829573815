import React from "react";
import Modal from 'react-modal';

interface Props {
  visible: boolean;
  customStyles: any;
  topPadding?: number;
  topPosition?: number;
  preventRequestClose?: boolean;

  closeModal(): void;
}

const styles = {
  content: {
    top: '25%',
    left: '50%',
    right: 'auto',
    zIndex: 99999,
    bottom: 'auto',
    border: 'none',
    paddingTop: "20px",
    marginRight: '-50%',
    backgroundColor: 'transparent',
    transform: 'translate(-50%, -50%)',
  },
};

export class BaseModal extends React.PureComponent<Props> {
  static defaultProps = {
    customStyles: styles,
  }

  render() {
    const {visible, closeModal, topPosition} = this.props;
    const {topPadding, customStyles, preventRequestClose} = this.props;

    if (!!topPosition) customStyles.content.top = `${topPosition}%`;
    if (!!topPadding) customStyles.content.paddingTop = `${topPadding}px`;
    
    return (
      <Modal isOpen={visible} style={customStyles} closeTimeoutMS={200} onRequestClose={!preventRequestClose ? closeModal : undefined}>{this.props.children}</Modal>
    );
  }
}