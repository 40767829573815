import React from 'react'
import { connect } from "react-redux";
import { IntlContext } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps } from 'react-router-dom';

import { IOfficeSelectorModel } from "../Types";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { BusyLoader } from "../../../PSolutions.Components/UI/BusyLoader";
import { clearError, clearState, getAsync, setOfficeId } from '../Redux/Actions';

interface Props extends RouteComponentProps<any> {
  children: any;
  isBusy: boolean;

  getAsync(): any;

  clearError(): void;

  clearState(): void;

  setOfficeId(office: IOfficeSelectorModel): void;
}

interface State {

}

class OfficeSelectorContainer extends React.PureComponent<Props, State> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  async componentDidMount() {
    const offices: Array<IOfficeSelectorModel> = await this.props.getAsync();
    if (offices && offices.length !== 1) return;

    this.props.setOfficeId(offices[0]);
    this.props.history.push("/calendar");
  }

  render() {
    return (
      <React.Fragment>
        <BusyLoader visible={this.props.isBusy}/>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isBusy: state.officeSelector.isBusy,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({clearError, clearState, getAsync, setOfficeId}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OfficeSelectorContainer))