/**
 * Calendar utilities
 */
export class CalendarUtilities {

  public static updateHeightOfGridCell(intervalMinute: number) {
    const heightVariable = "--full-calendar-time-grid-slot-height";
    // Minimal interval is 15 minute
    if (intervalMinute >= 0 && intervalMinute <= 15) return document.documentElement.style.setProperty(heightVariable, '33px');
    if (intervalMinute > 15 && intervalMinute <= 20) return document.documentElement.style.setProperty(heightVariable, '40px');
    if (intervalMinute > 20 && intervalMinute <= 30) return document.documentElement.style.setProperty(heightVariable, '45px');
    if (intervalMinute > 30 && intervalMinute <= 45) return document.documentElement.style.setProperty(heightVariable, '50px');
    return document.documentElement.style.setProperty(heightVariable, '60px');
  }
}