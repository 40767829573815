import React from "react";
import Switch from "react-switch";
import classNames from "classnames";
import styles from "../Styles/input.module.css"

interface Props {
  id: string;
  show: boolean;
  label: string;
  value: boolean;
  required: boolean;
  containerStyle?: any;

  handleChange(checked: boolean): void;
}


export class RegularCheck extends React.PureComponent<Props> {
  static defaultProps = {required: false, show: true};

  constructor(props: Props) {
    super(props);
    this.state = {focused: false};
    this.renderRequiredMark = this.renderRequiredMark.bind(this);
  }

  renderRequiredMark() {
    if (!this.props.required) return null;
    return <sup>&nbsp;*</sup>;
  }

  render() {
    if (!this.props.show) return null;
    const {id, label, value, containerStyle} = this.props;
    return (
      <div className={classNames(styles.checkHolder, containerStyle)}>
        <label className={classNames(styles.checkLabel)} htmlFor={id}>{label}</label>
        <Switch id={id} onChange={this.props.handleChange} checked={value} checkedIcon={false} uncheckedIcon={false} size={25}/>
      </div>
    );
  }
}