import React from "react";
import { IFormSelect } from "./Types";
import { FormError } from "./FormError";
import styles from './Styles/input.module.css';
import { TekicaSelect } from "../Controls/TekicaSelect";
import { ISelectOption } from "../../PSolutions.Types/Common";

interface Props extends IFormSelect {
}

interface State {
  focused: boolean;
}

export class FormSelect extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {focused: false};
    this.renderIcon = this.renderIcon.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isFieldDirty = this.isFieldDirty.bind(this);
    this.hasFieldErrors = this.hasFieldErrors.bind(this);
    this.renderRequiredMark = this.renderRequiredMark.bind(this);
  }

  isFieldDirty() {
    return !!this.props.form.touched[this.props.field.name];
  }

  hasFieldErrors() {
    return !!this.props.form.errors[this.props.field.name];
  }

  handleChange(selectOption: ISelectOption) {
    this.props.form.setFieldTouched(this.props.field.name, true);
    this.props.form.setFieldValue(this.props.field.name, selectOption);
  }

  renderRequiredMark() {
    if (!this.props.required) return null;
    return <sup>&nbsp;*</sup>;
  }

  renderIcon() {
    const icon = this.props.icon;
    if (!icon) return null;
    return <div className={styles.iconHolder}>{this.props.icon}</div>
  }

  render() {
    const {label, field, form, disabled, options, containerClassName} = this.props;

    return (
      <div className={styles.inputHolder}>
        <div className={styles.formSelectWrapper}>
          {this.renderIcon()}
          <TekicaSelect
            label={label}
            options={options}
            value={field.value}
            disabled={disabled}
            handleChange={this.handleChange}
            containerClass={containerClassName}
          />
        </div>
        <FormError errors={form.errors} fieldName={field.name} touched={form.touched}/>
      </div>
    );
  }
}