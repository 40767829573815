import React from "react";
import cn from 'classnames';
import { FaCommentDots } from "react-icons/fa";
import { FaAddressCard, FaPhone } from "react-icons/fa";
import { FormattedMessage, IntlContext } from "react-intl";
import { ClientInfoItem } from "./ClientInfoItem";
import { IAppointmentUpsertModel } from "../../Types";
import { SelectClientButton } from "./SelectClientButton";
import styles from '../../Styles/appointment-upsert.module.css';
import { RegularCheck } from "../../../../PSolutions.Components/Inputs/Components/RegularCheck";
import { RegularInput } from "../../../../PSolutions.Components/Inputs/Components/RegularInput";
import { ModalInputHolder } from "../../../../PSolutions.Components/Inputs/Components/ModalInputHolder";

interface Props {
  upsertMode: boolean;
  selectedAppointment: IAppointmentUpsertModel;

  handleClientSelect(): void;

  toggleWalkIn(checked: boolean): void;

  handleWalkInChange(text: string): void;
}

export class ClientInfo extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.getClientName = this.getClientName.bind(this);
    this.getClientNote = this.getClientNote.bind(this);
    this.getPhoneNumber = this.getPhoneNumber.bind(this);
  }

  getClientNote() {
    const option = {id: "generic.messages.client.note.has.not.entered"};
    if (!this.props.selectedAppointment.clientId) return this.context.formatMessage(option);
    if (!!this.props.selectedAppointment.clientId) return this.props.selectedAppointment?.client?.about || this.context.formatMessage(option)
  }

  getClientName() {
    const option = {id: "generic.messages.client.name.has.not.entered"};
    if (!!this.props.selectedAppointment.clientId) return this.props.selectedAppointment.client?.name;
    if (!this.props.selectedAppointment.clientId) return this.props.selectedAppointment?.walkInClientName || this.context.formatMessage(option)
  }

  getPhoneNumber() {
    const option = {id: "generic.messages.client.phone.number.has.not.entered"};
    if (!this.props.selectedAppointment.clientId) return this.context.formatMessage(option)
    if (!!this.props.selectedAppointment.clientId) return this.props.selectedAppointment.client?.phoneNumber || this.context.formatMessage(option);
  }

  getNameIcon() {
    return <FaAddressCard size="1.5em"/>
  }

  getPhoneIcon() {
    return <FaPhone size="1.4em" className={styles.phoneRotate}/>;
  }

  getNoteIcon() {
    return <FaCommentDots size="1.5em"/>;
  }

  render() {
    const {upsertMode, selectedAppointment, toggleWalkIn} = this.props;
    const walkInValue = this.props.selectedAppointment?.walkInClient || false;
    const walkIn = this.context.formatMessage({id: "generic.messages.walk.in"});
    const title = this.context.formatMessage({id: "generic.messages.walk.in.client.title"});
    const walkInPlaceholder = this.context.formatMessage({id: "generic.messages.walk.in.placeholder"});
    return (
      <div className={cn(styles.clientInfoContainer)}>
        <div className={styles.divider}><FormattedMessage id="generic.messages.client"/></div>
        <div className={styles.clientInfoWrapper}>
          <SelectClientButton
            show={upsertMode && !walkInValue}
            selectedAppointment={selectedAppointment}
            onClientSelect={this.props.handleClientSelect}
          />
          <ModalInputHolder show={upsertMode}>
            <RegularInput
              noMargin
              type="text"
              name="walk-in"
              label={title}
              id="walk-in-input"
              show={walkInValue}
              placeholder={walkInPlaceholder}
              value={selectedAppointment.walkInClientName}
              handleTextChange={(e: any) => this.props.handleWalkInChange(e.currentTarget.value)}
            />
          </ModalInputHolder>
          <RegularCheck show={upsertMode} label={walkIn} id="walk-in" value={walkInValue} handleChange={toggleWalkIn} containerStyle={styles.walkInCheck}/>
          <ClientInfoItem icon={this.getNameIcon()} show={!upsertMode} value={this.getClientName()}/>
          <ClientInfoItem icon={this.getPhoneIcon()} show={!upsertMode} value={this.getPhoneNumber()}/>
          <ClientInfoItem icon={this.getNoteIcon()} show={!upsertMode} value={this.getClientNote()}/>
        </div>
      </div>
    );
  }
}