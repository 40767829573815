import axios from "axios";
import { Dispatch } from "redux";
import { IShiftModel } from "../Types";
import { ShiftsActionTypesEnum } from "./Types";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";
import { IRootState } from "../../../PSolutions.State/RootReducer";

export interface GetSuccessAction {
  payload: IShiftModel;
  type: ShiftsActionTypesEnum.GET_SUCCESS;
}

export interface GetFailureAction {
  payload: string;
  type: ShiftsActionTypesEnum.GET_FAILURE;
}

export interface GetRequestedAction {
  type: ShiftsActionTypesEnum.GET_REQUESTED;
}

export interface IShiftsClearErrorAction {
  type: ShiftsActionTypesEnum.CLEAR_ERROR;
}

export interface IShiftsClearStateAction {
  type: ShiftsActionTypesEnum.CLEAR_STATE;
}

export interface GetListSuccessAction {
  payload: Array<IShiftModel>;
  type: ShiftsActionTypesEnum.GET_LIST_SUCCESS;
}


export interface GetListFailureAction {
  payload: string;
  type: ShiftsActionTypesEnum.GET_LIST_FAILURE;
}

export interface GetListRequestedAction {
  type: ShiftsActionTypesEnum.GET_LIST_REQUESTED;
}

export interface IShiftsClearErrorAction {
  type: ShiftsActionTypesEnum.CLEAR_ERROR;
}

export interface IShiftsClearStateAction {
  type: ShiftsActionTypesEnum.CLEAR_STATE;
}


export const getAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ShiftsActionTypesEnum.GET_REQUESTED});
      const res = await axios.post<IShiftModel>(`${AppSettings.apiUrl}/{path}`);
      dispatch({type: ShiftsActionTypesEnum.GET_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: ShiftsActionTypesEnum.GET_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const getListAsync = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const officeId = getState().officeSelector.selectedOfficeId;
      dispatch({type: ShiftsActionTypesEnum.GET_LIST_REQUESTED});
      const res = await axios.get<Array<IShiftModel>>(`${AppSettings.apiUrl}/OfficeShift`, {params: {officeId}});
      dispatch({type: ShiftsActionTypesEnum.GET_LIST_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: ShiftsActionTypesEnum.GET_LIST_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const clearError = () => (dispatch: Dispatch) => dispatch({type: ShiftsActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: ShiftsActionTypesEnum.CLEAR_STATE});

export type ShiftsActions =
  IShiftsClearErrorAction |
  IShiftsClearStateAction |
  GetListRequestedAction |
  GetListSuccessAction |
  GetRequestedAction |
  GetSuccessAction |
  GetFailureAction |
  GetListFailureAction;