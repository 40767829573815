import React from "react";
import { FormattedMessage } from "react-intl";
import { BsArrowRight, BsShieldFillCheck } from "react-icons/all";
import { IAppointmentUpsertModel } from "../../Types";
import styles from "../../Styles/appointment-upsert.module.css";
import { UserAvatar } from "../../../../PSolutions.Components/Avatar/UserAvatar";

interface Props {
  show: boolean;
  selectedAppointment: IAppointmentUpsertModel;

  onClientSelect(): void;
}

export class SelectClientButton extends React.PureComponent<Props> {
  render() {
    if (!this.props.show) return null
    const selectClientText = "generic.messages.select.client";

    const name = this.props.selectedAppointment.client?.firstName || "";
    const photo = this.props.selectedAppointment.client?.profilePhoto || "";
    const number = this.props.selectedAppointment.client?.phoneNumber || "";
    const clientEntered = !!this.props.selectedAppointment?.clientId || false;
    const verified = !!this.props.selectedAppointment?.client?.userId || false;

    return (
      <div className={styles.selectClientButton} onClick={this.props.onClientSelect}>
        <UserAvatar size="40px" round name={name} profilePhoto={photo}/>
        {verified && <BsShieldFillCheck size="1.5em" color="var(--utility-green)" className={styles.verified}/>}
        <BsArrowRight size="1.5em" color="var(--ternary-border-color)" className={styles.selectClientArrowRight}/>

        {!clientEntered && <span className={styles.selectClientButtonText}><FormattedMessage id={selectClientText}/></span>}
        {
          clientEntered &&
          <div className={styles.clientUpsertInfoHolder}>
            <div className={styles.clientUpsertName}>{name}</div>
            <div className={styles.clientUpsertPhoneNumber}>{number}</div>
          </div>
        }
      </div>
    );
  }
}