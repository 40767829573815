import React from "react";
import { FastField } from "formik";
import { FormCheck } from "../Inputs/FormCheck";

interface Props {
  id: string;
  name: string;
  label: string;
  required: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
}

export class CheckField extends React.PureComponent<Props> {
  render() {
    return (
      <FastField
        id={this.props.id}
        component={FormCheck}
        name={this.props.name}
        label={this.props.label}
        disabled={this.props.disabled}
        required={this.props.required}
        hideLabel={this.props.hideLabel}
      />
    );
  }
}