import React from "react";
import { useIntl } from "react-intl";
import { FormikErrors, FormikTouched } from "formik";
import inputFormStyles from './Styles/input.module.css';

interface Props {
  fieldName: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}

export const FormError = React.memo(({errors, fieldName}: Props) => {
  const intl = useIntl();
  if (!errors[fieldName]) return null;

  const error: any = errors[fieldName];
  return <div className={inputFormStyles.error}>{intl.formatMessage({id: error.key}, {...error.values})}</div>
})