import React from "react";
import classNames from "classnames";
import styles from './buttons.module.css';
import { BeatLoader } from "react-spinners";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  text: string;
  hide?: boolean;
  isBusy: boolean;

}

export class DangerButton extends React.PureComponent<Props> {
  render() {
    const {isBusy, text, onClick, type, hide, className, ...rest} = this.props;
    if (hide) return null;
    return (
      <button className={classNames(styles.dangerButton, className)} onClick={onClick} disabled={isBusy} {...rest}>
        {!this.props.isBusy && this.props.text}
        <BeatLoader size={9} color="white" loading={this.props.isBusy} speedMultiplier={0.75} css={styles.loader}/>
      </button>
    );
  }
}