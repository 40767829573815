import axios, { AxiosRequestConfig } from "axios";
import { store } from "../PSolutions.State/TekicaStore";

export const registerRequestInterceptor = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers["Accept-Language"] = store.getState().app.currentLanguage;
    config.headers["Authorization"] = `Bearer ${store.getState().app.accessToken}`;
    return config;
  })
}



