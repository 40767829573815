import React from "react";
import { VscClose } from 'react-icons/vsc';
import { FormattedMessage } from "react-intl";
import { BaseModal } from "./Base/BaseModal";
import styles from './Styles/modal.module.css'

interface Props {
  footer: any;
  content: any
  title: string;
  visible: boolean;

  closeModal(): void;
}

export class ContentModal extends React.PureComponent<Props> {
  render() {
    const {visible, closeModal, title} = this.props;
    return (
      <BaseModal visible={visible} closeModal={closeModal} topPosition={25}>
        <div className={styles.simpleContent}>
          <div className={styles.header}>
            <VscClose size={45} className={styles.headerIcon} onClick={closeModal}/>
            <div className={styles.headerTitle}><FormattedMessage id={title}/></div>
          </div>
          <div className={styles.main}>{this.props?.content}</div>
          <div className={styles.footer}>{this.props?.footer}</div>
        </div>
      </BaseModal>
    );
  }
}