import React from "react";
import classNames from "classnames";
import { IntlContext } from "react-intl";
import Select, { createFilter, ThemeConfig } from "react-select";
import styles from './tekica-select.module.css';
import { ISelectOption } from "../../PSolutions.Types/Common";

interface Props {
  label?: string;
  disabled?: boolean;
  containerClass?: string;
  defaultValue?: ISelectOption;
  options?: Array<ISelectOption>
  value: ISelectOption | undefined;

  handleChange(option: ISelectOption | null): void;
}

export class TekicaSelect extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    const {containerClass, label, defaultValue, disabled} = this.props;
    const selectLabel = this.context.formatMessage({id: "generic.messages.select"})
    return (
      <div className={classNames(containerClass, styles.wrapper)}>
        {!!label && <div className={styles.label}>{label}</div>}
        <Select
          styles={style}
          theme={appliedTheme}
          isDisabled={disabled}
          value={this.props.value}
          placeholder={selectLabel}
          defaultValue={defaultValue}
          options={this.props.options}
          menuPortalTarget={document.body}
          onChange={this.props.handleChange}
          filterOption={createFilter({ignoreAccents: false})}
        />
      </div>
    );
  }
}

const style = {menuPortal: (base: any) => ({...base, zIndex: 9999})}
const appliedTheme: any = (theme: any) => ({
  ...theme,
  borderRadius: ".4rem",
  colors: {
    ...theme.colors,
    primary: "var(--primary-font-color)",
    primary25: "var(--primary-border-color)",
    primary50: "var(--secondary-border-color)"
  }
}) as ThemeConfig;
