import React from "react";
import cn from "classnames";
import styles from '../Styles/popup.module.css';

interface Props {
  dividerText?: string;
  showDivider: boolean;
}

export class PopupContent extends React.PureComponent<Props> {
  static defaultProps = {showDivider: false}
  render() {
    const {dividerText, showDivider} = this.props;
    const applied = cn(styles.popupContentDivider, {[styles.hideDivider]: !showDivider})
    return (
      <div className={styles.popupContentHolder}>
        <div className={applied}>{dividerText}</div>
        <div className={styles.popupContentWrapper}>{this.props.children}</div>
      </div>
    );
  }
}