import React from "react";
import cn from "classnames";
import styles from '../Styles/popup.module.css';

interface Props {
  styles?: string;
}

export class PopupFooter extends React.PureComponent<Props> {
  render() {
    return (
      <div className={cn(styles.baseModalFooter)}>
        <div className={cn(styles.baseModalFooterWrapper, this.props.styles)}>{this.props.children}</div>
      </div>
    );
  }
}