import React from "react";
import { Switch, Route } from "react-router-dom";
import styles from './Styles/guest-page.module.css';
import { SignInContainer } from "../PSolutions.Containers/SignIn/Container/SignInContainer";

interface Props {
  show: boolean;
}

export class GuestPage extends React.PureComponent<Props> {
  render() {
    if (!this.props.show) return null;
    return (
      <main className={styles.main}>
        <Switch>
          <Route path="*" component={SignInContainer}/>
          <Route exact path="/" component={SignInContainer}/>
          <Route exact path="/sign-in" component={SignInContainer}/>
          <Route exact path="/reset-password" component={SignInContainer}/>
        </Switch>
      </main>
    );
  }
}