import classNames from "classnames";
import React, { FormEvent } from "react";
import { IFormInput } from "./Types";
import { FormError } from "./FormError";
import styles from './Styles/input.module.css';

interface Props extends IFormInput {
}

interface State {
  focused: boolean;
}

export class FormInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {focused: false};
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.isFieldDirty = this.isFieldDirty.bind(this);
    this.hasFieldErrors = this.hasFieldErrors.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.renderRequiredMark = this.renderRequiredMark.bind(this);
  }

  handleFocus() {
    this.setState({focused: true});
  }

  handleBlur() {
    this.setState({focused: false});
  }

  isFieldDirty() {
    return !!this.props.form.touched[this.props.field.name];
  }

  hasFieldErrors() {
    return !!this.props.form.errors[this.props.field.name];
  }

  handleTextChange(event: FormEvent<HTMLInputElement>) {
    this.props.form.setFieldTouched(this.props.field.name, true);
    this.props.form.setFieldValue(this.props.field.name, event.currentTarget.value);
  }

  renderRequiredMark() {
    if (!this.props.required) return null;
    return <sup>&nbsp;*</sup>;
  }

  render() {
    const {focused} = this.state;
    const {id, placeholder, label, name, type, field, form, disabled} = this.props;
    const isInvalid = this.isFieldDirty() && this.hasFieldErrors();

    return (
      <div className={styles.inputHolder}>
        <label className={classNames(styles.label, {[styles.focused]: focused})} htmlFor={id}>{label}</label>
        <input
          id={id}
          name={name}
          type={type}
          disabled={disabled}
          value={field.value}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          onFocus={this.handleFocus}
          onChange={this.handleTextChange}
          className={classNames(styles.formInput, {[styles.inputError]: isInvalid})}
        />
        <FormError errors={form.errors} fieldName={field.name} touched={form.touched}/>
      </div>
    );
  }
}