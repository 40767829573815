import React from "react";
import styles from '../Scroll/scroll-holder.module.css';

interface Props {
}

export class ScrollHolder extends React.PureComponent<Props> {
  render() {
    return (
      <div className={styles.infinityScrollHolder}>{this.props.children}</div>
    );
  }
}