import React from "react";
import cn from 'classnames';
import { IntlContext } from "react-intl";
import { FaCheck, FaMinus, FaUserMinus } from "react-icons/all";
import { ActionItem } from "./ActionItem";
import styles from '../Styles/appointment-upsert.module.css'

interface Props {
  show: boolean;
  disabled: boolean;

  onCancelClick(): void;

  onNoShowClick(): void;
}

export class AppointmentActions extends React.PureComponent<Props> {
  static defaultProps = {show: true};
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  handleDetailsPress() {
  }

  renderCheckoutIcon() {
    return <FaCheck size="20px"/>
  }

  renderNoShowIcon() {
    return <FaUserMinus size="20px"/>
  }

  renderCancelIcon() {
    return <FaMinus size="20px"/>
  }

  render() {
    const {disabled} = this.props
    if (!this.props.show) return null;
    const cancelText = this.context.formatMessage({id: "generic.messages.cancel"})
    const chargeText = this.context.formatMessage({id: "generic.messages.checkout"})
    const nowShowText = this.context.formatMessage({id: "generic.messages.now.show"})

    return (
      <div className={styles.appointmentActionsContainer}>
        <ActionItem onPress={this.handleDetailsPress} icon={this.renderCheckoutIcon()} title={chargeText} style={cn(styles.appointmentChargeItem, {[styles.disabledActions]: disabled})}/>
        <ActionItem onPress={this.props.onNoShowClick} icon={this.renderNoShowIcon()} title={nowShowText} style={cn(styles.appointmentNoShowItem, {[styles.disabledActions]: disabled})}/>
        <ActionItem onPress={this.props.onCancelClick} icon={this.renderCancelIcon()} title={cancelText} style={cn(styles.appointmentCancelItem, {[styles.disabledActions]: disabled})}/>
      </div>
    );
  }
}