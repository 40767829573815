import React from "react";
import moment from 'moment';
import * as dateFns from 'date-fns';
import { connect } from "react-redux";
import FullCalendar from '@fullcalendar/react';
import { DatesSetArg } from "@fullcalendar/react";
import scrollGrid from '@fullcalendar/scrollgrid';
import { bindActionCreators, Dispatch } from "redux";
import dayGridPlugin from '@fullcalendar/daygrid';
import { EventContentArg } from "@fullcalendar/react";
import interactionPlugin from '@fullcalendar/interaction';
import { EventApi, EventClickArg } from '@fullcalendar/react';
import resourceTimeGrid from "@fullcalendar/resource-timegrid";
import { ResourceLabelContentArg } from "@fullcalendar/resource-common";
import { DateSelectArg, SlotLabelContentArg } from "@fullcalendar/common";

import { Event } from "../Components/Events/Event";
import { IClient } from "../../ClientUpsert/Types";
import { Resource } from "../Components/Header/Resource";
import { IEmployeeModel } from "../../EmployeeList/Types";
import PauseContainer from "../../Pause/Container/PauseContainer";
import calendarStyles from '../Styles/appointment.list.module.css';
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { IAppointmentListState, IAppointmentQuery } from "../Types";
import { ICalendarManagerData } from "../../../PSolutions.Types/Common";
import { CalendarToolBar } from "../Components/Toolbar/CalendarToolBar";
import { getEmployeeListAsync } from "../../EmployeeList/Redux/Actions";
import { BusyLoader } from "../../../PSolutions.Components/UI/BusyLoader";
import { AppointmentPicker } from "../Components/Popups/AppointmentPicker";
import { clearError as clearPauseError } from "../../Pause/Redux/Actions";
import { ErrorDisplay } from "../../../PSolutions.Components/UI/ErrorDisplay";
import { CalendarManager } from "../../../PSolutions.Manager/CalendarManager";
import { TEKICA_DATE_FORMAT } from "../../../PSolutions.Config/TekicaTimeFormat";
import SelectClientContainer from "../../SelectClient/Container/SelectClientContainer";
import { AppointmentStatusEnum } from "../../../PSolutions.Enums/AppointmentStatusEnum";
import { IAppointmentUpsertModel, IServiceModel } from "../../AppointmentUpsert/Types";
import ServiceSelectorContainer from "../../ServiceSelector/Container/ServiceSelectorContainer";
import { TranslationContext } from "../../../PSolutions.Providers/Translation/TranslationContext";
import AppointmentUpsertContainer from "../../AppointmentUpsert/Container/AppointmentUpsertContainer";
import { clearError, getResourcesAsync, setSelectedEvent, setSelectedSlot } from "../Redux/Actions";
import { AppointmentFactory, IAppointmentServiceFactoryRequest } from "../../../PSolutions.Factory/AppointmentFactory";
import { setCalendarManagerSelectionData, setInitialAppointmentSelectionData, setSelectAppointmentInformation } from "../../AppointmentUpsert/Redux/Actions";

interface Props extends IAppointmentListState {
  pauseError: string;
  showPauseError: boolean;
  selectedOfficeId: number;
  employees: Array<IEmployeeModel>
  calendarData: ICalendarManagerData,
  selectedAppointment: IAppointmentUpsertModel,

  clearError(): any

  clearPauseError(): void;

  getEmployeeListAsync(): any;

  setSelectedEvent(event: EventApi): void;

  setSelectedSlot(slot: DateSelectArg): void;

  getResourcesAsync(query: IAppointmentQuery): any;

  setCalendarManagerSelectionData(data: ICalendarManagerData): void;

  setInitialAppointmentSelectionData(data: ICalendarManagerData): void;

  setSelectAppointmentInformation(appointment: IAppointmentUpsertModel): void;
}

interface State {
  addMode: boolean;
  showPicker: boolean;
  showUpsert: boolean;
  isPauseEdit: boolean;
  showPauseModal: boolean;
  statuses: Array<number>;
  showSelectClient: boolean;
  currentDateArg: DatesSetArg;
  selectedTime: DateSelectArg;
  showServiceSelector: boolean;
}

class AppointmentListContainer extends React.PureComponent<Props, State> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;
  private readonly calendarApi: React.RefObject<FullCalendar>;

  constructor(props: Props) {
    super(props);
    this.calendarApi = React.createRef<FullCalendar>();
    this.closePicker = this.closePicker.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDatesSet = this.handleDatesSet.bind(this);
    this.checkIsLoading = this.checkIsLoading.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.togglePauseModal = this.togglePauseModal.bind(this);
    this.toggleUpsertModal = this.toggleUpsertModal.bind(this);
    this.handleDateSelected = this.handleDateSelected.bind(this);
    this.handlePrevDateClick = this.handlePrevDateClick.bind(this);
    this.handleNextDateClick = this.handleNextDateClick.bind(this);
    this.toggleServiceSelector = this.toggleServiceSelector.bind(this);
    this.handleClientSelection = this.handleClientSelection.bind(this);
    this.handleServiceSelection = this.handleServiceSelection.bind(this);
    this.fetchAppointmentsAsync = this.fetchAppointmentsAsync.bind(this);
    this.toggleSelectClientPopup = this.toggleSelectClientPopup.bind(this);
    this.handlePauseSuccessAsync = this.handlePauseSuccessAsync.bind(this);
    this.state = {
      addMode: false,
      showPicker: false,
      showUpsert: false,
      isPauseEdit: false,
      showPauseModal: false,
      showSelectClient: false,
      showServiceSelector: false,
      statuses: [1, 4, 5, 6, 10, 99],
      selectedTime: {} as DateSelectArg,
      currentDateArg: {start: new Date()} as DatesSetArg,
    }
  }

  async componentDidMount() {
    await this.props.getEmployeeListAsync();
  }

  async fetchAppointmentsAsync() {
    const {selectedOfficeId} = this.props;
    const {statuses, currentDateArg} = this.state;
    const date = dateFns.format(currentDateArg.start, TEKICA_DATE_FORMAT);
    const query: IAppointmentQuery = {statuses, officeId: selectedOfficeId, date: date};

    await this.props.getResourcesAsync(query);
  }

  checkIsLoading() {
    return true;
  }

  renderEvent(eventInfo: EventContentArg) {
    return <Event eventInfo={eventInfo}/>
  }

  renderResourceHeader(resource: ResourceLabelContentArg) {
    return <Resource resourceData={resource}/>
  }

  renderSlotLabel(label: SlotLabelContentArg) {
    return <div className={calendarStyles.timeLabel}>{label.text}</div>
  }

  handleNextDateClick() {
    this.calendarApi?.current?.getApi().next();
  }

  handlePrevDateClick() {
    this.calendarApi?.current?.getApi().prev();
  }

  async handleDatesSet(dateInfo: DatesSetArg) {
    this.setState({currentDateArg: dateInfo}, async () => await this.fetchAppointmentsAsync());
  }

  async handleDateSelected(date: Date) {
    this.calendarApi?.current?.getApi().gotoDate(date);
  }

  handleEventClick(eventContent: EventClickArg) {
    this.props.setSelectedEvent(eventContent.event);
    if (eventContent.event.extendedProps.status !== AppointmentStatusEnum.TimeReservation) return this.toggleUpsertModal();
    if (eventContent.event.extendedProps?.status === AppointmentStatusEnum.TimeReservation) return this.togglePauseModal(true);
  }

  compareTimes(start: string, end: string) {
    const endDateTime = moment(end, 'H:m');
    const startDateTime = moment(start, 'H:m')
    return endDateTime >= startDateTime;
  }

  render() {
    let slotMinTime = "07:00";
    let slotMaxTime = "21:00";
    const {showPicker} = this.state;
    const {start} = this.state.currentDateArg;
    const {isPauseEdit, selectedTime, showPauseModal, addMode} = this.state;
    const {overallStartHour, overallEndHour, inlineCalendarMinuteInterval} = this.props;

    // Because it's letter
    if (this.compareTimes(overallStartHour, overallEndHour)) slotMaxTime = overallEndHour;
    if (this.compareTimes(overallStartHour, overallEndHour)) slotMinTime = overallStartHour;

    return (
      <div className={calendarStyles.content}>
        <div className={calendarStyles.calendar}>
          <div className={calendarStyles.calendarWrapper}>
            <CalendarToolBar
              currentDate={start}
              onPrevClick={this.handlePrevDateClick}
              onNextClick={this.handleNextDateClick}
              onDateClick={this.handleDateSelected}
            />
            <div className={calendarStyles.row}>
              <FullCalendar
                firstDay={1}
                height="100%"
                editable={false}
                selectable={true}
                allDaySlot={true}
                businessHours={true}
                slotMinWidth={10}
                // dayMinWidth={300}
                nowIndicator={true}
                selectMirror={true}
                duration={{hours: 1}}
                headerToolbar={false}
                ref={this.calendarApi}
                slotEventOverlap={false}
                select={this.handleSelect}
                slotMaxTime={slotMaxTime}
                slotMinTime={slotMinTime}
                resourceOrder="orderingNumber"
                slotLabelInterval={{hours: 1}}
                eventConstraint="businessHours"
                // selectConstraint="businessHours"
                datesSet={this.handleDatesSet}
                eventContent={this.renderEvent}
                loading={this.checkIsLoading}
                events={this.props.appointments}
                initialView="resourceTimeGridDay"
                eventClick={this.handleEventClick}
                resources={this.props.resources as any}
                slotLabelContent={this.renderSlotLabel}
                resourceLabelClassNames="resourceHolder"
                resourceLabelContent={this.renderResourceHeader}
                slotDuration={{minutes: inlineCalendarMinuteInterval}}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                slotLabelFormat={{hour: 'numeric', minute: '2-digit', hour12: false}}
                eventTimeFormat={{hour: "2-digit", minute: "2-digit", hourCycle: "h24"}}
                plugins={[resourceTimeGrid, interactionPlugin, scrollGrid, dayGridPlugin]}
              />
            </div>
          </div>
        </div>
        <BusyLoader visible={this.props.isBusy}/>
        <ErrorDisplay error={this.props.errorMessage} visible={this.props.showError} closeModal={this.props.clearError}/>
        <ErrorDisplay error={this.props.pauseError} visible={this.props.showPauseError} closeModal={this.props.clearPauseError}/>
        <SelectClientContainer show={this.state.showSelectClient} closeModal={this.toggleSelectClientPopup} onClientSelect={this.handleClientSelection}/>
        <ServiceSelectorContainer show={this.state.showServiceSelector} closeModal={this.toggleServiceSelector} onServiceClick={this.handleServiceSelection}/>
        <AppointmentPicker show={showPicker} closePicker={this.closePicker} onPauseClick={this.togglePauseModal} onAppointmentClick={this.toggleUpsertModal}/>
        <AppointmentUpsertContainer
          addMode={addMode}
          show={this.state.showUpsert}
          closeModal={this.toggleUpsertModal}
          currentLocale={this.context.currentLocale}
          fetchAppointmentsAsync={this.fetchAppointmentsAsync}
          toggleSelectServicePopup={this.toggleServiceSelector}
          toggleSelectClientPopup={this.toggleSelectClientPopup}
        />
        <PauseContainer
          isEdit={isPauseEdit}
          visible={showPauseModal}
          selectedTime={selectedTime}
          closeModal={this.togglePauseModal}
          onSuccess={this.handlePauseSuccessAsync}
          currentLocale={this.context.currentLocale}
        />
      </div>
    );
  }

  // Logic of other container

  closePicker() {
    this.setState({showPicker: false})
  }

  togglePauseModal(isEditMode: boolean = false) {
    this.setState({showPauseModal: !this.state.showPauseModal, isPauseEdit: isEditMode});
  }

  toggleUpsertModal(addMode: boolean = false) {
    const data = CalendarManager.parseFromSelection(this.state.selectedTime);
    if (addMode) this.props.setInitialAppointmentSelectionData(data);
    this.setState({showUpsert: !this.state.showUpsert, addMode: addMode});
  }

  toggleSelectClientPopup() {
    this.setState({showSelectClient: !this.state.showSelectClient, showUpsert: !this.state.showUpsert, addMode: true})
  }

  toggleServiceSelector() {
    this.setState({showServiceSelector: !this.state.showServiceSelector, showUpsert: !this.state.showUpsert, addMode: true});
  }

  handleClientSelection(client: IClient) {
    const {selectedAppointment} = this.props;

    selectedAppointment.client = client;
    selectedAppointment.clientId = client.id;

    this.setState({showSelectClient: false, showUpsert: true});
    this.props.setSelectAppointmentInformation(selectedAppointment);
  }

  handleServiceSelection(service: IServiceModel) {
    const {employees, selectedAppointment} = this.props;
    const {startTime, employeeId} = this.props.calendarData;

    const req: IAppointmentServiceFactoryRequest = {employees, service, startTime, employeeId};
    const newService = AppointmentFactory.CreateAppointmentService(req);
    if (!newService) return;

    selectedAppointment.services = [newService];
    this.props.setSelectAppointmentInformation(selectedAppointment);

    this.setState({showServiceSelector: false, showUpsert: true});
  }

  handleSelect(selectionInfo: DateSelectArg) {
    this.props.setSelectedSlot(selectionInfo);
    const data = CalendarManager.parseFromSelection(selectionInfo);
    this.props.setCalendarManagerSelectionData(data);
    this.setState({showPicker: true, selectedTime: selectionInfo});
  }

  async handlePauseSuccessAsync() {
    await this.fetchAppointmentsAsync();
  }

}

const mapStateToProps = (state: IRootState) => {
  return {
    pauseError: state.pause.errorMessage,
    isBusy: state.appointmentList.isBusy,
    showPauseError: state.pause.showError,
    employees: state.employeeList.employees,
    resources: state.appointmentList.resources,
    appointments: state.appointmentList.appointments,
    selectedEvent: state.appointmentList.selectedEvent,
    calendarData: state.appointmentUpsert.calendarData,
    overallEndHour: state.appointmentList.overallEndHour,
    selectedOfficeId: state.officeSelector.selectedOfficeId,
    overallStartHour: state.appointmentList.overallStartHour,
    selectedAppointment: state.appointmentUpsert.selectedAppointment,
    inlineCalendarMinuteInterval: state.appointmentList.inlineCalendarMinuteInterval,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    clearError,
    clearPauseError,
    setSelectedEvent,
    setSelectedSlot,
    getResourcesAsync,
    getEmployeeListAsync,
    setSelectAppointmentInformation,
    setCalendarManagerSelectionData,
    setInitialAppointmentSelectionData,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentListContainer);