import axios from "axios";
import { Dispatch } from "redux";
import { ISelectClientModel } from "../Types";
import { IClient } from "../../ClientUpsert/Types";
import { SelectClientActionTypesEnum } from "./Types";
import { IRootState } from "../../../PSolutions.State/RootReducer";
import { ErrorManager } from "../../../PSolutions.Manager/ErrorManager";
import { AppSettings } from "../../../PSolutions.Config/ApplicationSettings";

export interface GetSuccessAction {
  payload: IClient;
  type: SelectClientActionTypesEnum.GET_SUCCESS;
}

export interface GetFailureAction {
  payload: string;
  type: SelectClientActionTypesEnum.GET_FAILURE;
}

export interface GetRequestedAction {
  type: SelectClientActionTypesEnum.GET_REQUESTED;
}

export interface GetListSuccessAction {
  payload: ISelectClientModel;
  type: SelectClientActionTypesEnum.GET_LIST_SUCCESS;
}


export interface GetListFailureAction {
  payload: string;
  type: SelectClientActionTypesEnum.GET_LIST_FAILURE;
}

export interface GetListRequestedAction {
  type: SelectClientActionTypesEnum.GET_LIST_REQUESTED;
}

export interface ISelectClientClearErrorAction {
  type: SelectClientActionTypesEnum.CLEAR_ERROR;
}

export interface ISelectClientClearStateAction {
  type: SelectClientActionTypesEnum.CLEAR_STATE;
}


export const getAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: SelectClientActionTypesEnum.GET_REQUESTED});
      const res = await axios.post<ISelectClientModel>(`${AppSettings.apiUrl}/SelectClient}`);
      dispatch({type: SelectClientActionTypesEnum.GET_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: SelectClientActionTypesEnum.GET_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}

export const getListAsync = (page: number, pageSize: number, searchText: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const companyId = getState().officeSelector?.selectedOffice.companyId;
      dispatch({type: SelectClientActionTypesEnum.GET_LIST_REQUESTED});
      const queryParams = {companyId: companyId, page: page, pageSize: pageSize, searchText};
      const res = await axios.get(`${AppSettings.apiUrl}/Clients`, {params: queryParams});
      dispatch({type: SelectClientActionTypesEnum.GET_LIST_SUCCESS, payload: res.data});
      return res.data;
    } catch (error: any) {
      const manager = new ErrorManager(error);
      dispatch({type: SelectClientActionTypesEnum.GET_LIST_FAILURE, payload: manager.getApiError()})
      return null;
    }
  }
}


export const clearError = () => (dispatch: Dispatch) => dispatch({type: SelectClientActionTypesEnum.CLEAR_ERROR});
export const clearState = () => (dispatch: Dispatch) => dispatch({type: SelectClientActionTypesEnum.CLEAR_STATE});

export type SelectClientActions =
  GetRequestedAction |
  GetSuccessAction |
  GetFailureAction |
  GetListRequestedAction |
  GetListSuccessAction |
  GetListFailureAction |
  ISelectClientClearErrorAction |
  ISelectClientClearStateAction;